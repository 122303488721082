import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"

import { setLocale } from "reducers/ducks/user";
import { getCurrentUser } from "selectors/user.selector"
import { fetchCurrentUser } from "services/currentUser.service";
import { getLanguageCode } from 'selectors/locale.selectors';

export const useSetLocaleIfNotSet = () => {
  const user = useSelector(getCurrentUser);
  const languageCode = useSelector(getLanguageCode)
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!user?.email && !user?.locale) {
      dispatch(setLocale(languageCode))
        .then(() => dispatch(fetchCurrentUser))
    }
  }, [dispatch, languageCode, user])

  return null
}