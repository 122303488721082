import React from "react";
import { Grid } from "@material-ui/core";
import Employee from "./list";
import SearchContainer from "./list/searchContainer";
import EmployeeCharts from "./charts";

const EmployeeDashboard = () => {
  const [selected, setSelected] = React.useState({});
  const [search, setSearch] = React.useState("");

  return (
    <div style={{ padding: 16 }}>
      <Grid container wrap="nowrap" style={{ height: "100%" }} spacing={1}>
        <Grid item xs={3}>
          <SearchContainer handleSearch={setSearch} />
          <Employee
            search={search}
            selected={selected}
            setSelected={setSelected}
          />
        </Grid>
        <Grid item xs={9}>
          <EmployeeCharts selected={selected} />
        </Grid>
      </Grid>
    </div>
  );
};

export default EmployeeDashboard;
