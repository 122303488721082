import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  lastName: Yup.string().required(),
  email: Yup.string().required().email(),
  password: Yup.string().required(),
  repeatedPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'validation.custom.passwordDoesNotMatch')
    .required(),
  company: Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required().email(),
  }),
});