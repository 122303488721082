import { assoc } from 'ramda';

import { getTranslate } from 'shared/components/translate';
import { actions as notificationActions } from 'shared/components/notifications';

export const SET_GOALS = 'MYKPI/DEPARTMENT_GOAL/SET_GOALS';
const FETCHING = 'MYKPI/DEPARTMENT_GOAL/FETCHING';
const CREATE_DIALOG = 'MYKPI/DEPARTMENT_GOAL/CREATE_DIALOG';
const EDIT_DIALOG = 'MYKPI/DEPARTMENT_GOAL/EDIT_DIALOG';
const EDIT_GOAL_VAlUE = 'MYKPI/DEPARTMENT_GOAL/EDIT_GOAL_VAlUE';
const RECORD_DIAlOG = 'MYKPI/DEPARTMENT_GOAL/RECORD_DIAlOG';
const RECORD_DIALOG_VALUES = 'MYKPI/DEPARTMENT_GOAL/RECORD_DIALOG_VALUES';

const defaultState = {
  goals: {},
  isFetching: false,
  openCreateDialog: false,
  openEditDialog: false,
  editGoalValue: null,
  recordDialog: false,
  recordDialogValues: null,
};

export default (state = defaultState, { type, payload } = {}) => {
  switch (type) {
    case SET_GOALS:
      return assoc('goals', payload, state);
    case FETCHING:
      return assoc('isFetching', payload, state);
    case CREATE_DIALOG:
      return assoc('openCreateDialog', payload, state);
    case EDIT_DIALOG:
      return assoc('openEditDialog', payload, state);
    case EDIT_GOAL_VAlUE:
      return assoc('editGoalValue', payload, state);
    case RECORD_DIAlOG:
      return assoc('recordDialog', payload, state);
    case RECORD_DIALOG_VALUES:
      return assoc('recordDialogValues', payload, state);
    default:
      return state;
  }
}

// actions
export const setDepartmentGoals = (strategies = []) => ({
  type: SET_GOALS,
  payload: strategies.reduce((acc, elem) => {
    return {
      ...acc,
      [elem.id]: elem,
    }
  }, {})
});

export const setFetching = payload => ({
  type: FETCHING,
  payload
});

export const setOpenCreateDialog = payload => ({
  type: CREATE_DIALOG,
  payload
});

export const setOpenRecordValue = payload => ({
  type: RECORD_DIALOG_VALUES,
  payload
});

export const setOpenRecordDialog = payload => ({
  type: RECORD_DIAlOG,
  payload
});

export const setOpenEditDialog = payload => ({
  type: EDIT_DIALOG,
  payload
});

export const setEditGoalValue = payload => ({
  type: EDIT_GOAL_VAlUE,
  payload
});

export const openCreateDialog = setOpenCreateDialog(true);
export const closeCreateDialog = dispatch => {
  dispatch(setOpenCreateDialog(false));
  dispatch(setEditGoalValue(null));
};
export const openEditDialog = setOpenEditDialog(true);
export const closeEditDialog = dispatch => {
  dispatch(setOpenEditDialog(false));
  dispatch(setEditGoalValue(false));
}
export const openRecordDialog = setOpenRecordDialog(true);
export const closeRecordDialog = setOpenRecordDialog(false);

// operators
export const fetchDepartmentStrategy = (dispatch, getState, { api }) => {
  dispatch(setFetching(true));
  const translate = getTranslate(getState());

  return api.get('/private/departmentGoal/list')
    .catch(error => {
      dispatch(notificationActions.displayMessage(
        'error',
        translate('notifications.errors.get')
      ));

      throw error;
    })
    .finally(() => dispatch(setFetching(false)))
}

export const setFetchedDepartmentGoals = dispatch => {
  return dispatch(fetchDepartmentStrategy)
    .then(response => {
      dispatch(setDepartmentGoals(response.data));

      return response;
    });
}

export const createDepartmentGoal = body => (dispatch, getState, { api }) => {
  const translate = getTranslate(getState());

  return api.post('/private/departmentGoal/create', body)
    .catch(err => {
      dispatch(notificationActions.displayMessage(
        'error',
        translate('notifications.errors.get')
      ));

      throw err;
    })
}

export const editDepartmentGoal = ({ id, ...rest }) => (dispatch, getState, { api }) => {
  const translate = getTranslate(getState());

  return api.put(`/private/departmentGoal/goal/${id}`, rest)
    .catch(err => {
      dispatch(notificationActions.displayMessage(
        'error',
        translate('notifications.errors.put')
      ));

      throw err;
    })
}

export const createAndFetchDepartmentGoals = body => dispatch => {
  return dispatch(createDepartmentGoal(body))
    .then(() => dispatch(setFetchedDepartmentGoals))
}

export const fetchDepartmentGoal = id => (dispatch, getState, { api }) => {
  const translate = getTranslate(getState());

  return api.get(`/private/departmentGoal/goal/${id}`)
    .catch(error => {
      dispatch(notificationActions.displayMessage(
        'error',
        translate('notifications.errors.get')
      ));

      throw error;
    })
}

export const setDepartmentGoalRecord = ({ id, ...rest } = {}) => (dispatch, getState, { api }) => {
  const translate = getTranslate(getState());

  return api.put(`/private/departmentGoal/goal/${id}/record`, rest)
    .catch(error => {
      dispatch(notificationActions.displayMessage(
        'error',
        translate('notifications.errors.')
      ));

      throw error;
    });
}

export const getDepartmentGoal = id => (dispatch, getState, { api }) => {
  const translate = getTranslate(getState());

  return api.get(`/private/departmentGoal/goal/${id}`)
    .catch(error => {

      throw error;
    });
}