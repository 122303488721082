import React from 'react';
import { TableCell, makeStyles } from '@material-ui/core';
import { WithRbac } from 'common/rbac';
import inlineStyles from './index.module.css';
import { ROLES } from 'common/roles';

const useStyles = makeStyles(theme => ({
  pointer: {
    cursor: 'pointer'
  },
  disabled: {
    backgroundColor: theme.palette.grey[200],
  },
  correct: {
    backgroundColor: '#4caf501a',
  },
  bad: {
    backgroundColor: '#f443361a',
  },
}));

// const getBackgroundColor =  ({ plannedValue, unit, title } = {}) => {
const customParseFloat = (str = '') => parseFloat(str.replace(",", "."));

const getBackgroundColor = ({ value, plannedValue, equalCase } = {}) => {
  if (value == null || plannedValue == null || equalCase == null) {
    return
  }

  switch (equalCase) {
    case 'lte':
      return customParseFloat(value) <= customParseFloat(plannedValue) ? inlineStyles.correct : inlineStyles.bad;
    case 'gte':
      return customParseFloat(value) >= customParseFloat(plannedValue) ? inlineStyles.correct : inlineStyles.bad;
    default:
      return;
  }
}

export const BaseCell = ({ title, equalCase, plannedValue, ...props }) => {
  const styles = useStyles();
  return (
    <WithRbac
      action={ROLES.STRATEGIC_GOAL["strategicGoal:create"]}
      fallback={(<FallBackCell title={title} />)}
    >
      <TableCell
        {...props}
        className={`${inlineStyles.baseCell} ${styles.pointer} ${getBackgroundColor({ value: title, equalCase, plannedValue })} `} >
        <div className={`${inlineStyles.fullCell}`}>
          {title}
        </div>
      </TableCell>
    </WithRbac>
  )
}

export const FallBackCell = ({ title, ...props }) => {
  const styles = useStyles();

  return (
    <TableCell >
      <div className={`${inlineStyles.baseCell} ${styles.disabled}`}>
        {title}
      </div>
    </TableCell>
  )
}