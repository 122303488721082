import * as Yup from 'yup';
import { merge } from 'ramda';

export const baseValidationSchema = Yup.object().shape({
  email: Yup.string().required().email(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
});

export const securityValidationSchema = Yup.object().shape({
  password: Yup.string().required(),
  repeatedPassword: Yup.string().required().oneOf([Yup.ref("password")], "Nesutampa slaptažodis"),
});

export const validationSchema = baseValidationSchema