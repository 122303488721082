import React, { useCallback, useMemo, useState } from "react";
import {
  Grid,
  Button,
  Collapse,
  Paper,
  Icon,
  IconButton,
  Slide,
  Link
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch, withRouter } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";

import Status from "../status";
import { useTranslate } from "shared/components/translate";
import { getLastUntilDate } from "../utils";
import Table from "shared/components/table";
import {
  fetchProblemsTable,
  problemTableSelectors,
  setProblemsTableState,
} from "reducers/ducks/problems/table";

const ProblemListContainer = (props) => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const match = useRouteMatch()
  const data = useSelector(problemTableSelectors.getData);
  const filter = useSelector(problemTableSelectors.getFilter);
  const tableState = useSelector(problemTableSelectors.getTableState);
  const loading = useSelector(problemTableSelectors.isLoading);
  const pagination = useSelector(problemTableSelectors.getPagination);

  React.useEffect(() => {
    dispatch(fetchProblemsTable());
  }, [dispatch, filter, tableState]);

  const loadData = useCallback(
    (tableState) => {
      dispatch(setProblemsTableState(tableState));
    },
    [dispatch]
  );

  const columns = useMemo(() => {
    return [
      // {
      //   Header: translate("problems.table.createAt"),
      //   accessor: "createdAt",
      //   Cell: (props) => <Date date={props.value} format="YYYY-MM-DD" />,
      // },
      {
        Header: translate("problems.table.description"),
        accessor: "description",
        Cell: ({ value, row }) => {
          return (
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item>
                <Status status={row.original.status} />
              </Grid>
              <Grid item>
                {value}
              </Grid>
            </Grid>
          );
        },
      },
      // {
      //   Header: translate("problems.table.status"),
      //   accessor: "status",
      //   Cell: (props) => {
      //     return (
      //       <Grid container wrap="nowrap" spacing={1}>
      //         <Grid item>
      //           <Status status={props.value} />
      //         </Grid>
      //         <Grid item>
      //           {translate(`enums.statuses.${props.value?.name}`)}
      //         </Grid>
      //       </Grid>
      //     );
      //   },
      // },
      {
        Header: translate("problems.table.until"),
        accessor: "until",
        Cell: (props) => {
          return getLastUntilDate(props.row.original) || "-";
        },
      },
      {
        Header: translate("problems.table.actions"),
        Cell: (props) => {
          return (
            <Link
              component={RouterLink}
              to={`/problems/problem/${props.row.original.id}`}
            >
              {translate("common.view")}
            </Link>
          );
        },
      },
    ];
  }, [translate]);

  return (
    <Grid container>
      {
        match.isExact && (
          <Grid item xs={12} style={{ padding: 16 }}>
            <Table
              initialState={{ sortBy: [{ id: "createdAt", desc: true }] }}
              loading={loading}
              columns={columns}
              data={data}
              loadData={loadData}
              pagination={pagination}
            />
          </Grid>
        )
      }
      {
        !match.isExact && (
          <Grid
            item
            xs={12}
          >
            <Slide in={!match.isExact} direction="left">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item style={{ padding: "8px 8px 0 0" }}>
                          <IconButton
                            component={RouterLink}
                            to="/problems"
                          >
                            <ArrowBack />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    {!match.isExact && <Grid item xs={12}>{props.children}</Grid>}
                  </Grid>
                </Grid>
              </Grid>
            </Slide>
          </Grid>
        )
      }
    </Grid>
  );
};

export default withRouter(ProblemListContainer);
