import React from 'react';
import { Grid } from '@material-ui/core';

import { useTranslate } from 'shared/components/translate';
import SearchTextField from 'shared/components/searchTextField';

const SearchContainer = ({ handleSearch }) => {
  const [value, setSearch] = React.useState("");
  const translate = useTranslate();

  return (
    <form onSubmit={e => {
      e.preventDefault();
      handleSearch(value)
    }}>
      <Grid container wrap='nowrap' justify='space-between' alignItems='center' spacing={1}>
        <Grid item xs={12}>
          <SearchTextField
            inputProps={{
              value,
              onChange: (e) => setSearch(e.target.value),
              placeholder: translate('dashboards.filter.name'),
            }}
            buttonProps={{
              type: "submit"
            }}
          />
        </Grid>
      </Grid>
    </form >
  )
}

export default SearchContainer;