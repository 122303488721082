import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, Button, ListItem } from "@material-ui/core";
import { path } from "ramda";

import { getMission } from "reducers/ducks/indicator/listIndicators";
import CreateMissionModal from "../create/mission";
import { goTo } from "shared/utils/goTo";
import LoaderContainer from "shared/components/loaderContainer";
import { fetchIndicatorId } from "selectors/indicator.selector";
import { WithRbac } from "common/rbac";
import { ROLES } from "common/roles";
import { useTranslate } from "shared/components/translate";

const Vision = () => {
  const dispatch = useDispatch();
  const [mission, setMission] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const fetchId = useSelector(fetchIndicatorId);
  const translate = useTranslate();

  React.useEffect(() => {
    setLoading(true);
    dispatch(getMission)
      .then((response) => setMission(path(["data"], response)))
      .finally(() => setLoading(false));
  }, [dispatch, setMission, setLoading, fetchId]);

  if (!mission && !loading) {
    return (
      <WithRbac action={ROLES["MISSION-CREATE"]}>
        <CreateMissionModal open={open} handleClose={() => setOpen(false)} />
        <Grid container spacing={1} justify="flex-end">
          <Grid item xs={12}>
            <Typography variant="caption" color="textSecondary">
              {translate("indicators.lists.noMission")}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={() => setOpen(true)}
              variant="contained"
              color="primary"
              size="small"
            >
              {translate("indicators.addMission")}
            </Button>
          </Grid>
        </Grid>
      </WithRbac>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <LoaderContainer fetching={loading}>
          <ListItem
            button
            onClick={() => goTo(`/strategy/mission/${mission.id}`)}
            compoent="div"
          >
            {translate("indicators.lists.mission")}
          </ListItem>
        </LoaderContainer>
      </Grid>
    </Grid>
  );
};

export default Vision;
