import React from "react";
import { Grid, TextField, Button, IconButton, Collapse } from "@material-ui/core";
import Employee from "./list";
import SearchContainer from "./list/searchContainer";
import EmployeeCharts from "./charts";
import FilterListContainer from "shared/components/filterList";
import { ArrowBack } from "@material-ui/icons";
import { useTranslate } from "shared/components/translate";

const EmployeeDashboard = () => {
  const [selected, setSelected] = React.useState({});
  const [search, setSearch] = React.useState("");
  const [showChart, setShowChart] = React.useState(false);
  const translate = useTranslate();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Collapse in={!showChart}>
          <div style={{ padding: 16 }}>
            <SearchContainer handleSearch={setSearch} />
            <Employee
              search={search}
              selected={selected}
              setSelected={setSelected}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={Object.values(selected)?.length === 0}
              onClick={() => setShowChart(true)}
            >
              {translate('common.show')}
            </Button>
          </div>
        </Collapse>
      </Grid>
      <Grid item xs={12} md={3}>
        <Collapse in={showChart}>
          <div style={{ padding: 16 }}>
            <IconButton color="primary" onClick={() => setShowChart(false)}>
              <ArrowBack />
            </IconButton>
            <EmployeeCharts selected={selected} />
          </div>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default EmployeeDashboard;
