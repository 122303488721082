import { path } from 'ramda';
import qs from 'qs';

export const fetchAcknowledgment = (filter) => (dispatch, getState, { api }) => {
  dispatch({ type: 'acknowledgment/fetch_acknowledgment_request' });

  return api.get(`/private/acknowledgment/list?${qs.stringify(filter)}`)
    .then(result => {
      dispatch({
        type: 'acknowledgment/list_success',
        payload: result.data,
      });

      return result.data
    });
}

export const fetchMyGivenAcknowledgment = (dispatch, getState, { api }) => {
  dispatch({ type: 'acknowledgment/fetch_my_given_acknowledgment_request' });

  return api.get('/private/acknowledgment/my-list')
    .then(result => {
      dispatch({
        type: 'acknowledgment/fetch_my_given_acknowledgment_success',
        payload: result.data,
      });
    });
}

export const createAcknowledgment = (form) => (dispatch, getState, { api }) => {
  const assignTo = path(['assignTo'], form);
  const createdBy = path(['createdBy'], form);

  if (!assignTo) {
    return Promise.reject('Test');
  }

  return api.post('/private/acknowledgment/create', { assignTo, createdBy })
}

export const removeAcknowledgment = id => (dispatch, getState, { api }) => {

  return api.delete(`/private/acknowledgment/remove/${id}`);
}