import React from "react";
import { Slider, withStyles } from "@material-ui/core";

const formatSliderValue = (val, priceTags) => {
  return priceTags[val].monthPrice;
};

const PriceSlider = ({ sliderValue, setSliderValue, priceTags, classes }) => {
  return (
    <Slider
      classes={classes}
      aria-labelledby="price-slider"
      valueLabelDisplay="auto"
      valueLabelFormat={(val) => formatSliderValue(val, priceTags)}
      value={sliderValue}
      color="primary"
      onChange={(e, val) => setSliderValue(val)}
      step={1}
      marks={Object.values(priceTags)}
      min={1}
      max={6}
    />
  );
};

export default withStyles((theme) => ({
  root: {
    height: 2,
    padding: "15px 0",
  },
  track: {
    height: 10,
    marginRight: -4,
    backgroundColor: theme.palette.primary.main,
  },
  rail: {
    height: 10,
    opacity: 0.5,
    backgroundColor: theme.palette.primary.main,
  },
  mark: {
    height: 12,
    width: 12,
    marginTop: -4,
    marginLeft: -4,
    borderRadius: "100%",
    backgroundColor: theme.palette.background.default,
    border: `2px solid ${theme.palette.primary.main}`
  },
  markActive: {
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
  },
  thumb: {
    marginTop: -7,
    marginLeft: -24,
    backgroundColor: theme.palette.primary.main,
    height: 26,
    width: 56,
    borderRadius: 15

  },
  markLabel: {
    paddingTop: 16
  },
  valueLabel: {
    marginLeft: 40,
  }
}))(PriceSlider);
