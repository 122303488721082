import React from "react";
import { useTranslate } from "shared/components/translate";
import { List, ListItem } from "@material-ui/core";

import InstructorCard from "./instructorCard";
import erlandas from "assets/img/erlandas.jpg";

const ErlandasCv = () => {
  const translate = useTranslate();

  return (
    <InstructorCard
      name="Erlandas Jakubonis"
      imageUrl={erlandas}
      aboutMe={<>
        <p>{translate("landingPage.cv.erlandas.aboutMe")}</p>
        <p>{translate("landingPage.cv.erlandas.aboutMe2")}</p>
      </>}
      email="erlandas.jakubonis@gmail.com"
      linkedIn="https://www.linkedin.com/in/erlandasjakubonis"
    />
  );
};

export default ErlandasCv;
