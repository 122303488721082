import { combineReducers } from 'redux';
import { localizeReducer } from 'react-localize-redux';

import { reducer as userReducer } from './user.reducer';
import { reducer as acknowledgmentReducer } from './acknowledgment.reducer';
// import { reducer as indicatorReducer } from './indicators.reducer';
import { reducer as notificationReducer, NAME } from '../shared/components/notifications';
import departmentReducer from './ducks/department';
import visionReducer from './ducks/vision';
import missionReducer from './ducks/mission';
import strategyReducer from './ducks/strategy';
import strategyTableReducer from './ducks/strategy/table';
import { DELETE_GOAL_DIALOG as STRATEGY_DELETE_GOAL_DIALOG } from './ducks/strategy/deleteGoal';
import dialogReducer from './ducks/common/dialog';
import createNamedWrapperReducer from './ducks/common/utils';
import { DELETE_TEMPLATE_DIALOG } from './ducks/template/deleteDialog';
import templateTableReducer from './ducks/template';
import problemReducer from './ducks/problems';
import indicatorReducer from './ducks/indicator';
import dashboardReducer from './ducks/dashboard';

export default combineReducers({
  user: userReducer,
  problem: problemReducer,
  acknowledgment: acknowledgmentReducer,
  indicator: indicatorReducer,
  localize: localizeReducer,
  department: departmentReducer,
  dashboard: dashboardReducer,
  template: combineReducers({
    deleteDialog: createNamedWrapperReducer(dialogReducer, DELETE_TEMPLATE_DIALOG),
    table: templateTableReducer
  }),
  strategy: combineReducers({
    vision: visionReducer,
    mission: missionReducer,
    strategy: strategyReducer,
    table: strategyTableReducer,
    deleteGoal: createNamedWrapperReducer(dialogReducer, STRATEGY_DELETE_GOAL_DIALOG),
  }),
  [NAME]: notificationReducer,
});