import React from 'react';
import { Grid, Typography, ListItem, List } from '@material-ui/core';
import { useTranslate } from 'shared/components/translate';
import Vision from 'assets/img/vision.jpg';
import ImageCard from './image';

const Post1 = () => {
  const translate = useTranslate();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">
          {translate("landingPage.blogPost.post1.title")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ImageCard image={Vision} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {translate("landingPage.blogPost.post1.subtitle1")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.blogPost.post1.p1")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <List dense>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post1.list1.l1")}</Typography></ListItem>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post1.list1.l2")}</Typography></ListItem>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post1.list1.l3")}</Typography></ListItem>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post1.list1.l4")}</Typography></ListItem>
        </List>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.blogPost.post1.p2")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.blogPost.post1.p3")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.blogPost.post1.p5")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {translate("landingPage.blogPost.post1.subtitle1")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.blogPost.post1.p6")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.blogPost.post1.p7")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {translate("landingPage.blogPost.post1.subtitle3")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.blogPost.post1.p8")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.blogPost.post1.p9")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {translate("landingPage.blogPost.post1.subtitle4")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.blogPost.post1.p10")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.blogPost.post1.p11")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.blogPost.post1.p12")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.blogPost.post1.p13")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {translate("landingPage.blogPost.post1.subtitle5")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.blogPost.post1.p14")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {translate("landingPage.blogPost.post1.subtitle6")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.blogPost.post1.p15")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {translate("landingPage.blogPost.post1.subtitle7")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.blogPost.post1.p16")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.blogPost.post1.p17")}
        </Typography>
      </Grid>

    </Grid>
  )
}

export default Post1;