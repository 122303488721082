import React from "react";
import { Grid, Typography } from "@material-ui/core";

const InformationBox = ({ title, children }) => {
  if (!children) {
    return null;
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography color="textPrimary">{title}</Typography>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

export default InformationBox;
