import React from "react";
import { Grid, Button, Hidden } from "@material-ui/core";

import PageHeader from "shared/components/pageHeader";
import SplitButton from "shared/components/splitButton";
import { WithRbac } from "common/rbac";
import { ROLES } from "common/roles";
import { Search } from "@material-ui/icons";
import { useTranslate } from "shared/components/translate";

const IndicatorsHeader = ({ handleCreate, toggleFilter }) => {
  const translate = useTranslate();

  return (
    <PageHeader title={translate("indicators.title")}>
      <div style={{ padding: 8 }}>
        <Grid container justify="flex-end" spacing={1}>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={toggleFilter}>
              <Hidden mdDown>{translate("common.filter")}</Hidden>
              <Hidden lgUp>
                <Search />
              </Hidden>
            </Button>
          </Grid>
          <WithRbac
            action={[
              ROLES["INDICATOR-CREATE-VISION"],
              ROLES["INDICATOR-CREATE-STRATEGY"],
              ROLES["INDICATOR-CREATE-DEPARTMENT"],
              ROLES["INDICATOR-CREATE-EMPLOYEE"],
            ]}
          >
            <Grid item>
              <SplitButton
                title={translate("common.create")}
                handleClick={handleCreate}
                options={[
                  {
                    title: translate("indicators.createOptions.strategic"),
                    type: "strategic",
                    roles: [ROLES["INDICATOR-CREATE-STRATEGY"]],
                  },
                  {
                    title: translate("indicators.createOptions.groupIndicator"),
                    type: "groupIndicator",
                    roles: [ROLES["INDICATOR-CREATE-DEPARTMENT"]],
                  },
                  {
                    title: translate(
                      "indicators.createOptions.employeeIndicator"
                    ),
                    type: "employeeIndicator",
                    roles: [ROLES["INDICATOR-CREATE-EMPLOYEE"]],
                  },
                ]}
              />
            </Grid>
          </WithRbac>
        </Grid>
      </div>
    </PageHeader>
  );
};

export default IndicatorsHeader;
