import React from "react";
import { withRouter } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import { createPasswordWithInvitation } from "../../services/auth.service";
import InputField from "../../shared/components/input";
import { withTranslate } from "../../shared/components/translate";

const ForgotPassword = withRouter(({ history, translate, match }) => {
  const dispatch = useDispatch();
  const { key } = match.params;

  return (
    <Card>
      <CardHeader title={translate("auth.createPassword.title")} />
      <CardContent>
        <Formik
          validationSchema={Yup.object().shape({
            password: Yup.string().required(),
            passwordRepeated: Yup.string()
              .required()
              .oneOf(
                [Yup.ref("password")],
                "auth.createPassword.passwordNotMatch"
              ),
          })}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            dispatch(createPasswordWithInvitation(key, values))
              .then(() => history.push("/auth/login"))
              .catch(() => {
                actions.setError({ failed: true });
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          }}
          render={(props) => (
            <form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                {props.error && (
                  <Grid item xs={12}>
                    <Typography variant="caption" color="error">
                      {translate("auth.createPassword.noInvitation")}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    component={InputField}
                    label={translate("auth.createPassword.password")}
                    variant="outlined"
                    type="password"
                    name="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    component={InputField}
                    label={translate("auth.createPassword.repeatedPassword")}
                    variant="outlined"
                    type="password"
                    name="passwordRepeated"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={props.isSubmitting || !props.isValid}
                  >
                    {translate("common.create")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </CardContent>
    </Card>
  );
});

export default withTranslate(ForgotPassword);
