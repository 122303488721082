import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { withTranslate } from 'shared/components/translate';
import { fetchStrategicGoal, closeEditDialog, setEditGoalId, editStrategicGoal, setFetchedCompanyStrategy } from 'reducers/ducks/strategy';
import { isEditStrategicGoalDialogOpen, getEditStrategicGoalId } from 'selectors/strategy.selector';
import StrategicGoal, { strategicGoalValidationSchema } from '../forms/strategicGoal';

const CreateStrategicGoalDialog = ({ translate }) => {
  const [formValue, setFormValue] = React.useState();
  const dispatch = useDispatch();
  const open = useSelector(isEditStrategicGoalDialogOpen);
  const id = useSelector(getEditStrategicGoalId);
  const handleCloseRecord = () => {
    dispatch(closeEditDialog);
    dispatch(setEditGoalId(null));
  }

  React.useEffect(() => {
    if (!!id) {
      dispatch(fetchStrategicGoal(id))
        .then(response => setFormValue(response.data))
    }
  }, [dispatch, id])

  return (
    <Dialog fullWidth open={open} onClose={handleCloseRecord}>
      <DialogTitle>Redaguoti strateginį tikslą</DialogTitle>
      <Formik
        enableReinitialize
        initialValues={formValue}
        validationSchema={strategicGoalValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          dispatch(editStrategicGoal(id, values))
            .then(() => dispatch(setFetchedCompanyStrategy))
            .then(() => handleCloseRecord())
            .finally(() => setSubmitting(false));
        }}
        render={formProps => (
          <Form>
            <DialogContent>
              <StrategicGoal />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseRecord}>{translate("common.cancel")}</Button>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                disabled={!formProps.isValid || formProps.isSubmitting}
              >
                {translate("common.edit")}
              </Button>
            </DialogActions>
          </Form>
        )}
      />
    </Dialog>
  )
}

export default withTranslate(CreateStrategicGoalDialog);