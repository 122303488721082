import React from "react";
import { Grid, Collapse } from "@material-ui/core";

import IndicatorsHeader from "./header";
import IndicatorLists from "./list";
import { CreateDialogs } from "./create";
import AdvanceFilter from "./filter/advancedFilter";

const ConnectedContainer = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState(null);

  return (
    <React.Fragment>
      <CreateDialogs handleClose={() => setType(null)} type={type} />
      <Grid container style={{ height: "100%" }}>
        <Grid item xs={12}>
          <IndicatorsHeader
            toggleFilter={() => setOpen(!open)}
            handleCreate={(e, option) => setType(option.type)}
          />
        </Grid>
        <Grid item xs={12}>
          <Collapse in={open} unmountOnExit={false}>
            <div
              style={{
                padding: 16,
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              }}
            >
              <AdvanceFilter />
            </div>
          </Collapse>
        </Grid>
        <Grid item xs={12} style={{ height: "100%" }}>
          <Grid container style={{ height: "100%" }}>
            <Grid item xs={3}>
              <IndicatorLists open={open} />
            </Grid>
            <Grid item xs={9} style={{ height: "100%" }}>
              {children}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ConnectedContainer;
