import React from "react";
import { Route, Switch } from "react-router-dom";

import Template from 'components/templates';
import CreateTemplate from 'components/templates/create';
import EditTemplate from 'components/templates/edit';

export default (props) => {
  return (
    <Switch>
      <Route
        path={`${props.match.url}/edit/:id`}
        component={EditTemplate} />
      <Route
        path={`${props.match.url}/create`}
        component={CreateTemplate} />
      <Route
        path={`${props.match.url}`}
        component={Template} />
    </Switch>
  )
}


