import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  contactContainer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 4,
    height: 42,
    width: 42,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const ContactBox = ({ icon: Icon }) => {
  const classes = useStyles();

  return (
    <div className={classes.contactContainer}>
      <Icon />
    </div>
  );
};

export default ContactBox;
