import React from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import {
  getLanguages,
  getActiveLanguage,
  setActiveLanguage,
} from "../translate";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentUser } from "selectors/user.selector";
import { setLocale } from "reducers/ducks/user";
import { isEmpty } from "ramda";

const LanguageSelector = () => {
  const langs = useSelector(getLanguages);
  const active = useSelector(getActiveLanguage);
  const user = useSelector(getCurrentUser)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectLang = (lang) => {
    dispatch(setActiveLanguage(lang.code));
    setAnchorEl(null);

    if (!isEmpty(user)) {
      return dispatch(setLocale(lang.code))
    }
  };

  if (!active) {
    return null;
  }

  return (
    <>
      <Button
        variant="outlined"
        color="inherit"
        aria-controls="translation"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {active.code}
      </Button>
      <Menu
        id="translation"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {langs.map((lang) => {
          return (
            <MenuItem key={lang.code} onClick={() => selectLang(lang)}>
              {lang.name}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default LanguageSelector;
