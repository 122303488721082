import React from 'react';
import { Field } from 'formik';
import { Grid } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { path } from 'ramda';

import InputField from 'shared/components/input';
import UserSelect from 'shared/components/select-user';
import SelectStatus from 'shared/components/select-status';
import { createField } from 'shared/components/datePicker';
import { withTranslate } from 'shared/components/translate';
import DescriptionInput from './descriptionField'
import selectUserIndicator from 'shared/components/select-user-indicator';

const Picker = createField(KeyboardDatePicker);

const ProblemForm = ({ translate, values, setValue, multiAssignees }) => (
  <Grid container spacing={1}>
    <Grid container item spacing={1}>
      <Grid item xs={12} md={6}>
        <Field
          required
          fullWidth
          name='description'
          multiline
          rows={3}
          label={translate("problems.table.description")}
          component={DescriptionInput}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          fullWidth
          component={InputField}
          multiline
          rows={3}
          name='actionDescription'
          label={translate("problems.table.actionDescription")}
        />
      </Grid>
    </Grid>
    <Grid container item xs={12} md={6}>
      <Grid item xs={12}>
        <Field
          fullWidth
          required
          component={UserSelect}
          onChange={() => {
            setValue('indicator', null)
          }}
          name='createdBy'
          label={translate("problems.table.createdBy")}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          required
          fullWidth
          isMulti={multiAssignees}
          component={UserSelect}
          name='assignTo'
          label={translate("problems.table.assignTo")}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          required
          fullWidth
          component={SelectStatus}
          name='status'
          label={translate("problems.table.status")}
        />
      </Grid>
    </Grid>
    <Grid container item xs={12} md={6}>
      <Grid item xs={12}>
        <Field
          fullWidth
          disableToolbar
          autoOk
          allowKeyboardControl
          variant="inline"
          format="YYYY-MM-DD"
          component={Picker}
          placeholder={translate("common.select")}
          name='firstDate.date'
          label={translate("problems.table.date1")}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          autoOk
          fullWidth
          disableToolbar
          variant="inline"
          format="YYYY-MM-DD"
          component={Picker}
          placeholder={translate("common.select")}
          name='secondDate.date'
          label={translate("problems.table.date2")}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          autoOk
          fullWidth
          disableToolbar
          variant="inline"
          format="YYYY-MM-DD"
          component={Picker}
          placeholder={translate("common.select")}
          name='thirdDate.date'
          label={translate("problems.table.date3")}
        />
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Field
        fullWidth
        type='employee'
        component={selectUserIndicator}
        userId={path(['createdBy', 'id'], values)}
        name='indicator'
        label={translate("problems.table.employeeIndicator")}
      />
    </Grid>
  </Grid>
)

export default withTranslate(ProblemForm)