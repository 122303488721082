import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { WithRbac } from 'common/rbac';
import { ROLES } from 'common/roles';
import { useTranslate } from 'shared/components/translate';

export const ActionContainer = ({ onDelete, onEdit }) => {
  const translate = useTranslate();

  return (
    <Grid container justify='flex-end' spacing={1}>
      <WithRbac action={ROLES["INDICATOR-DELETE-EMPLOYEE"]}>
        <Grid item>
          <Button
            variant="contained"
            color='secondary'
            onClick={onDelete}
          >
            {translate("common.delete")}
          </Button>
        </Grid>
      </WithRbac>
      <WithRbac action={ROLES["INDICATOR-UPDATE-EMPLOYEE"]}>
        <Grid item>
          <Button
            variant="contained"
            color='primary'
            onClick={onEdit}
          >
            {translate("common.edit")}
          </Button>
        </Grid>
      </WithRbac>
    </Grid>
  )
}