import { assoc, path } from 'ramda';
import { actions } from '../shared/components/notifications';
import { getTranslate } from '../shared/components/translate';
import qs from 'qs';

export const fetchUserList = filter => (dispatch, getState, { api }) => {
  dispatch({ type: 'user/fetch_user_list_request' });

  return api.get(`/private/user/list?${qs.stringify(filter)}`)
    .then(result => {
      dispatch({
        type: 'user/fetch_user_list_success',
        payload: result.data,
      })
    });
}

export const createUser = user => (dispatch, getState, { api }) => {
  dispatch({ type: 'user/create_user_request' });

  return api.post('/private/user/create', user)
    .then(result => {
      dispatch({
        type: 'user/create_user_success',
        payload: result.data,
      });
    });
}

export const createUserWithCompany = form => (dispatch, getState, { api }) => {
  return api.post('/authenticate/create', form)
}

export const fetchUserById = id => (_, __, { api }) => {
  return api.get(`/private/user/id/${id}`)
    .then(response => response.data);
}

export const editUserById = (id, body) => (_, __, { api }) => {
  return api.put(`/private/user/edit/${id}`, body)
    .then(response => response.data);
}

export const changeOwnProfile = body => (dispatch, getState, { api }) => {
  const translate = getTranslate(getState());

  return api.put('/private/user/changeProfile', body)
    .catch(error => {
      dispatch(
        actions.displayMessage(
          'error',
          translate('notifications.errors.update')
        )
      );

      throw error;
    });
}

export const changeOwnPassword = body => (dispatch, getState, { api }) => {
  const translate = getTranslate(getState());

  return api.put('/private/user/changePassword', body)
    .then(response => {
      dispatch(
        actions.displayMessage(
          'success',
          translate('notifications.success.edit')
        )
      );
      return response;
    })
    .catch(error => {
      dispatch(
        actions.displayMessage(
          'error',
          translate('notifications.errors.edit')
        )
      );

      throw error;
    });
}