import React from 'react';
import { useDispatch } from 'react-redux';
import { path } from 'ramda';

import { AsyncSelect } from 'shared/components/select';
import { createField } from './selectField';
import { findUnits } from 'reducers/ducks/unit/findUnit';
import { useTranslate } from './translate';

const SelectUnit = ({ type, ...props }) => {
  const dispatch = useDispatch();
  const translate = useTranslate();

  return (
    <AsyncSelect
      {...props}
      loadOptions={() => dispatch(findUnits()).then(response => response.data)}
      getOptionValue={path(["id"])}
      getOptionLabel={opt => translate(`enums.units.${opt.name}`)}
      noOptionsMessage={() => translate("common.noRecords")}
      menuPlacement='bottom'
      menuPosition='fixed'
    />
  );
}

export default createField(SelectUnit)