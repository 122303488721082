import React from "react";
import { Grid, Typography } from "@material-ui/core";
import Slider from "react-slick";

import { useMatchMobile } from "shared/hooks/responsive";
import { useTranslate } from "shared/components/translate";
import CvCard from "./cvCard";

import Rokas from "assets/img/rokasCard.png";
import Rimtautas from "assets/img/rimtautasCard.png";
import Rytis from "assets/img/Rytis.jpg";
import Erlandas from "assets/img/erlandas.jpg";

const instructors = [
  {
    name: "Rokas Milovanovas",
    photo: Rokas,
    link: "/home/instructor/1",
    email: "rokas@letslean.lt",
    phone: "+37068692737",
    linkedin: "https://www.linkedin.com/in/rokas-milovanovas-0bbb8b150/",
  },
  {
    name: "Rytis Juozapavičius",
    photo: Rytis,
    link: "/home/instructor/3",
    email: "rytis@juozapavicius.com",
    linkedin: "http://linkedin.com/in/rytis-juozapavicius-4a986910",
  },
  {
    name: "Erlandas Jakubonis",
    photo: Erlandas,
    link: "/home/instructor/4",
    email: "erlandas.jakubonis@gmail.com",
    linkedin: "https://www.linkedin.com/in/erlandasjakubonis",
  },
  {
    name: "Rimtautas Dališanskis",
    photo: Rimtautas,
    link: "/home/instructor/2",
    email: "rimtautas@letslean.lt",
    phone: "+37060794278",
    linkedin: "https://www.linkedin.com/in/rimtautasdalisanskis/",
  },
];

const CvCards = () => {
  const isMobile = useMatchMobile();
  const translate = useTranslate();

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: isMobile ? 1 : 1,
  };

  return (
    <Grid container justify="center">
      <Grid item xs={11} lg={9}>
        <Grid container>
          <Grid item xs={12} style={{ padding: "0 0 92px 0" }}>
            <Typography variant="h4" color="textPrimary">
              {translate("landingPage.cv.title")}
            </Typography>
          </Grid>
        </Grid>
        <div style={{ padding: "0 16px" }}>
          <Slider adaptiveHeight={false} {...settings}>
            {instructors.map((inctructor) => {
              return <CvCard {...inctructor} />;
            })}
          </Slider>
        </div>
      </Grid>
    </Grid>
  );
};

export default CvCards;
