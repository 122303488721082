export const ROLES = {
  'COMPANY-CREATE': 'COMPANY-CREATE',
  'COMPANY-UPDATE': 'COMPANY-UPDATE',
  'COMPANY-READ': 'COMPANY-READ',
  'DEPARTMENT-CREATE': 'DEPARTMENT-CREATE',
  'DEPARTMENT-UPDATE': 'DEPARTMENT-UPDATE',
  'DEPARTMENT-READ': 'DEPARTMENT-READ',
  'DEPARTMENT-DELETE': 'DEPARTMENT-DELETE',
  'USER-CREATE': 'USER-CREATE',
  'USER-UPDATE': 'USER-UPDATE',
  'USER-READ': 'USER-READ',
  'INDICATOR-CREATE-VISION': 'INDICATOR-CREATE-VISION',
  'INDICATOR-UPDATE-VISION': 'INDICATOR-UPDATE-VISION',
  'INDICATOR-READ-VISION': 'INDICATOR-READ-VISION',
  'INDICATOR-CREATE-STRATEGY': 'INDICATOR-CREATE-STRATEGY',
  'INDICATOR-UPDATE-STRATEGY': 'INDICATOR-UPDATE-STRATEGY',
  'INDICATOR-READ-STRATEGY': 'INDICATOR-READ-STRATEGY',
  'INDICATOR-DELETE-STRATEGY': 'INDICATOR-DELETE-STRATEGY',
  'INDICATOR-CREATE-DEPARTMENT': 'INDICATOR-CREATE-DEPARTMENT',
  'INDICATOR-UPDATE-DEPARTMENT': 'INDICATOR-UPDATE-DEPARTMENT',
  'INDICATOR-READ-DEPARTMENT': 'INDICATOR-READ-DEPARTMENT',
  'INDICATOR-DELETE-DEPARTMENT': 'INDICATOR-DELETE-DEPARTMENT',
  'INDICATOR-CREATE-EMPLOYEE': 'INDICATOR-CREATE-EMPLOYEE',
  'INDICATOR-UPDATE-EMPLOYEE': 'INDICATOR-UPDATE-EMPLOYEE',
  'INDICATOR-READ-EMPLOYEE': 'INDICATOR-READ-EMPLOYEE',
  'INDICATOR-DELETE-EMPLOYEE': 'INDICATOR-DELETE-EMPLOYEE',
  'INDICATOR-RECORDS-CREATE': 'INDICATOR-RECORDS-CREATE',
  'INDICATOR-RECORDS-UPDATE': 'INDICATOR-RECORDS-UPDATE',
  'INDICATOR-RECORDS-READ': 'INDICATOR-RECORDS-READ',
  'INDICATOR-TEMPLATE-CREATE': 'INDICATOR-TEMPLATE-CREATE',
  'INDICATOR-TEMPLATE-UPDATE': 'INDICATOR-TEMPLATE-UPDATE',
  'INDICATOR-TEMPLATE-READ': 'INDICATOR-TEMPLATE-READ',
  'INDICATOR-TEMPLATE-DELETE': 'INDICATOR-TEMPLATE-DELETE',
  'PROBLEMS-CREATE': 'PROBLEMS-CREATE',
  'PROBLEMS-UPDATE': 'PROBLEMS-UPDATE',
  'PROBLEMS-READ': 'PROBLEMS-READ',
  'PROBLEMS-DELETE': 'PROBLEMS-DELETE',
  'ROLE-GROUPS-CREATE': 'ROLE-GROUPS-CREATE',
  'ROLE-GROUPS-UPDATE': 'ROLE-GROUPS-UPDATE',
  'ROLE-GROUPS-READ': 'ROLE-GROUPS-READ',
  'ROLE-GROUPS-DELETE': 'ROLE-GROUPS-DELETE',
  'ACKNOWLEDGMENT-CREATE': 'ACKNOWLEDGMENT-CREATE',
  'ACKNOWLEDGMENT-UPDATE': 'ACKNOWLEDGMENT-UPDATE',
  'ACKNOWLEDGMENT-READ': 'ACKNOWLEDGMENT-READ',
  'ACKNOWLEDGMENT-DELETE': 'ACKNOWLEDGMENT-DELETE',
  'MISSION-CREATE': 'MISSION-CREATE',
  'MISSION-UPDATE': 'MISSION-UPDATE',
  'MISSION-READ': 'MISSION-READ',
  'EMAIL-INDICATOR-REMIND-FILL-VISION': 'EMAIL-INDICATOR-REMIND-FILL-VISION',
  'EMAIL-INDICATOR-REMIND-FILL-STRATEGIC': 'EMAIL-INDICATOR-REMIND-FILL-STRATEGIC',
  'EMAIL-INDICATOR-REMIND-FILL-DERPARTMENT': 'EMAIL-INDICATOR-REMIND-FILL-DERPARTMENT',
  'EMAIL-INDICATOR-REMIND-FILL-EMPLOYEE': 'EMAIL-INDICATOR-REMIND-FILL-EMPLOYEE',
  'EMAIL-PROBLEM-REMIND-ENDED': 'EMAIL-PROBLEM-REMIND-ENDED',
}