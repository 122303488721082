import React from 'react';
import { useDispatch } from 'react-redux';
import { List, ListItem, ListItemText, ListItemIcon, Checkbox, Paper } from '@material-ui/core';
import { getStrategies } from 'reducers/ducks/dashboard';


const Employee = ({ selected, setSelected, search }) => {
  const [data, setData] = React.useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getStrategies)
      .then(response => setData(response.data))
  }, [dispatch, setData]);

  const handleToggle = (user) => {
    if (selected[user.id]) {
      return setSelected({
        ...selected,
        [user.id]: undefined,
      })
    }

    setSelected({
      ...selected,
      [user.id]: user,
    })
  };


  return (
    <Paper elevation={0} style={{ marginTop: 8 }}>
      <List>
        {
          data
            .filter(indicator => indicator && indicator.name && indicator.name.toLowerCase().indexOf(search.toLowerCase()) >= 0)
            .map(indicator => {
              return (
                <ListItem key={indicator.id} role={undefined} dense button onClick={() => handleToggle(indicator)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={!!selected[indicator.id]}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': indicator.id }}
                    />
                  </ListItemIcon>
                  <ListItemText id={indicator.id} primary={indicator.name} />
                </ListItem>
              )
            })
        }
      </List>
    </Paper>
  )
}

export default Employee;