import React from 'react';

import LoaderContainer from 'shared/components/loaderContainer';
import { ErrorBoundary } from 'shared/components/errorBoundary';
import NoContentContainer from 'shared/components/noContentContainer';

export class IndicatorLoader extends React.Component {
  state = {
    data: null,
    fetching: true,
  }

  componentDidMount() {
    this.handleFetchData();
  }

  componentDidUpdate(oldProps) {
    if (this.props.id !== oldProps.id && !!this.props.fetchData) {
      this.handleFetchData();
    }
  }

  handleFetchData = () => {
    this.props.fetchData(this.props.id)
      .then(response => {
        this.setState({
          data: response.data,
        })
      })
      .finally(() => {
        this.setState({ fetching: false });
      });
  }

  render() {
    return (
      <LoaderContainer fetching={this.state.fetching}>
        <NoContentContainer noContent={!this.state.data}>
          <ErrorBoundary>
            {this.props.children({ data: this.state.data || {} })}
          </ErrorBoundary>
        </NoContentContainer>
      </LoaderContainer>
    )
  }
}