import { combineReducers } from "redux"
import qs from 'qs';

import filterReducer from './filter';
import { getDasboardFilter } from "selectors/dashboard";

export const getEmployeeDashboard = (id) => (_, getState, { api }) => {
  const filters = getDasboardFilter(getState());

  return api.get(`/private/dashboard/employee/${id}?${qs.stringify(filters)}`)
}

export const getEmployeeIndicatorDashboard = (indicatorId) => (_, getState, { api }) => {
  const filters = getDasboardFilter(getState());

  return api.get(`/private/dashboard/employee/indicator/${indicatorId}?${qs.stringify(filters)}`)
}

export const getDepartmentDashboard = (id) => (_, getState, { api }) => {
  const filters = getDasboardFilter(getState());

  return api.get(`/private/dashboard/department/${id}?${qs.stringify(filters)}`)
}

export const getDepartmentIndicatorDashboard = (id, indicatorId) => (_, getState, { api }) => {
  const filters = getDasboardFilter(getState());

  return api.get(`/private/dashboard/department/${id}/indicator/${indicatorId}?${qs.stringify(filters)}`)
}

export const getCompanyDashboard = (id) => (_, getState, { api }) => {
  const filters = getDasboardFilter(getState());

  return api.get(`/private/dashboard/company?${qs.stringify(filters)}`)
}
export const getStrategyDashboard = (id) => (_, getState, { api }) => {
  const filters = getDasboardFilter(getState());

  return api.get(`/private/dashboard/strategy/${id}?${qs.stringify(filters)}`)
}

export const getStrategies = (_, __, { api }) => {
  return api.get(`/private/indicator/strategic/list`)
}


export const fetchGroupUser = (_, __, { api }) => {
  return api.get('/private/user/listManagedUsers')
}

export const fetchManagedGroupIndiactors = (_, __, { api }) => {
  return api.get('/private/indicator/listMangedGroupIndicators')
}

export default combineReducers({ filter: filterReducer })