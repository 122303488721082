import React from 'react';
import { useDispatch } from 'react-redux';
import { path } from 'ramda';

import { AsyncSelect as Select } from 'shared/components/select';
import { createField } from './selectField';
import { findIndicator } from 'reducers/ducks/indicator/findIndicator';

const SelectDepartmentStrategy = (props) => {
  const dispatch = useDispatch();

  return (
    <Select
      {...props}
      async
      loadOptions={q => dispatch(findIndicator('group')(q))}
      getOptionValue={path(["id"])}
      getOptionLabel={path(["name"])}
      noOptionsMessage={() => 'Nėra įrašų'}
      menuPlacement='bottom'
      menuPosition='fixed'
    />
  );
}

export default createField(SelectDepartmentStrategy)