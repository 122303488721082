export default function createNamedWrapperReducer(
  reducerFunction,
  reducerName
) {
  return (state, action) => {
    const { name } = action;
    const isInitializationCall = state === undefined;
    if (name !== reducerName && !isInitializationCall) return state;

    return reducerFunction(state, action);
  };
}

export const createPaginationString = (tableState) => {
  let str = "";

  if (tableState?.pageIndex != null) {
    str += `page=${tableState?.pageIndex}&size=25`;
  }

  // desc: false
  // id: "createdAt"

  if (tableState.sortBy?.length) {
    // str += `&sortBy=${tableState.sortBy[0]?.id}&orderBy=${
    //   tableState.sortBy[0]?.desc ? "DESC" : "ASC"
    // }`;
    str += `&orderBy=${tableState.sortBy[0]?.id}&sortBy=${tableState.sortBy[0]?.desc ? "desc" : "asc"
      }`;
  }

  return str;
};
