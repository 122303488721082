import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useMatchMobile } from "shared/hooks/responsive";
import Vision from "assets/img/vision.jpg";
import Mistakes from "assets/img/mistakes.jpg";
import Meetings from "assets/img/meetings.jpg";
import BlogCard from "./blogCard";
import { useTranslate } from "shared/components/translate";

const blogPost = [
  {
    id: 1,
    photo: Vision,
    photoTitle: "vision",
    title: "landingPage.blogPost.post1.title",
    description: "landingPage.blogPost.post1.subtitle1",
    link: "/home/post/1",
  },
  {
    id: 2,
    photo: Mistakes,
    photoTitle: "mistakes",
    title: "landingPage.blogPost.post2.title",
    description: "landingPage.blogPost.post2.p1",
    link: "/home/post/2",
  },
  {
    id: 3,
    photo: Meetings,
    photoTitle: "title",
    title: "landingPage.blogPost.post3.title",
    description: "landingPage.blogPost.post3.l1",
    link: "/home/post/3",
  },
];

const BlogPosts = () => {
  const translate = useTranslate();

  return (
    <Grid container justify="center">
      <Grid item xs={11} lg={9}>
        <Grid container>
          <Grid item xs={12} style={{ padding: "0 0 92px 0" }}>
            <Typography variant="h4" color="textPrimary">
              {translate("landingPage.blogPost.title")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {blogPost.map((post) => {
                return (
                  <Grid item xs={12} key={post.id}>
                    <BlogCard
                      title={translate(post.title)}
                      photo={post.photo}
                      description={post.description}
                      photoTitle={post.photoTitle}
                      link={post.link}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BlogPosts;
