import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid, Button, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { getSearchField, getFilterSelectors } from 'selectors/problem.selector';
import { setSearch, setOtherFields } from 'reducers/ducks/problems/filter/fields';
import SelectStatus from 'shared/components/select-status';
import SelectUser from 'shared/components/select-user';
import { fetchProblemsToStore } from 'reducers/ducks/problems/list';
import { createField } from 'shared/components/datePicker'
import { useTranslate } from 'shared/components/translate';
import selectDepartment from 'shared/components/select-department';
import { getCurrentUser } from 'selectors/user.selector';
import { fetchProblemsTable, problemTableSelectors, setProblemsFilter } from 'reducers/ducks/problems/table';
import InputField from 'shared/components/input'

const DateField = createField(KeyboardDatePicker);

const FilterContainer = () => {
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const translate = useTranslate();
  const initialValues = useSelector(problemTableSelectors.getFilter);
  const searchField = initialValues?.description;
  console.log(initialValues);
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        dispatch(setProblemsFilter(values));
        dispatch(fetchProblemsTable());
      }}
    >
      {
        () => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={3}>
                    <Field
                      fullWidth
                      component={InputField}
                      placeholder={translate("common.enter")}
                      name="search"
                      label={translate("problems.table.description")}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Field
                      fullWidth
                      isMulti
                      name='status'
                      component={SelectStatus}
                      label={translate("problems.table.status")} />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Field
                      fullWidth
                      isMulti
                      name='assignedTo'
                      component={SelectUser}
                      label={translate("problems.table.assignTo")}
                    />
                  </Grid>
                  {
                    !!user?.departmentManager?.length && (
                      <Grid item xs={12} md={3}>
                        <Field
                          fullWidth
                          isMulti
                          name='group'
                          component={selectDepartment}
                          label={translate("problems.table.group")}
                        />
                      </Grid>
                    )
                  }
                  <Grid item xs={12} md={3}>
                    <Field
                      fullWidth
                      isMulti
                      name='createdBy'
                      component={SelectUser}
                      label={translate("problems.table.createdBy")}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Field
                      fullWidth
                      autoOk
                      name='createdAt'
                      component={DateField}
                      label={translate("problems.table.createAt")}
                      format='YYYY-MM-DD'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='secondary'
                      type='submit'
                      size='small'
                    >
                      <Search />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )
      }
    </Formik>
  )
}

export default FilterContainer;