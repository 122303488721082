import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartmentDashboard } from 'reducers/ducks/dashboard';

import ChartWrapper from '../../chartWrapper';

import CountProblem from './countProblems';
import CountUniqueStatus from './countUniquestatus';
import { getDasboardFilter } from 'selectors/dashboard';
import { useTranslate } from 'shared/components/translate';
import InfoChartWrapper from 'components/dashboards/infoChartWrapper';

const Charts = ({ department }) => {
  const [data, setData] = React.useState({});
  const dispatch = useDispatch();
  const filters = useSelector(getDasboardFilter)
  const translate = useTranslate();

  React.useEffect(() => {
    dispatch(getDepartmentDashboard(department.id))
      .then(response => {
        setData(response.data)
      });
  }, [dispatch, filters]);


  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <div style={{ height: 48 }}>
          <Grid container alignItems="center" className="h-100">
            <Grid item>
              <Typography variant="subtitle1"
                color="primary">{department.name}</Typography>
            </Grid>
          </Grid>
        </div>

      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={4}>
            <InfoChartWrapper label={translate('dashboards.departmentCharts.departmentProblems.title')}>
              <CountUniqueStatus data={data.departmentsProblems} />
            </InfoChartWrapper>
          </Grid>
          <Grid item xs={12}>
            <CountProblem departmentId={department.id} />
          </Grid>
        </Grid>
      </Grid>
    </Grid >
  )
}

export default Charts;