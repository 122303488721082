import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import { useTranslate } from "shared/components/translate";
import Phone from "assets/img/phone.png";
// import landingPhotoSrc from "assets/svg/chart2.svg";

const LandingPhoto = () => {
  const translate = useTranslate();

  return (
    <div className="landing-photo h-100" style={{ color: "white" }}>
      <Grid container alignItems="flex-start" className="h-100">
        <Grid item style={{ paddingTop: 92 }}>
          <Grid container justify="space-between" className="h-100" spacing={6}>
            <Grid item xs={12} lg={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    variant="h3"
                    color="inherit"
                    style={{ fontWeight: "bold" }}
                  >
                    {translate("landingPage.mainTitle")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={6} md="auto">
                      <Button
                        fullWidth
                        component={Link}
                        to="/auth/login"
                        variant="contained"
                        color="secondary"
                        disableElevation
                      >
                        {translate("landingPage.login")}
                      </Button>
                    </Grid>
                    <Grid item xs={6} md="auto">
                      <Button
                        fullWidth
                        component={Link}
                        to="/auth/create"
                        color="inherit"
                      >
                        {translate("landingPage.register")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} md={8}>
              <img
                src={Phone}
                title="myKPI.eu"
                style={{ width: "100%" }}
              />
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default LandingPhoto;
