import React from "react";
import { Divider, Grid, Typography } from "@material-ui/core";
import { path } from "ramda";

import { Date } from "shared/components/date";
import InformationBox from "./informationBox";
import Status from "../status";
import { useTranslate } from "shared/components/translate";
import UserAvatar from "../avatar";

const DetailViewContainer = ({ problem }) => {
  const translate = useTranslate();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container wrap="nowrap" spacing={1}>
          <Grid item>
            <Status status={problem.status} />
          </Grid>
          <Grid item>{translate(`enums.statuses.${problem.status.name}`)}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: 24 }}>
        <Typography variant="h4">{problem.description}</Typography>
      </Grid>

      <Grid item xs={6}>
        <InformationBox title={translate("problems.table.assignTo")}>
          <UserAvatar
            firstName={path(["assignTo", "firstName"], problem)}
            lastName={path(["assignTo", "lastName"], problem)}
          />
        </InformationBox>
      </Grid>
      <Grid item xs={6}>
        <InformationBox title={translate("problems.table.createdBy")}>
          <UserAvatar
            firstName={path(["createdBy", "firstName"], problem)}
            lastName={path(["createdBy", "lastName"], problem)}
          />
        </InformationBox>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 24, paddingBottom: 24 }} />
      {!!problem.actionDescription && (
        <Grid item xs={12} style={{ paddingBottom: 24 }}>
          <InformationBox title={translate("problems.table.actionDescription")}>
            {problem.actionDescription}
          </InformationBox>
        </Grid>
      )}

      {
        !!path(
          ["indicator", "name"],
          problem && (
            <Grid item xs={12} style={{ paddingBottom: 24 }}>
              <InformationBox
                title={translate("problems.table.connectedIndicator")}
              >
                {path(["indicator", "name"], problem)}
              </InformationBox>
            </Grid>
          )
        )
      }

      <Grid item xs={4}>
        <InformationBox title={translate("problems.table.date1")}>
          <Date
            format="YYYY-MM-DD"
            date={path(["firstDate", "date"], problem)}
          />
        </InformationBox>
      </Grid>
      <Grid item xs={4}>
        <InformationBox title={translate("problems.table.date2")}>
          <Date
            format="YYYY-MM-DD"
            date={path(["secondDate", "date"], problem)}
          />
        </InformationBox>
      </Grid>
      <Grid item xs={4}>
        <InformationBox title={translate("problems.table.date3")}>
          <Date
            format="YYYY-MM-DD"
            date={path(["thirdDate", "date"], problem)}
          />
        </InformationBox>
      </Grid>
    </Grid>
  );
};

export default DetailViewContainer;
