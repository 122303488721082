import React, { useCallback } from "react";
import { Field } from "formik";
import { map, compose, flatten, filter } from "ramda";

import SelectRole from "shared/components/roles/select-role";
import { withTranslate } from "../../../shared/components/translate";

const FilteredRoles = ({ translate, field: { value } }) => {
  const filterAssignedRolesFromRoleGroups = useCallback(
    (role) => {
      try {
        const roles = compose(
          filter((val) => !!val),
          map((role) => role?.name),
          flatten,
          map((roleGroup) => roleGroup?.roles)
        )(value || []);

        return roles.indexOf(role?.name) < 0;
      } catch (error) {
        return true;
      }
    },
    [value]
  );

  return (
    <Field
      fullWidth
      isMulti
      optionFilter={filterAssignedRolesFromRoleGroups}
      component={SelectRole}
      label={translate("users.form.rights.roles")}
      name="roles"
    />
  );
};

export default withTranslate(FilteredRoles);
