import { path } from 'ramda';

import { SET_STRATEGY } from '../actionTypes';

const SET_STRATEGY_ROW = 'MYKPI/COMPANY_STRATEGY/STRATEGY_ROW';
const defaultState = {}

export const selectors = pt => {
  return {
    isOpen(name) {
      return path([...pt, name])
    },
  }
}

export default (state = defaultState, { type, payload, name } = {}) => {
  switch (type) {
    case SET_STRATEGY:
      return Object.values(payload)
        .reduce(
          (acc, goal) => ({
            ...acc,
            [goal.id]: state[goal.id] || false
          }),
          {}
        )
    case SET_STRATEGY_ROW:
      return {
        ...state,
        [name]: payload
      }
    default:
      return state;
  }
}

export const openStrategyRow = name => ({
  type: SET_STRATEGY_ROW,
  payload: true,
  name
});

export const closeStrategyRow = name => ({
  type: SET_STRATEGY_ROW,
  payload: false,
  name
});

export const toggleOpenStrategyRow = (name, open) => ({
  type: SET_STRATEGY_ROW,
  payload: !open,
  name
});