import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Field } from "formik";

import SelectDepartment from "../../../shared/components/select-department";
import FilteredRoles from "./filteredRoles";
import SelectRoleGroup from "shared/components/select-roleGroup";
import { withTranslate } from "../../../shared/components/translate";

const RightsInfo = ({ translate }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          {translate("users.form.rights.title")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          isMulti
          label={translate("users.form.rights.departmentManager")}
          component={SelectDepartment}
          name="departmentManager"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          isMulti
          component={SelectRoleGroup}
          label={translate("users.form.rights.roleGroups")}
          name="roleGroups"
        />
      </Grid>
      <Grid item xs={12}>
        <Field name="roleGroups" component={FilteredRoles} />
      </Grid>
    </Grid>
  );
};

export default withTranslate(RightsInfo);
