import React from 'react';
import { Field } from 'formik';
import { Grid, Divider } from '@material-ui/core';

import TextField from 'shared/components/input';
import IndicatorForm from './indicatorForm';
import { useTranslate } from 'shared/components/translate';

const IndicatorTemplateForm = () => {
  const translate = useTranslate();

  return (
    <Grid container>
      <Grid item xs={12} md={6} lg={4}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Field
              required
              fullWidth
              name='templateName'
              label={translate("template.table.templateName")}
              component={TextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <IndicatorForm />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default IndicatorTemplateForm;