import { pathOr, path } from 'ramda';

export const getIndicators = pathOr([], ['indicator', 'list']);
export const getActiveIndicator = path(['indicator', 'active']);
export const getSelectedIndicator = pathOr({}, ['indicator', 'selected']);
export const getSelectedRecord = pathOr({}, ['indicator', 'activeRecord']);
export const getYearRange = pathOr({}, ['indicator', 'yearRange']);
export const isOpenEditDialog = pathOr(false, ['indicator', 'editDialog']);
export const isOpenCreateDialog = pathOr(false, ['indicator', 'createDialog']);
export const isOpenCreateRecordDialog = pathOr(false, ['indicator', 'createRecordDialog']);
export const isOpenEditRecordDialog = pathOr(false, ['indicator', 'editRecordDialog']);

export const getFilterFrom = path(['indicator', 'recordDateRange', 'from']);
export const getFilterTill = path(['indicator', 'recordDateRange', 'till']);
export const getSearchField = path(['indicator', 'filter', 'fields', 'search']);
export const getAllSearchFields = path(['indicator', 'filter', 'fields']);
export const fetchIndicatorId = path(['indicator', 'filter', 'fetchId', 'id']);
export const fetchIndicatorIds = path(['indicator', 'fetchId', 'id']);