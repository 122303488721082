import React from 'react';
import { path, split } from 'ramda';
import { FormControlLabel, Checkbox } from '@material-ui/core';


import { withTranslate } from './translate';

const CustomCheckbox = ({
  field,
  form: { touched, errors },
  translate,
  ...rest
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          {...field}
          checked={!!path(['value'], field)}
          color="primary"
        />
      }
      {...rest}
    />)
}

export default withTranslate(CustomCheckbox)