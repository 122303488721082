import qs from "qs";
import {
  createFetchTableData,
  createSetFilter,
  createSetTableState,
  createSelectors,
} from "reducers/ducks/common/tablePagination";
import { createPaginationString } from "../common/utils";

export const PROBLEMS_TABLE_NAME = "@PROBLEMS_TABLE";

export const setProblemsFilter = createSetFilter(PROBLEMS_TABLE_NAME);
export const setProblemsTableState = createSetTableState(PROBLEMS_TABLE_NAME);
export const problemTableSelectors = createSelectors(["problem", "table"]);

export const fetchProblemsTable = createFetchTableData({
  name: PROBLEMS_TABLE_NAME,
  urlFormatter({ filter, tableState }) {
    return `/private/problems/list?${createPaginationString(
      tableState
    )}&${qs.stringify(filterFormatter(filter))}`;
  },
  path: ["problem", "table"],
});


const filterFormatter = (fields) => {
  if (!fields) {
    return {};
  }

  const formattedFields = { ...fields };

  if (fields.status && Array.isArray(fields.status)) {
    formattedFields.status = fields.status.map(stat => stat.id)
  }

  if (fields.assignedTo && Array.isArray(fields.assignedTo)) {
    formattedFields.assignedTo = fields.assignedTo.map(user => user.id)
  }

  if (fields.createdBy && Array.isArray(fields.createdBy)) {
    formattedFields.createdBy = fields.createdBy.map(user => user.id)
  }
  if (fields.group && Array.isArray(fields.group)) {
    formattedFields.group = fields.group.map(group => group.id)
  }

  return formattedFields
}