import React from 'react';
import { useDispatch } from 'react-redux';
import { path } from 'ramda';

import { AsyncSelect } from 'shared/components/select';
import { createField } from './selectField';
import { findIndicatorPeriod } from 'reducers/ducks/indicator/findIndicatorPeriod';
import { useTranslate } from './translate';

const SelectIndicatorPeriod = ({ type, ...props }) => {
  const dispatch = useDispatch();
  const translate = useTranslate();

  return (
    <AsyncSelect
      {...props}
      loadOptions={val => dispatch(findIndicatorPeriod(val)).then(response => response.data)}
      getOptionValue={path(["id"])}
      getOptionLabel={opt => translate(`enums.indicatorPeriod.${opt.name}`)}
      noOptionsMessage={() => translate("common.noRecords")}
      menuPlacement='bottom'
      menuPosition='fixed'
    />
  );
}

export default createField(SelectIndicatorPeriod)