import {
  FormControl,
  FormGroup,
  FormLabel,
  RadioGroup,
} from "@material-ui/core";
import React, { useMemo } from "react";
import { path, split } from "ramda";
import { CheckboxGroupContext } from "./context";

const getPath = split(".");
const CheckboxGroup = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  children,
  valueField,
  labelField,
  required,
  variant = "checkbox",
  ...rest
}) => {
  const pt = getPath(field.name);
  const fieldTouched = path(pt, touched);
  const fieldErrors = path(pt, errors);

  const contextValue = useMemo(
    () => ({
      onChange: (value) => setFieldValue(field.name, value),
      value: field.value,
      checked: field.checked,
      valueField,
      labelField,
      variant: variant,
    }),
    [setFieldValue, field, valueField, labelField]
  );

  return (
    <CheckboxGroupContext.Provider value={contextValue}>
      <FormControl
        required={required}
        error={fieldErrors && fieldTouched}
        component="fieldset"
        {...rest}
      >
        <FormLabel component="legend">{label}</FormLabel>
        {variant === "radio" ? (
          <RadioGroup
            name={field.name}
            value={field.value}
            onChange={(e, value) => setFieldValue(field.name, value)}
          >
            {children}
          </RadioGroup>
        ) : (
          <FormGroup>{children}</FormGroup>
        )}
      </FormControl>
    </CheckboxGroupContext.Provider>
  );
};

export default CheckboxGroup;
