import React from "react";
import { Button, Grid, Collapse } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getDasboardFilter } from "selectors/dashboard";
import { DateRange } from "@material-ui/icons";
import SplitButton from "shared/components/splitButton";
import PageHeader from "shared/components/pageHeader";

import { ROLES } from "common/roles";
import { WithRbac } from "common/rbac";
import Filter from "./views/dateFilter";
import { Date } from "shared/components/date";
import SwitchDashboards from "./switchDashboards";
import { useTranslate } from "shared/components/translate";
import { useDashboardDropdownOptions } from "./hooks";

const MainDashboard = () => {
  const [dashboard, setDashboard] = React.useState("employee");
  const handleSelect = (event, option) => setDashboard(option.type);
  const [open, setOpen] = React.useState();
  const fields = useSelector(getDasboardFilter);
  const options = useDashboardDropdownOptions();
  const translate = useTranslate();
  const Icon = options.find((option) => option.type === dashboard).icon

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader title={translate('dashboards.title')}>
          <Grid container justify="flex-end" alignItems="center" spacing={1}>
            <Grid item>
              <Button
                onClick={() => setOpen(!open)}
                variant="outlined"
                color="primary"
              >
                <DateRange />
              </Button>
            </Grid>
            <WithRbac
              action={[
                ROLES["INDICATOR-READ-VISION"],
                ROLES["INDICATOR-READ-STRATEGY"],
                ROLES["INDICATOR-READ-DEPARTMENT"],
              ]}
            >
              <Grid item>
                <SplitButton
                  title={<Icon />}
                  handleClick={handleSelect}
                  options={options}
                />
              </Grid>
            </WithRbac>
          </Grid>
        </PageHeader>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={open}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
            >
              <div style={{ padding: 16 }}>
                <Filter handleClose={() => setOpen(false)}>
                  <Button variant="contained" color="secondary" type="submit">
                    {translate('common.search')}
                  </Button>
                </Filter>
              </div>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={!open}>
          <div style={{ padding: "16px 16px 0 0" }}>
            <Grid container justify="flex-end" spacing={1}>
              <Grid item>
                <Date format="YYYY-MM" date={fields.from} />
              </Grid>
              <Grid item>-</Grid>
              <Grid item>
                <Date format="YYYY-MM" date={fields.till} />
              </Grid>
            </Grid>
          </div>
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <SwitchDashboards type={dashboard} />
      </Grid>
    </Grid>
  );
};

export default MainDashboard;
