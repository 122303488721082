import React from "react";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Formik, Field } from "formik";
import { path } from "ramda";
import moment from "moment";

import { createField } from "shared/components/datePicker";
// import { validationSchemaRecordDateRange } from './validationSchema';
import { getDasboardFilter } from "selectors/dashboard";
import { setDashbordFields } from "reducers/ducks/dashboard/filter";
import { useTranslate } from "shared/components/translate";

const DateField = createField(KeyboardDatePicker);

const Filter = ({ children, handleClose }) => {
  const initialValues = useSelector(getDasboardFilter);
  const dispatch = useDispatch();
  const translate = useTranslate();

  return (
    <Formik
      // validationSchema={validationSchemaRecordDateRange}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(val) => {
        dispatch(setDashbordFields(val));
        handleClose();
      }}
    >
      {(formProps) => (
        <form onSubmit={formProps.handleSubmit}>
          <div style={{ padding: 4 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
                <Field
                  fullWidth
                  required
                  autoOk
                  views={["month"]}
                  variant="inline"
                  name="from"
                  label={translate("indicators.filters.from")}
                  format="YYYY-MM"
                  maxDate={path(["values", "till"], formProps)}
                  maxDateMessage={translate("indicators.filters.maxDateMessage")}
                  component={DateField}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  fullWidth
                  required
                  autoOk
                  variant="inline"
                  views={["month"]}
                  name="till"
                  label={translate("indicators.filters.till")}
                  format="YYYY-MM"
                  maxDateMessage={translate("indicators.filters.minDateMessage")}
                  minDate={path(["values", "from"], formProps)}
                  component={DateField}
                />
              </Grid>
              <Grid item xs={12}>
                {children}
              </Grid>
            </Grid>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Filter;
