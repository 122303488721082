import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { fetchRoles } from "reducers/ducks/role";

export const useFetchRoles = ({ optionFilter }) => {
  const dispatch = useDispatch();
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const filteredOptions = useMemo(() => {
    if (!!optionFilter && typeof optionFilter === "function") {
      return options.filter(optionFilter);
    }

    return options;
  }, [optionFilter, options]);

  React.useEffect(() => {
    setLoading(true);
    dispatch(fetchRoles)
      .then((response) => setOptions(response.data))
      .finally(() => setLoading(false));
  }, [dispatch, setOptions, setLoading]);

  return { loading, options: filteredOptions };
};
