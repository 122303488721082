import React from "react";
import { Formik, Form } from "formik";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
} from "@material-ui/core";
import { useDispatch } from "react-redux";

import IndicatorForm, {
  validationSchema,
} from "components/indicators/form/employee/create";
import { createEmployeeIndicator } from "reducers/ducks/indicator/createIndicator";
import { fetchIndicatorFilter } from "reducers/ducks/indicator";
import { useTranslate } from "shared/components/translate";

const CreateEmployeeIndicator = ({ open, handleClose, ...props }) => {
  const dispatch = useDispatch();
  const [initialValues, setInitial] = React.useState({});
  const translate = useTranslate();

  return (
    <Dialog open={open} onClose={handleClose}>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(value) => {
          dispatch(createEmployeeIndicator(value))
            .then(() => dispatch(fetchIndicatorFilter()))
            .then(() => handleClose());
        }}
      >
        {({ values, isValid }) => (
          <Form>
            <DialogTitle>
              {translate("indicators.dialogs.employee.title")}
            </DialogTitle>
            <DialogContent>
              <IndicatorForm setInitial={setInitial} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>
                {translate("common.cancel")}
              </Button>
              <Button
                disabled={!isValid}
                variant="contained"
                color="primary"
                type="submit"
              >
                {translate("common.create")}{" "}
                {values && values.assignTo && values.assignTo.length >= 2
                  ? ` - ${values.assignTo.length}`
                  : ""}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateEmployeeIndicator;
