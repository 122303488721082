import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { IndicatorContainer } from 'components/indicators/common/indicatorContainer';
import { getMission } from 'reducers/ducks/indicator/listIndicators';
import { path } from 'ramda';
import Edit from './edit';
import { fetchIndicatorIds } from 'selectors/indicator.selector';
import { ROLES } from 'common/roles';
import { WithRbac } from 'common/rbac';
import { useTranslate } from 'shared/components/translate';

export const MissionIndicator = () => {
    const [mision, setMission] = React.useState();
    const [open, setOpen] = React.useState();
    const dispatch = useDispatch();
    const fetchId = useSelector(fetchIndicatorIds)
    const translate = useTranslate();

    React.useEffect(() => {
        dispatch(getMission)
            .then(response => setMission(response.data))
    }, [setMission, dispatch, fetchId]);

    return (
        <React.Fragment>
            <Edit open={open} handleClose={() => setOpen(false)} initialValues={mision} />
            <Grid container>
                <Grid item xs={12}>
                    <IndicatorContainer>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant='h6'>{translate("indicators.views.mission.title")}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body1'>{path(['name'], mision)}</Typography>
                            </Grid>
                            <WithRbac action={ROLES["MISSION-UPDATE"]}>
                                <Grid item xs={12}>
                                    <Grid container justify='flex-end'>
                                        <Grid item>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                type='submit'
                                                onClick={() => setOpen(true)}
                                            // disabled={fetching || isSubmitting}
                                            >
                                                {translate("common.edit")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </WithRbac>
                        </Grid>
                    </IndicatorContainer>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}