
import { path } from 'ramda';

export const withTheme = (theme) => ({
  dropdownIndicator: (provided) => ({
    ...provided,
    color: theme.palette.grey[400],
    padding: theme.spacing.unit / 2,
    cursor: 'pointer',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: theme.palette.grey[400],
    padding: theme.spacing.unit / 2,
    cursor: 'pointer',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '0px',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '0px',
  }),
  option: (provided, state) => ({
    ...provided,
    boxShadow: `0 1px 0 0 ${theme.palette.secondary[200]}`,
    padding: theme.spacing.unit,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: state.isDisabled ? theme.palette.grey[400] : theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    backgroundColor: state.isSelected ? theme.palette.primary.light : 'rgba(0, 0, 0, 0)',
    color: state.isSelected ? theme.palette.primary.contrastText : '#555',
    fontFamily: path(['typography', 'fontFamily'], theme),
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#eee',
    fontWeight: 'bold',
    height: 25,
    fontFamily: path(['typography', 'fontFamily'], theme),
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: '#aaa',
    cursor: 'pointer',
    fontSize: 8,
  }),
  input: (provided) => ({
    ...provided,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: path(['typography', 'fontFamily'], theme),
    color: path(['palette', 'grey', '500'], theme),
  }),
  container: (provided) => ({
    ...provided,
    marginTop: 16,
  }),
  control: (provided, state) => {
    return {
      ...provided,
      border: 'unset',
      fontFamily: theme.typography.fontFamily,
      borderRadius: 'unset',
      boxShadow: 'none',
      backgroundColor: 'unset',
      fontSize: '1.12rem',
      minHeight: '1rem !important',
      marginTop: '0px',
      '& > div:first-child > div:last-child': {
        padding: 0,
      },
      '&:hover': {
        '&:before': {
          content: "''",
          borderBottomWidth: 2,
          borderBottomColor: `${theme.palette.grey[700]} !important`,
        },
      },
      '&:before': {
        left: 0,
        right: 0,
        bottom: 0,
        content: "''",
        position: 'absolute',
        transition:
          'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderBottom: '1px solid #949494',
        borderBottomColor: state.isFocused
          ? theme.palette.primary.main
          : '#949494',
        pointerEvents: 'none',
      },
    };
  },
  indicatorSeparator: (provided) => ({ display: 'none' }),
  indicatorContainer: (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
})