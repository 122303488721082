import React from 'react';
import { Grid, Typography, Avatar } from '@material-ui/core';
import { Date } from 'shared/components/date';

const CommentContainer = ({ comment }) => {
  if (!comment) {
    return null;
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container alignItems='center' spacing={1}>
          <Grid item>
            <Avatar color='primary'>
              {comment.createdBy.firstName[0]}
            </Avatar>
          </Grid>
          <Grid item>
            <Typography
              color='textSecondary'
              variant='subtitle2'
            >
              {comment.createdBy.firstName}
              {' '}
              {comment.createdBy.lastName}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body1'>{comment.description}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          color='textSecondary'
          variant='caption'
        >
          <Date date={comment.createdAt} format='YYYY-MM-DD HH:mm:ss' />
        </Typography>
      </Grid>
    </Grid>
  )
}

export default CommentContainer;