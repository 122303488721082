import React, { useState, useEffect } from "react";
import { Close } from "@material-ui/icons";
import {
  Input,
  Dialog,
  Grid,
  ListItem,
  ListItemText,
  List,
  IconButton,
  makeStyles,
  Typography,
  Checkbox,
  ListItemSecondaryAction,
  DialogActions,
  Button,
  ListItemIcon,
} from "@material-ui/core";

import { useTranslate } from "shared/components/translate";
import { isEmpty } from "ramda";

const useDialogStyle = makeStyles(() => ({
  container: {
    justifyContent: "flex-end",
  },
}));

const isSelected = (props, multiData, dt) => {
  if (!props.isMulti) {
    return props.getOptionValue(props.value) == props.getOptionValue(dt);
  }

  return (
    multiData?.findIndex(
      (el) => props.getOptionValue(el) == props.getOptionValue(dt)
    ) >= 0
  );
};

const emptyArray = [];

const getData = (value, isMulti) => {
  if (!value || isEmpty(value)) {
    if (isMulti) {
      return emptyArray;
    }

    return;
  }

  return value;
};

const DialogSelect = ({ options, open, setOpen, ...props }) => {
  const translate = useTranslate();
  const styles = useDialogStyle();
  const [multiData, setMultiData] = useState(
    getData(props.value, props.isMulti)
  );
  const [searchText, setSearchText] = useState("");

  return (
    <Dialog
      classes={{ container: styles.container }}
      open={open}
      onClose={() => setOpen((open) => !open)}
      style={{ justifyContent: "flex-end" }}
      PaperProps={{
        style: {
          margin: 0,
          borderRadius: 0,
          width: 240,
          padding: 16,
          height: "100vh",
          maxHeight: "unset",
        },
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item>
              <IconButton onClick={() => setOpen(false)}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">{props.label}</Typography>
          <Input
            fullWidth
            placeholder={translate("common.enter")}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Grid>
        {props.isMulti && (
          <Grid item xs={12}>
            <Button size="small" onClick={() => setMultiData([])}>
              {translate("common.deselectAll")}
            </Button>
            <Button size="small" onClick={() => setMultiData(options)}>
              {translate("common.selectAll")}
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <List style={{ marginBottom: 52 }}>
            {(options || [])
              .filter((dt) => props.getOptionLabel(dt).indexOf(searchText) >= 0)
              .map((dt) => (
                <ListItem
                  selected={isSelected(props, multiData, dt)}
                  button
                  key={props.getOptionValue(dt)}
                  onClick={() => {
                    if (!props.isMulti) {
                      setOpen(false);
                      props.onChange(dt);
                    } else {
                      setMultiData((data) => {
                        const checked = isSelected(props, multiData, dt);
                        const innerData = [...data];
                        if (!checked) {
                          innerData.push(dt);
                        } else {
                          const index = innerData.findIndex(
                            (el) =>
                              props.getOptionValue(el) ==
                              props.getOptionValue(dt)
                          );
                          innerData.splice(index, 1);
                        }

                        return innerData;
                      });
                    }
                  }}
                >
                  {!!props.isMulti && (
                    <ListItemIcon>
                      <Checkbox checked={isSelected(props, multiData, dt)} />
                    </ListItemIcon>
                  )}
                  <ListItemText primary={props.getOptionLabel(dt)} />
                </ListItem>
              ))}
          </List>
        </Grid>
      </Grid>
      <DialogActions
        style={{
          position: "fixed",
          bottom: 0,
          backgroundColor: "white",
          width: 220,
        }}
      >
        <Button onClick={() => setOpen(false)}>
          {translate("common.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            props.onChange(multiData);
            setOpen(false);
          }}
        >
          {translate("common.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogSelect;
