import React from 'react';
import { useSelector } from 'react-redux';
import { Table, TableRow, TableHead, TableCell, TableBody, Button, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { path } from 'ramda';

import { Date } from '../../../shared/components/date';
import { withTranslate } from '../../../shared/components/translate';
import { getUserList } from '../../../selectors/user.selector';
import { WithRbac } from 'common/rbac';
import { ROLES } from 'common/roles';
import UserStatus from './userStatus';

const UserTable = ({ translate }) => {
  const users = useSelector(getUserList);


  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell>{translate('users.table.createAt')}</TableCell>
          <TableCell>{translate('users.table.firstName')}</TableCell>
          <TableCell>{translate('users.table.lastName')}</TableCell>
          <TableCell>{translate('users.table.email')}</TableCell>
          <TableCell>{translate('users.table.department')}</TableCell>
          <TableCell>{translate('users.table.status')}</TableCell>
          <WithRbac action={ROLES["USER-UPDATE"]}>
            <TableCell>{translate('users.table.actions')}</TableCell>
          </WithRbac>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          users.map(user => (
            <TableRow key={user.id}>
              <TableCell>
                <Date format={'YY-MM-DD'} date={user.createdAt} />
              </TableCell>
              <TableCell>{user.firstName}</TableCell>
              <TableCell>{user.lastName}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{path(['department', 'name'], user)}</TableCell>
              <TableCell><UserStatus user={user} /></TableCell>
              <WithRbac action={ROLES["USER-UPDATE"]}>
                <TableCell>
                  <Link component={RouterLink} to={`/users/edit/${user.id}`}>
                    <Button size='small' color='primary'>{translate("users.table.edit")}</Button>
                  </Link>
                </TableCell>
              </WithRbac>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  )
}

export default withTranslate(UserTable);