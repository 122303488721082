import { combineReducers } from 'redux';

import tableReducer from './table';
import goalReducer from './goal';
import { actions as notificationActions } from 'shared/components/notifications';
import departmentReducer from './departmentsList';
import deleteReducer from './delete';
import createNamedWrapperReducer from '../common/utils';
import dialogReducer from '../common/dialog';
import { DELETE_GOAL_DIALOG } from './goal/delete';

export function createDepartment(values) {
  return (dispatch, _, { api }) => {
    return api.post('/private/department/create', values)
      .catch((error) => {
        dispatch(notificationActions.displayMessage('error', 'Nepavyko sukurti skyriaus'));

        throw error;
      })
  }
}

export function getDepartment(id) {
  return (dispatch, _, { api }) => {
    return api.get(`/private/department/id/${id}`)
      .then(response => {
        if (!response.data) {
          return dispatch(notificationActions.displayMessage('error', 'Neturite teisiu tvarkyti sio skyriaus'));
        }

        return response;
      })
      .catch((error) => {
        dispatch(notificationActions.displayMessage('error', 'Nepavyko gauti skyriaus duomenu'));

        throw error;
      });
  }
}

export function editDepartment(id, values) {
  return (dispatch, _, { api }) => {
    return api.put(`/private/department/id/${id}`, values)
      .then(response => {
        dispatch(notificationActions.displayMessage('error', 'Sekmingai redaguota grupė'));

        return response;
      })
      .catch((error) => {
        dispatch(notificationActions.displayMessage('error', 'Nepavyko redaguoti skyriaus'));

        throw error;
      });
  }
}

export function deleteDepartment(id) {
  return (dispatch, _, { api }) => {
    return api.delete(`/private/department/id/${id}`)
      .then(response => {
        dispatch(notificationActions.displayMessage('error', 'Sekmingai panaikinta grupė'));

        return response;
      })
      .catch((error) => {
        dispatch(notificationActions.displayMessage('error', 'Nepavyko panaikinti skyriaus'));

        throw error;
      });
  }
}

export const getManagedDepartments = (dispatch, _, { api }) => {
  return api.get(`/private/department/list-managed-departments`)
    .catch((error) => {
      dispatch(notificationActions.displayMessage('error', 'Nepavyko gauti grupių sąrašo'));

      throw error;
    });
}

export default combineReducers({
  table: tableReducer,
  delete: deleteReducer,
  departmentList: departmentReducer,
  goal: combineReducers({
    goal: goalReducer,
    delete: createNamedWrapperReducer(dialogReducer, DELETE_GOAL_DIALOG),
  }),
});