import React from 'react';
import { Grid, Typography } from '@material-ui/core';

export const IndicatorDescriptionItem = ({ title, children }) => {
  return (
    <Grid container justify='space-between' wrap='nowrap'>
      <Grid item xs={12} md={4}>
        <Typography variant='subtitle2' style={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        {children}
      </Grid>
    </Grid>
  )
}