import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { withTranslate } from 'shared/components/translate';
import DepartmentGoals, { departmentGoalValidationSchema } from '../forms/departmentGoal';
import { getEditGoalValue, isOpenEditDialog } from 'selectors/department.select';
import { getDepartmentGoal, editDepartmentGoal, closeEditDialog, setFetchedDepartmentGoals } from 'reducers/ducks/department/goal';

const CreateDepartmentGoalDialog = ({ translate, ...props }) => {
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = React.useState({});
  const open = useSelector(isOpenEditDialog);
  const handleCloseRecord = () => dispatch(closeEditDialog);
  const values = useSelector(getEditGoalValue);

  React.useEffect(() => {
    if (!!values) {
      dispatch(getDepartmentGoal(values.id))
        .then(response => {
          setInitialValues(response.data);
        })
    }
  }, [dispatch, values])

  return (
    <Dialog fullWidth open={open} onClose={handleCloseRecord}>
      <DialogTitle>Redaguoti skyriaus tikslą</DialogTitle>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={departmentGoalValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          dispatch(editDepartmentGoal(values))
            .then(() => dispatch(setFetchedDepartmentGoals))
            .then(() => handleCloseRecord())
            .finally(() => setSubmitting(false));
        }}
        render={formProps => (
          <Form>
            <DialogContent>
              <DepartmentGoals />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseRecord}>{translate("common.cancel")}</Button>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                disabled={!formProps.isValid || formProps.isSubmitting}
              >
                {translate("common.edit")}
              </Button>
            </DialogActions>
          </Form>
        )}
      />
    </Dialog>
  )
}

export default withTranslate(CreateDepartmentGoalDialog);