import React from 'react';

export const IndicatorContainer = ({ children }) => {
  return (
    <div style={{
      padding: 16,
      width: '100%',
      backgroundColor: '#fff',
      boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.05)',
    }}>
      {children}
    </div>
  )
}