import { isOpenFilter } from "selectors/problem.selector";

const SET_OPEN = 'MYKPI/PROBLEMS_FILTER/SET_OPEN';

const defaultState = {
  open: false,
}

export default function problemFilterStateReducer(state = defaultState, { type, payload } = {}) {
  switch (type) {
    case SET_OPEN:
      return { ...state, open: payload }
    default:
      return state;
  }
}

export const setOpen = payload => ({
  type: SET_OPEN,
  payload,
})

export const toggleOpen = (dispatch, getState) => {
  const open = isOpenFilter(getState());

  dispatch({
    type: SET_OPEN,
    payload: !open,
  });
}