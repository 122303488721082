import React from "react";
import { Grid, Divider } from "@material-ui/core";

import { SelectTemplateIndicator } from "shared/components/select-indicatorTemplates";
import { withTranslate } from "shared/components/translate";
import BaseForm from "./base";
import { Field } from "formik";
import CustomCheckbox from "shared/components/checkbox";
import { WithRbac } from "common/rbac";
import { ROLES } from "common/roles";
export { validationSchema } from "./base";

const IndicatorForm = ({ translate, setInitial }) => {
  return (
    <Grid container spacing={1}>
      {/* <WithRbac action={ROLES.TEMPLATES.READ}> */}
      <Grid item xs={12}>
        <SelectTemplateIndicator
          name="template"
          placeholder={translate("common.select")}
          onChange={({ templateName, id, ...template }) => {
            setInitial({
              ...template,
              assignTo: [],
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {/* </WithRbac> */}
      <Grid item xs={12}>
        <BaseForm multiAssignees={true} />
      </Grid>
      <WithRbac action={ROLES["INDICATOR-TEMPLATE-CREATE"]}>
        <Grid item xs={12}>
          <Field
            component={CustomCheckbox}
            name="saveAsTemplate"
            label={translate("indicators.form.saveAsTemplate")}
          />
        </Grid>
      </WithRbac>
    </Grid>
  );
};

export default withTranslate(IndicatorForm);
