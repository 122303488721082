import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { withTranslate } from 'shared/components/translate';
import DepartmentGoals, { departmentGoalValidationSchema } from '../forms/departmentGoal';
import { isOpenCreateDialog } from 'selectors/department.select';
import { closeCreateDialog, createDepartmentGoal, setFetchedDepartmentGoals } from 'reducers/ducks/department/goal';
import { openStrategyRow } from 'reducers/ducks/strategy/table/strategy';
import { openDepartmentRow } from 'reducers/ducks/strategy/table/department';
import { actions } from 'shared/components/notifications';

const CreateDepartmentGoalDialog = ({ translate }) => {
  const dispatch = useDispatch();
  const open = useSelector(isOpenCreateDialog);
  const handleCloseRecord = () => dispatch(closeCreateDialog);

  return (
    <Dialog fullWidth open={open} onClose={handleCloseRecord}>
      <DialogTitle>Pridėti skyriaus tikslą</DialogTitle>
      <Formik
        validationSchema={departmentGoalValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          dispatch(createDepartmentGoal(values))
            .then(() => dispatch(setFetchedDepartmentGoals))
            .then(() => handleCloseRecord())
            .then(() => dispatch(actions.displayMessage('success', 'Sėkmingai sukurtas skyriaus tikslas')))
            .then(() => {
              dispatch(openStrategyRow(values.strategicGoals.id));
              dispatch(openDepartmentRow(`${values.strategicGoals.id}/${values.department.id}`));
            })
            .finally(() => setSubmitting(false));
        }}
        render={formProps => (
          <Form>
            <DialogContent>
              <DepartmentGoals />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseRecord}>{translate("common.cancel")}</Button>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                disabled={!formProps.isValid || formProps.isSubmitting}
              >
                {translate("common.add")}
              </Button>
            </DialogActions>
          </Form>
        )}
      />
    </Dialog>
  )
}

export default withTranslate(CreateDepartmentGoalDialog);