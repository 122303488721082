import React from 'react';
import { Formik, Form } from 'formik';
import { Dialog, DialogActions, DialogTitle, Button, DialogContent } from '@material-ui/core';

import IndicatorForm from 'components/indicators/form/group';
import { DataFetcher } from 'shared/components/dataFetcher';
import { useDispatch } from 'react-redux';
import { fetchIndicator } from 'reducers/ducks/indicator/fetchIndicator';
import { useTranslate } from 'shared/components/translate';

const EditGroupIndicator = ({ open, handleClose, id, handleSubmit, ...props }) => {
  const dispatch = useDispatch();
  const translate = useTranslate();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DataFetcher
        id={id}
        fetchData={() => dispatch(fetchIndicator(id)).then(response => response.data)}
      >
        {({ data, fetching }) => (
          <Formik
            enableReinitialize
            initialValues={data}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <DialogTitle>
                  {translate("indicators.views.group.edit")}
                </DialogTitle>
                <DialogContent>
                  <IndicatorForm />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>
                    {translate("common.cancel")}
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    disabled={fetching || isSubmitting}
                  >
                    {translate("common.edit")}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        )}
      </DataFetcher>
    </Dialog>
  )
}

export default EditGroupIndicator;