import { assoc } from 'ramda';

const defaultState = {
  list: [],
  myList: [],
  filter: {},
};

export const reducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'acknowledgment/set_filter':
      return assoc('filter', action.payload, state);
    case 'acknowledgment/list_success':
      return assoc('list', action.payload, state);
    case 'acknowledgment/fetch_my_given_acknowledgment_success':
      return assoc('myList', action.payload, state);
    default:
      return state;
  }
}