import { path as ramdaPath } from "ramda";
import { createRequestMessageHandler } from "shared/utils/httpRequestMessageHandler";

const SET_FILTER = "MYKPI/TABLE/SET_FILTER";
const SET_TABLE_STATE = "MYKPI/TABLE/SET_TABLE_STATE";
const SET_PAGINATION = "MYKPI/TABLE/SET_PAGINATION";
const SET_DATA = "MYKPI/TABLE/SET_DATA";
const SET_LOADING = "MYKPI/TABLE/SET_LOADING";

const defaultSate = {
  tableState: {
    size: 25,
  },
  pagination: {
    count: 0,
    page: 1,
    rowsPerPage: 25,
  },
  filter: {},
  data: [],
  loading: false,
};

export const createSelectors = (path) => {
  return {
    getData: ramdaPath([...path, "data"]),
    getFilter: ramdaPath([...path, "filter"]),
    getPagination: ramdaPath([...path, "pagination"]),
    getTableState: ramdaPath([...path, "tableState"]),
    isLoading: ramdaPath([...path, "loading"]),
  };
};
const countTotalPages = (rowsPerPage, totalRecords) => {
  if (rowsPerPage && totalRecords) {
    return Math.ceil(totalRecords / rowsPerPage);
  }
  return 0;
};
export default function tableReducer(
  state = defaultSate,
  { type, payload } = {}
) {
  switch (type) {
    case SET_FILTER:
      return { ...state, filter: payload };
    case SET_TABLE_STATE:
      return { ...state, tableState: payload };
    case SET_PAGINATION:
      return {
        ...state,
        pagination: {
          totalRecords: payload?.count,
          count: countTotalPages(25, payload?.count),
          page: payload?.page + 1,
          rowsPerPage: 25,
        },
      };
    case SET_DATA:
      return { ...state, data: payload };
    case SET_LOADING:
      return { ...state, loading: payload };
    default:
      return state;
  }
}

export const createSetData = (name) => (payload) => ({
  name,
  payload,
  type: SET_DATA,
});

export const createSetFilter = (name) => (payload) => ({
  name,
  payload,
  type: SET_FILTER,
});

export const createSetPagination = (name) => (payload) => ({
  name,
  payload,
  type: SET_PAGINATION,
});

export const createSetTableState = (name) => (payload) => ({
  name,
  payload,
  type: SET_TABLE_STATE,
});

export const createSetLoading = (name) => (payload) => ({
  name,
  payload,
  type: SET_LOADING,
});

export const createFetchTableData = ({ name, urlFormatter, path }) => () => (
  dispatch,
  getState,
  { api }
) => {
  dispatch(createSetLoading(name)(true));

  const messageHandler = createRequestMessageHandler();
  const filter = createSelectors(path).getFilter(getState());
  const tableState = createSelectors(path).getTableState(getState());

  return api
    .get(urlFormatter({ filter, tableState }))
    .then((response) => {
      dispatch(createSetData(name)(response?.data?.data));
      dispatch(createSetPagination(name)(response?.data?.pagination));
    })
    .catch((error) => {
      dispatch(messageHandler(error));

      throw error;
    })
    .finally(() => {
      dispatch(createSetLoading(name)(false));
    });
};
