import React, { useRef, useState } from "react";
import {
  FormControl,
  useTheme,
  InputLabel,
  FormHelperText,
  Grid,
  Menu,
  Input,
  MenuItem,
} from "@material-ui/core";
import { path, split } from "ramda";

import { withTheme } from "./selectFieldStyles";
import { withTranslate, getActiveLanguage } from "./translate";
import { useMatchMobile } from "shared/hooks/responsive";
import { useSelector } from "react-redux";

const getPath = split(".");

const CustomMenu = React.forwardRef((props, ref) => {
  console.log(ref, props);


  return <Menu open={props.selectProps.menuIsOpen} anchorEl={ref.current}>
    <MenuItem>a</MenuItem>
  </Menu>
  // return <Menu onClose={props.selectProps.onMenuClose} open={props.selectProps.menuIsOpen} anchorEl={ref}>{props.children}</Menu>
})

export const createField = (Component) =>
  withTranslate(
    ({
      field,
      form: { touched, errors, setFieldValue, setTouched },
      translate,
      helperText,
      ...rest
    }) => {
      const customStyles = withTheme(useTheme());
      const pt = getPath(field.name);
      const ref = useRef();
      const formRef = useRef();
      const isMobile = useMatchMobile();
      const fieldTouched = path(pt, touched);
      const fieldErrors = path(pt, errors);
      const locale = useSelector(getActiveLanguage);

      return (
        <FormControl fullWidth>
          <Grid container>
            <Grid item xs={12}>
              <InputLabel
                ref={ref}
                required={rest.required}
                shrink={true}
                htmlFor={field.name}
                style={{ display: "block" }}
                error={!!fieldTouched && !!fieldErrors}
              >
                {rest.label}
              </InputLabel>
            </Grid>
            <Grid item xs={12} ref={formRef}>
              <Component
                placeholder={translate("common.select")}
                {...field}
                onFocus={() => {
                  if (isMobile) {
                    ref.current.scrollIntoView(true);
                  }
                }}
                {...rest}
                styles={customStyles}
                onBlur={(e) =>
                  setTouched({
                    ...touched,
                    [field.name]: true,
                  })
                }
                onChange={(val) => setFieldValue(field.name, val)}
              />
            </Grid>
            {((fieldTouched && fieldErrors) || helperText) && (
              <Grid item xs={12}>
                <FormHelperText
                  style={{ display: "block" }}
                  error={!!fieldTouched && !!fieldErrors}
                >
                  {fieldErrors ? translate(fieldErrors) : helperText}
                </FormHelperText>
              </Grid>
            )}
          </Grid>
        </FormControl>
      );
    }
  );

export default createField;
