import React from 'react';
import { options } from 'shared/components/select-status';
const Status = ({ status = {} }) => {

  return (
    <div style={{
      height: 16,
      width: 16,
      borderRadius: 2,
      backgroundColor: status.colorCode
    }} />
  )
}

export default Status;