import React from 'react';
import { Grid, Paper, TableHead, TableCell, Table, TableRow } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import PageHeader from '../../../shared/components/pageHeader';
import { withTranslate } from '../../../shared/components/translate';
import { fetchMyGivenAcknowledgment } from '../../../services/acknowledgment';
import MyGivenBody from './body';

const MyGivenContainer = ({ translate }) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchMyGivenAcknowledgment);
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <PageHeader title={translate("acknowledgment.myGivenAcknowledgment")} />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translate("acknowledgment.table.created")}</TableCell>
                <TableCell>{translate("acknowledgment.table.name")}</TableCell>
                <TableCell>{translate("acknowledgment.table.department")}</TableCell>
                <TableCell>{translate("acknowledgment.table.actions")}</TableCell>
              </TableRow>
            </TableHead>
            <MyGivenBody />
          </Table>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default withTranslate(MyGivenContainer);
