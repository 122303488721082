import React from 'react';
import { useDispatch } from 'react-redux';
import { path } from 'ramda';

import { Select } from 'shared/components/select';
import { createGetCachedOrFetchCompanyStrategy } from 'reducers/ducks/strategy';
import { listCompanyStrategicGoals } from 'selectors/strategy.selector';
import { createField } from './selectField';
import { useTranslate } from './translate';

const SelectCompanyStrategy = (props) => {
  const dispatch = useDispatch();
  const [options, setOptions] = React.useState([]);
  const translate = useTranslate();

  React.useEffect(() => {
    dispatch(createGetCachedOrFetchCompanyStrategy(listCompanyStrategicGoals))
      .then(setOptions);
  }, [dispatch])

  return (
    <Select
      {...props}
      getOptionValue={path(["id"])}
      getOptionLabel={path(["description"])}
      noOptionsMessage={() => translate("common.noRecords")}
      menuPlacement='bottom'
      menuPosition='fixed'
      options={options}
    />
  );
}

export default createField(SelectCompanyStrategy)