import axios from "axios";
import { path, pathEq } from "ramda";
import EventDispatcher from "./EventDispatcher";

const createResponseInterceptors = {
  fetchingNewToken: false,
  apiCalls: {},
  eventEmmiter: new EventDispatcher(),
  success(response) {
    return response;
  },
  error(error) {
    const api = this;

    if (pathEq(["response", "status"], 401, error)) {
      const refreshToken = localStorage.getItem("refresh_token");
      const eventEmmiter = createResponseInterceptors.eventEmmiter;

      const prom = new Promise((resolve, reject) => {
        createResponseInterceptors.apiCalls[error.config.url] =
          error.config.url;

        eventEmmiter.on(`${error.config.url}-success`, (token) => {
          const originalRequest = {
            ...error.config,
            retry: true,
            // url: formatUrl(error.config.url, error.config.baseURL),
          };
          originalRequest.headers["Authorization"] = `Bearer ${token}`;
          api.defaults.headers["Authorization"] = `Bearer ${token}`;
          resolve(api(originalRequest));
          eventEmmiter.off(`${error.config.url}-success`);
          eventEmmiter.off(`${error.config.url}-failure`);
        });
        eventEmmiter.on(`${error.config.url}-failure`, (innerError) => {
          eventEmmiter.off(`${error.config.url}-success`);
          eventEmmiter.off(`${error.config.url}-failure`);

          reject(error);
        });
      });

      if (!createResponseInterceptors.fetchingNewToken) {
        createResponseInterceptors.fetchingNewToken = true;
        api
          .post("/authenticate/token", {
            grant_type: "refresh_token",
            client_id: "mykpi-app",
            client_secret:
              "jfojlkfno1n4lk1n4lk123n4kjn2fkjrj13n42kj12n4kjn2314k",
            refresh_token: refreshToken,
          })
          .then((response) => {
            const token = path(["data", "access_token"], response);
            const refreshToken = path(["data", "refresh_token"], response);

            localStorage.setItem("token", token);
            localStorage.setItem("refresh_token", refreshToken);

            api.defaults.headers.Authorization = `Bearer ${token}`;
            const clonedApiCalls = {
              ...createResponseInterceptors.apiCalls,
            };

            Object.values(clonedApiCalls).map((url) => {
              delete createResponseInterceptors.apiCalls[url];
              eventEmmiter.trigger(
                `${url}-success`,
                response.data.access_token
              );
            });
            createResponseInterceptors.fetchingNewToken = false;
          })
          .catch(() => {
            window.location.replace("/auth/login");
            localStorage.removeItem("token");
            localStorage.removeItem("refresh_token");

            createResponseInterceptors.fetchingNewToken = false;
            const clonedApiCalls = {
              ...createResponseInterceptors.apiCalls,
            };

            Object.values(clonedApiCalls).map((url) => {
              eventEmmiter.trigger(`${url}-failure`, error);
              delete createResponseInterceptors.apiCalls[url];
            });
          });
      }

      return prom;
    }

    throw error;
  },
};

export default function () {
  const token = localStorage.getItem("token");
  const instance = axios.create({ baseURL: "/api" });
  const responseInterceptors = createResponseInterceptors;

  instance.interceptors.response.use(
    responseInterceptors.success,
    responseInterceptors.error.bind(instance)
  );

  if (token) {
    instance.defaults.headers.Authorization = `Bearer ${localStorage.getItem(
      "token"
    )}`;
  }

  return instance;
}
