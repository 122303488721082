import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import Navigation from "../../landing/navigation";
import Footer from "components/landingPage/footer";
import ContactUs from "components/landingPage/contactUs";

const InstructorLayout = ({ children }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <Grid
      container
      justify="center"
      style={{ backgroundColor: "#F4F7F8", minHeight: "100vh" }}
    >
      <Grid
        item
        xs={12}
        style={{
          background: "linear-gradient(45deg, #02052F, #313CDC)",
          maxHeight: 66,
          height: 66,
        }}
      >
        <Grid container justify="center" alignItems="center">
          <Grid item xs={11} lg={9}>
            <Navigation />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ padding: "92px 0" }}>
        <Grid container justify="center">
          <Grid item xs={10} lg={9}>
            {children}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ padding: "92px 0" }}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <ContactUs />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default InstructorLayout;
