import React from 'react';
import { Grid } from '@material-ui/core';

import Navigation from './navigation';
import LandingPhoto from './landingPhoto';

const LandingPage = () => {
  return (
    <div style={{
      height: '100vh',
      minHeight: 830,
      width: '100%',
      background: "linear-gradient(45deg, #02052F, #313CDC)",
      padding: 0
    }}>
      <Grid container justify='center' className="h-100">
        <Grid item xs={11} lg={9} className="h-100">
          <Navigation />
          <div style={{ height: "calc(100% - 57px)" }}>
            <LandingPhoto />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default LandingPage;