import { createRequestMessageHandler } from "shared/utils/httpRequestMessageHandler"

export const editIndicator = (type, values) => (dispatch, __, { api }) => {
  return api.put(
    `/private/indicator/type/${type}/id/${values.id}`,
    values
  ).catch(error => {
    dispatch(createRequestMessageHandler()(error))
  })
}
