import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Field } from "formik";

import InputField from "../../../shared/components/input";
import { withTranslate } from "../../../shared/components/translate";
import SelectDepartment from "../../../shared/components/select-department";
import FieldCheckbox from "../../../shared/components/checkbox";

const BasicInfo = ({ translate }) => {
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          {translate("users.form.basic.title")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Field name="emailConfirmed">
          {({ field }) => {
            return (
              <Field
                fullWidth
                required
                label={translate("users.form.basic.email")}
                component={InputField}
                disabled={!!field.value}
                type="email"
                name="email"
              />
            );
          }}
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          required
          label={translate("users.form.basic.firstName")}
          component={InputField}
          name="firstName"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          required
          label={translate("users.form.basic.lastName")}
          component={InputField}
          name="lastName"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          name="department"
          label={translate("users.form.basic.department")}
          component={SelectDepartment}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          name="active"
          label={translate("users.form.basic.enabled")}
          component={FieldCheckbox}
        />
      </Grid>
    </Grid>
  );
};

export default withTranslate(BasicInfo);
