import React, { useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import { useTranslate } from "shared/components/translate";
import PriceText from "./priceText";
import PriceSlider from "./slider";

const priceTags = {
  1: {
    value: 1,
    label: "1-5",
    monthPrice: "30",
  },
  2: {
    value: 2,
    label: "6-10",
    monthPrice: "40",
  },
  3: {
    value: 3,
    label: "11-20",
    monthPrice: "60",
  },
  4: {
    value: 4,
    label: "21-50",
    monthPrice: "100",
  },
  5: {
    value: 5,
    label: "51-100",
    monthPrice: "140",
  },
  6: {
    value: 6,
    label: "100+",
    monthPrice: "200",
  },
};

const Price = () => {
  const translate = useTranslate();
  const [sliderValue, setSliderValue] = useState(2);

  return (
    <Grid container justify="center">
      <Grid item xs={11} lg={9}>
        <Grid container justify="center">
          <Grid item xs={12} style={{ padding: "0 0 92px 0" }}>
            <Typography variant="h4" color="textPrimary">
              {translate("landingPage.price.title")}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={8} style={{ padding: "0 0 0 0" }}>
            <Grid container justify="center">
              <Grid item xs={12} style={{ padding: "0 0 46px 0" }}>
                <PriceText priceTags={priceTags} sliderValue={sliderValue} />
              </Grid>
              <Grid item xs={12} style={{ padding: "0 0 46px 0" }}>
                <PriceSlider
                  setSliderValue={setSliderValue}
                  sliderValue={sliderValue}
                  priceTags={priceTags}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="center">
                  <Grid item>
                    <Button component={Link} to="/auth/create">
                      {translate("landingPage.price.tryFree")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Price;
