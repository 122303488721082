import { path as ramdaPath } from 'ramda';
import { createSelector } from 'reselect';

const SET_PAGE = 'MYKPI/PAGINATION/SET_PAGE';
const SET_TOTAL = 'MYKPI/PAGINATION/SET_TOTAL';
const SET_SIZE = 'MYKPI/PAGINATION/SET_SIZE';

const defaultSate = {
  page: 0,
  total: 10,
  size: 10,
};

export const selectors = (path) => {
  const paginationSelectors = {
    getPage: ramdaPath([...path, 'page']),
    getTotal: ramdaPath([...path, 'total']),
    getSize: ramdaPath([...path, 'size']),
    getAll: state => createSelector(
      paginationSelectors.getPage,
      paginationSelectors.getTotal,
      paginationSelectors.getSize,
      (page, total, size) => ({
        page,
        total,
        size
      })
    )(state)
  }


  return paginationSelectors
}

export default function paginationReducer(state = defaultSate, { type, payload } = {}) {
  switch (type) {
    case SET_PAGE:
      return { ...state, page: payload }
    case SET_TOTAL:
      return { ...state, total: payload }
    case SET_SIZE:
      return { ...state, size: payload }
    default:
      return state
  }
}

export const createSetPage = name => payload => ({
  name,
  payload,
  type: SET_PAGE,
});

export const createSetTotal = name => payload => ({
  name,
  payload,
  type: SET_TOTAL,
});

export const createSetSize = name => payload => ({
  name,
  payload,
  type: SET_SIZE,
});

export const createSetPaginationFromResponse = name => responsePagination => dispatch => {
  dispatch(createSetPage(name)(responsePagination.page))
  dispatch(createSetTotal(name)(responsePagination.total))
  dispatch(createSetSize(name)(responsePagination.size))
};
