import React, { useState } from "react";
import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { Link } from "react-router-dom";

import { useTranslate } from "shared/components/translate";
import LanguageSelector from "shared/components/languageSelector";
import { useMatchMobile } from "shared/hooks/responsive";

const Navigation = () => {
  const translate = useTranslate();
  const isMobile = useMatchMobile();
  const [open, setOpen] = useState(false);

  return (
    <div className="navigation" style={{ color: "white", paddingTop: 16 }}>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button
                fullWidth
                component={Link}
                to="/auth/create"
                variant="contained"
                color="primary"
              >
                {translate("landingPage.register")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                component={Link}
                to="/auth/login"
                variant="contained"
                color="secondary"
                disableElevation
              >
                {translate("landingPage.login")}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={2}>
          <Typography variant="h5" color="inherit">
            <Link
              style={{ textDecoration: "unset", color: "inherit" }}
              to="/home"
            >
              myKPI.eu
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={1} wrap="nowrap" justify="flex-end">
            {isMobile ? (
              <Grid item>
                <Button color="inherit" onClick={() => setOpen((val) => !val)}>
                  <Menu />
                </Button>
              </Grid>
            ) : (
                <>
                  <Grid item>
                    <Button
                      component="a"
                      href="#contactUs"
                      variant="text"
                      color="inherit"
                    >
                      {translate("landingPage.contactUsButton")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      component={Link}
                      to="/auth/create"
                      variant="text"
                      color="inherit"
                    >
                      {translate("landingPage.register")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      component={Link}
                      to="/auth/login"
                      variant="contained"
                      color="secondary"
                      disableElevation
                    >
                      {translate("landingPage.login")}
                    </Button>
                  </Grid>
                </>
              )}
            <Grid item>
              <LanguageSelector />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Navigation;
