import React from 'react';
import { Field } from 'formik';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';

import InputField from 'shared/components/input';
import { withTranslate } from 'shared/components/translate';

export const missionValidationSchema = Yup.object().shape({
  mission: Yup.string().required(),
});

const Mission = ({ translate }) => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Field
        required
        fullWidth
        name='mission'
        multiline
        rows={2}
        label='Misija'
        component={InputField}
      />
    </Grid>
  </Grid>
)

export default withTranslate(Mission)