import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import PageHeader from 'shared/components/pageHeader';
import { WithRbac } from 'common/rbac';
import { ROLES } from 'common/roles';
import { useTranslate } from 'shared/components/translate';

const ProblemHeader = ({ handleCreate, toggleFilter }) => {
  const translate = useTranslate();

  return (
    <PageHeader title={translate("problems.title")}>
      <div style={{ padding: 8 }}>
        <Grid container justify='flex-end' spacing={1}>
          <Grid item>
            <Button
              variant='outlined'
              color='primary'
              onClick={toggleFilter}
            >
              {translate("common.filter")}
            </Button>
          </Grid>
          <WithRbac action={ROLES["PROBLEMS-CREATE"]}>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                onClick={handleCreate}
              >
                <Add />
                {translate("common.add")}
              </Button>
            </Grid>
          </WithRbac>

        </Grid>
      </div>
    </PageHeader>
  )
}

export default ProblemHeader;