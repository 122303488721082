import React from 'react';
import { groupBy } from 'ramda';
import { TableRow, TableCell, Grid } from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';

import { listGoalsAssignToStrategicGoal, getDepartment } from 'selectors/department.select';
import { departmentTableSelectors } from 'selectors/strategy.selector';
import { toggleOpenDepartmentRow } from 'reducers/ducks/strategy/table/department';
import DepartmentGoalRow from './departmentGoalRow';
import styles from './index.module.css';

const groupByDepartment = groupBy(goal => goal.department);

const StrategyRow = ({ strategy }) => {
  const departmentGoals = useSelector(listGoalsAssignToStrategicGoal(strategy.id));
  const groupedGoals = groupByDepartment(departmentGoals);

  if (!departmentGoals || (departmentGoals && !departmentGoals.length)) {
    return null;
  }

  return (
    <React.Fragment>
      {
        Object.keys(groupedGoals)
          .map(key => (
            <DepartmentGroup
              key={key}
              goalKey={key}
              strategyId={strategy.id}
              groupedGoals={groupedGoals}
            />
          ))
      }
    </React.Fragment>
  )
}

const DepartmentGroup = ({ goalKey, groupedGoals, strategyId }) => {
  const dispatch = useDispatch();
  const open = useSelector(departmentTableSelectors.isOpen(`${strategyId}/${goalKey}`));
  const setOpen = () => dispatch(toggleOpenDepartmentRow(`${strategyId}/${goalKey}`, open))

  return (
    <React.Fragment >
      <DepartmentName
        open={open}
        onClick={setOpen}
        goalKey={goalKey}
      />
      {
        open && groupedGoals[goalKey].map(goal => (<DepartmentGoalRow key={goal.id} goal={goal} />))
      }
    </React.Fragment>
  )
}

const DepartmentName = ({ onClick, open, goalKey }) => {
  const department = useSelector(getDepartment(goalKey))

  return (
    <TableRow className={styles.childRow} onClick={onClick}>
      <TableCell colSpan={9}>
        <Grid container spacing={1}>
          <Grid item style={{ width: 16 }} />
          <Grid item>
            {
              open ? <ArrowUpward className={styles.icon} /> : <ArrowDownward className={styles.icon} />
            }
          </Grid>
          <Grid item>
            {department.name}
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
}

export default StrategyRow;