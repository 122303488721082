import { pathOr, path } from 'ramda';
import { ROLES } from '../common/roles';
import { createSelector } from 'reselect';

export const getUserList = pathOr([], ['user', 'list']);
export const getUserComposedRoles = pathOr([], ['user', 'current', 'combinedRoles']);
export const getUserId = path(['user', 'current', 'id']);
export const getCurrentUser = pathOr({}, ['user', 'current']);
export const getUserDepartments = pathOr('', ['user', 'current', 'department']);
export const getUserManagedDepartments = pathOr('', ['user', 'current', 'departmentManager']);

export const getUserComposedDepartments = createSelector(
  getUserDepartments,
  getUserManagedDepartments,
  (userDepartment, managedDepartments) => {
    return [...managedDepartments, userDepartment]
  }
)