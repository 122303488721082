import React from 'react';
import { Grid } from '@material-ui/core';

import AssignTo from '../form/assignTo';
import CreatedBy from '../form/createdBy';

const CreateAcknowledgment = () => {
  const ref = React.createRef();
  return (
    <Grid container ref={ref}>
      <Grid item xs={12}>
        <AssignTo />
      </Grid>
      <Grid item xs={12}>
        <CreatedBy />
      </Grid>
    </Grid>
  )
}

export default CreateAcknowledgment;