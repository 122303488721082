import React from 'react';
import { Grid, Divider } from '@material-ui/core';

import { RecordDateRange } from '../common/recordDateRange';

export const RecordsContainer = ({ children }) => {

  return (
    <div style={{ padding: 16 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RecordDateRange />
        </Grid>
        <Grid item xs={12}>
          <Divider variant='fullWidth' />
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </div>
  )
}