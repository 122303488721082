import React from 'react';
import moment from 'moment';


export const RecordVariantWrapper = ({
  children,
  cases,
  plannedValue,
  value,
  week,
  comment
}) => {
  if (value == null && !!comment) {
    return children({ variant: "SKIP" })
  }

  if (cases === 'lte' && value != null) {
    if (value <= plannedValue) {
      return children({ variant: 'GOOD' });
    }

    return children({ variant: 'BAD' });
  }


  if (cases === 'gte' && value != null) {
    if (value >= plannedValue) {
      return children({ variant: 'GOOD' });
    }

    return children({ variant: 'BAD' });
  }

  if (!!week && moment(week).week() === moment().week() && moment(week).year() === moment().year()) {
    return children({ variant: 'CURRENT' });
  }

  return children({ variant: 'EMPTY' })
}