import React from 'react';
import { Field } from 'formik';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';

import TextField from 'shared/components/input';
import SelectUser from 'shared/components/select-user';
import SelectIndicator from 'shared/components/select-indicator';
import { withTranslate } from 'shared/components/translate';
import selectUnit from 'shared/components/select-unit';
import selectIndicatorPeriod from 'shared/components/select-indicatorPeriod';
import selectIndicatorCase from 'shared/components/select-IndicatorCase';
import NumberField from 'shared/components/numberInput';

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  plannedValue: Yup
    .number()
    .required(),
  period: Yup.object().required(),
  unit: Yup.object().required(),
  case: Yup.object().required(),
  parentIndicator: Yup.object().required(),
  assignTo: Yup.array().min(1).required(),
});

const IndicatorForm = ({ translate, multiAssignees = false }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Field
          fullWidth
          name='name'
          label={translate("indicators.form.name")}
          component={TextField}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          name='unit'
          label={translate("indicators.form.unit")}
          component={selectUnit}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          name='plannedValue'
          label={translate("indicators.form.plannedValue")}
          component={NumberField}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          name='period'
          label={translate("indicators.form.period")}
          component={selectIndicatorPeriod}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          name='case'
          label={translate("indicators.form.case")}
          component={selectIndicatorCase}
        />
      </Grid>
      {/* <WithRbac action={ROLES.USER["user:find"]}> */}
      <Grid item xs={12}>
        <Field
          isClearable
          isMulti={multiAssignees}
          name='assignTo'
          label={translate("indicators.form.assignTo")}
          component={SelectUser}
        />
      </Grid>
      {/* </WithRbac> */}
      {/* <WithRbac action={ROLES.DEPARTMENT_GOAL["departmentGoal:find"]}> */}
      <Grid item xs={12}>
        <Field
          name='parentIndicator'
          type='department'
          label={translate("indicators.form.departmentIndicator")}
          component={SelectIndicator}
        />
      </Grid>
      {/* </WithRbac> */}
    </Grid>
  )
}

export default withTranslate(IndicatorForm);