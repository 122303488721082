import moment from 'moment';

const SET_FROM_DATE = 'MYKPI/INDICATOR_RECORD/SET_FROM_DATE';
const SET_TILL_DATE = 'MYKPI/INDICATOR_RECORD/SET_TILL_DATE';
const SET_RANGE = 'MYKPI/INDICATOR_RECORD/SET_RANGE';


const defaultState = {
  from: moment().subtract(3, 'week').utc().startOf('week').toJSON(),
  till: moment().add(2, 'week').utc().endOf('week').toJSON(),
}

export default (state = defaultState, { type, payload } = {}) => {
  switch (type) {
    case SET_FROM_DATE:
      return { ...state, from: payload };
    case SET_TILL_DATE:
      return { ...state, till: payload };
    case SET_RANGE:
      return payload;
    default:
      return state;
  }
}

export const setFromDate = date => ({
  type: SET_FROM_DATE,
  payload: date
});

export const setTillDate = date => ({
  type: SET_TILL_DATE,
  payload: date
});

export const setRangeDate = (from, till) => ({
  type: SET_RANGE,
  payload: {
    from,
    till
  }
});
