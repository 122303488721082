import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  ListSubheader,
  Paper,
  Grid,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { groupBy, mapObjIndexed, filter, compose, path, pathOr } from "ramda";

import { fetchGroupUser } from "reducers/ducks/dashboard";
import { useTranslate } from "shared/components/translate";

const Employee = ({ selected, setSelected, search }) => {
  const [users, setUsers] = React.useState([]);
  const dispatch = useDispatch();
  const translate = useTranslate();

  React.useEffect(() => {
    dispatch(fetchGroupUser).then((response) => setUsers(response.data));
  }, [dispatch]);

  const handleToggle = (user) => {
    if (selected[user.id]) {
      try {
        delete selected[user.id]

      } catch (error) {

      }
      return setSelected({
        ...selected,
      });
    }

    setSelected({
      ...selected,
      [user.id]: user,
    });
  };

  return (
    <List dense component={Grid} container>
      {compose(
        Object.values,
        mapObjIndexed((value) => {
          return (
            <Grid item xs={12} style={{ paddingBottom: 8 }}>
              <Paper elevation={0}>
                <ListSubheader style={{ zIndex: 0 }}>
                  {pathOr(translate("common.unassigned"), [0, "department", "name"], value)}
                </ListSubheader>
                {value.map((user) => {
                  return (
                    <ListItem
                      key={user.id}
                      role={undefined}
                      dense
                      button
                      onClick={() => handleToggle(user)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={!!selected[user.id]}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": user.id }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={user.id}
                        primary={`${user.firstName} ${user.lastName}`}
                      />
                    </ListItem>
                  );
                })}
              </Paper>
            </Grid>
          );
        }),
        groupBy(path(["department", "id"])),
        filter(
          (user) =>
            user &&
            user.firstName &&
            user.firstName.toLowerCase().indexOf(search.toLowerCase()) >= 0
        )
      )(users)}
    </List>
  );
};

export default Employee;
