import React from "react";
import Chart from "chart.js";
import { path, compose } from "ramda";
import { withTheme } from "@material-ui/core";

import { withTranslate } from "../../../shared/components/translate";

const ChartComponent = ({ data, theme, translate }) => {
  const [chart, setChart] = React.useState();
  const ref = React.createRef();
  const primary = theme.palette.primary;

  React.useEffect(() => {
    setChart(
      new Chart(ref.current, {
        type: "horizontalBar",
        data: {
          labels: [],
          datasets: [
            {
              label: translate("acknowledgment.charts.title"),
              backgroundColor: primary.light,
              borderColor: primary.main,
              data: [],
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                maxBarThickness: 12,
              },
            ],
            xAxes: [
              {
                ticks: {
                  stepSize: 1,
                  beginAtZero: true,
                },
              },
            ],
          },
          legend: {
            display: false,
          },
        },
      })
    );
  }, [setChart, primary, translate,]);

  React.useEffect(() => {
    if (chart && data) {
      chart.data.labels = [];
      chart.data.datasets[0].data = [];
      chart.data.datasets[0].label = translate("acknowledgment.charts.title");
      data.forEach((ack) => {
        chart.data.labels.push(
          `${path(["user", "firstName"], ack)} ${path(
            ["user", "lastName"],
            ack
          )}`
        );
        chart.data.datasets[0].data.push(path(["total"], ack));
      });

      chart.update();
    }
  }, [data, translate]);

  return (
    <div
      style={{
        width: "100%",
        height: data?.length * 25,
        minHeight: 300,
        maxHeight: "60vh",
        padding: 16,
      }}
    >
      <canvas ref={ref} />
    </div>
  );
};

export default compose(withTranslate, withTheme)(ChartComponent);
