import React from "react";
import { TextField, Grid, Button } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { setSearch } from "reducers/ducks/indicator/filterFields";
import { getSearchField } from "selectors/indicator.selector";
import { useTranslate } from "shared/components/translate";

const SearchContainer = ({ handleSearch }) => {
  const dispatch = useDispatch();
  const searchField = useSelector(getSearchField);
  const translate = useTranslate();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSearch(e);
      }}
    >
      <div style={{ padding: "0 8px 0 0" }}>
        <Grid
          container
          wrap="nowrap"
          justify="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item style={{ flex: 1 }}>
            <TextField
              margin="dense"
              variant="outlined"
              value={searchField}
              onChange={(e) => dispatch(setSearch(e.target.value))}
              fullWidth
              placeholder={translate("common.enter")}
              label={translate("indicators.filters.description")}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              type="submit"
            >
              <Search />
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};

export default SearchContainer;
