import React, { useMemo } from "react";
import { path } from "ramda";

import { Select } from "shared/components/select";
import { createField } from "../selectField";
import { useDispatch } from "react-redux";
import { fetchRoles } from "reducers/ducks/role";
import { useTranslate } from "../translate";
import { useFetchRoles } from "./hooks";

export const SelectRole = ({ optionFilter, ...props }) => {
  const { loading, options } = useFetchRoles({ optionFilter });
  const translate = useTranslate();

  return (
    <Select
      {...props}
      isLoading={loading}
      options={options}
      getOptionValue={path(["id"])}
      getOptionLabel={(opt) => translate(`enums.roles.${opt.name}`)}
      noOptionsMessage={() => translate("common.noRecords")}
      menuPlacement="bottom"
      menuPosition="fixed"
    />
  );
};

export default createField(SelectRole);
