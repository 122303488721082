import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { path } from 'ramda';

import CreateForm from './form';
import { createAcknowledgment, fetchAcknowledgment } from '../../../services/acknowledgment';
import { withTranslate } from '../../../shared/components/translate';
import { actions } from '../../../shared/components/notifications';
import { getAcknowledgmentsMappedFilter } from 'selectors/acknowledgment.selector';

const schemaValidation = Yup.object().shape({
  assignTo: Yup.string().required(),
})

const CreateDialog = (props) => {
  const dispatch = useDispatch();
  const filter = useSelector(getAcknowledgmentsMappedFilter);

  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      open={props.open}
      onClose={props.handleClose}
    >
      <Formik
        validationSchema={schemaValidation}
        onSubmit={(val, { setSubmitting, setErrors }) => {
          setSubmitting(true);
          dispatch(createAcknowledgment(val))
            .then(() => dispatch(fetchAcknowledgment(filter)))
            .then(props.handleClose)
            .then(() => dispatch(actions.displayMessage('success', props.translate("notifications.success.save"))))
            .catch(err => {
              const errors = path(['response', 'data', 'errors'], err);
              dispatch(actions.displayMessage('success', props.translate("notifications.errors.save")));
              if (errors) setErrors(errors);
            })
            .finally(() => setSubmitting(false));
        }}
        render={formProps => (
          <form onSubmit={formProps.handleSubmit} >
            <DialogTitle>{props.translate("acknowledgment.dialog.title")}</DialogTitle>
            <DialogContent>
              <CreateForm />
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose}>{props.translate("common.cancel")}</Button>
              <Button
                type='submit'
                color='primary'
                variant='contained'
                disabled={!formProps.isValid || formProps.isSubmitting}
              >{props.translate("acknowledgment.dialog.create")}</Button>
            </DialogActions>
          </form>
        )}
      />

    </Dialog>
  )
}

export default withTranslate(CreateDialog);