import React from "react";
import { Paper, Grid, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { path, compose } from "ramda";
import { withRouter } from "react-router-dom";

import PageHeader from "shared/components/pageHeader";
import { withTranslate } from "shared/components/translate";
import { createDepartment } from "reducers/ducks/department";
import Form from "../form";
import validationSchema from "../form/validateSchema";

const CreateDepartment = ({ translate, history }) => {
  const dispatch = useDispatch();

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader title={translate("departments.create.title")} />
      </Grid>
      <Grid item xs={12}>
        <div style={{ padding: 16 }}>
          <Paper style={{ padding: 16 }}>
            <Formik
              validationSchema={validationSchema}
              onSubmit={(value, { setSubmitting, setErrors }) => {
                setSubmitting(true);
                dispatch(createDepartment(value))
                  .then(() => {
                    history.push("/department");
                  })
                  .catch((error = {}) => {
                    const errors = path(["response", "data", "errors"], error);
                    setErrors(errors);
                  })
                  .finally(() => setSubmitting(false));
              }}
              render={(props) => (
                <Form {...props}>
                  <Grid container spacing={1}>
                    <Grid item onClick={() => history.push("/department")}>
                      <Button>{translate("common.cancel")}</Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={props.isSubmitting || !props.isValid}
                      >
                        {translate("common.create")}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            />
          </Paper>
        </div>
      </Grid>
    </Grid>
  );
};

export default compose(withRouter, withTranslate)(CreateDepartment);
