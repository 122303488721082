import React from "react";
import { Route, Switch } from "react-router-dom";

import LandingPage from "components/landingPage";
import BlogPostLayout from "components/landingPage/blog/posts/layout";
import Post1 from "components/landingPage/blog/posts/post1";
import Post2 from "components/landingPage/blog/posts/post2";
import Post3 from "components/landingPage/blog/posts/post3";
import PrivateElegations from "components/landingPage/footer/privateElegations";

import InstructorLayout from "components/landingPage/cv/instructors/layout";
import RokasCv from "components/landingPage/cv/instructors/rokas";
import RimtautasCV from "components/landingPage/cv/instructors/rimtautas";
import RytisCV from "components/landingPage/cv/instructors/rytis";
import EndedSupscription from "components/landingPage/endedSubscription";
import ErlandasCv from "components/landingPage/cv/instructors/erlandas";

export default (props) => {
  return (
    <Switch>
      <Route path={`${props.match.url}/post`}>
        <BlogPostLayout>
          <Route path={`${props.match.url}/post/1`} component={Post1} />
          <Route path={`${props.match.url}/post/2`} component={Post2} />
          <Route path={`${props.match.url}/post/3`} component={Post3} />
        </BlogPostLayout>
      </Route>
      <Route path={`${props.match.url}/privacy-policy`}>
        <BlogPostLayout>
          <PrivateElegations />
        </BlogPostLayout>
      </Route>
      <Route path={`${props.match.url}/instructor`}>
        <InstructorLayout>
          <Route path={`${props.match.url}/instructor/1`} component={RokasCv} />
          <Route
            path={`${props.match.url}/instructor/2`}
            component={RimtautasCV}
          />
          <Route
            path={`${props.match.url}/instructor/3`}
            component={RytisCV}
          />
          <Route
            path={`${props.match.url}/instructor/4`}
            component={ErlandasCv}
          />
        </InstructorLayout>
      </Route>
      <Route path={`${props.match.url}/ended-subscription`}>
        <InstructorLayout>
          <EndedSupscription />
        </InstructorLayout>
      </Route>
      <Route path={`${props.match.url}`} component={LandingPage} />
    </Switch>
  );
};
