import React from 'react';
import { Grid, Typography, Card } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyDashboard } from 'reducers/ducks/dashboard';

import ChartWrapper from '../../chartWrapper';

import CountProblem from './countProblems';
import Network, { colors } from './network';
import { pathOr } from 'ramda';
import { getDasboardFilter } from 'selectors/dashboard';
import { useTranslate } from 'shared/components/translate';
import NetworkLegend from './networkLegend';

const Charts = ({ user }) => {
  const [data, setData] = React.useState({});
  const dispatch = useDispatch();
  const filters = useSelector(getDasboardFilter)
  const translate = useTranslate();

  React.useEffect(() => {
    dispatch(getCompanyDashboard())
      .then(response => {
        setData(response.data)
      });
  }, [dispatch, filters]);


  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="primary">{translate('dashboards.companyCharts.title')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <ChartWrapper label={translate("dashboards.companyCharts.vision.title")} height='40vh' minHeight='400px'>
              <CountProblem vision={pathOr([], ['visionToStrategic', 'vision'], data)} strategic={pathOr({}, ['visionToStrategic', 'strategic'], data)} />
            </ChartWrapper>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={0}>
              <div style={{ padding: 16 }}>
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography variant="subtitle1" color="textPrimary">
                      {translate("dashboards.companyCharts.network.title")}
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Grid container spacing={2} justify="flex-end">
                      <Grid item>
                        <NetworkLegend colors={colors.blue} description="> 70%" />
                      </Grid>
                      <Grid item>
                        <NetworkLegend colors={colors.green} description="70% - 50%" />
                      </Grid>
                      <Grid item>
                        <NetworkLegend colors={colors.yellow} description="30% - 50%" />
                      </Grid>
                      <Grid item>
                        <NetworkLegend colors={colors.red} description="< 30%" />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <div style={{ width: '100%', minHeight: 400, height: '40vh' }}>
                <Network data={data.indicatorStructure} />
              </div>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid >
  )
}

export default Charts;