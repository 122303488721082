import { actions as notificationActions } from 'shared/components/notifications';
import { getTranslate } from 'shared/components/translate';

export const contactUs = data => (dispatch, getState, { api }) => {
  const translate = getTranslate(getState());

  return api.post('/unregister/email', data)
    .then(() => {
      dispatch(notificationActions.displayMessage(
        'success',
        translate('notifications.success.email')
      ));

    })
    .catch(error => {
      dispatch(notificationActions.displayMessage(
        'error',
        translate('notifications.errors.email')
      ));

      throw error;
    })
}