import React from 'react';
import { Grid } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { IndicatorLoader } from 'components/indicators/common/indicatorLoader';
import { IndicatorRecordsContainer } from 'components/indicators/recordContainer';
import { ActionContainer } from './actions';
import { IndicatorView } from './indicatorView';
import { RecordsContainer } from '../recordsContainer';
import { DeleteAlert } from '../deleteAlert';
import EditGroupIndicator from './edit';
import { fetchIndicator } from 'reducers/ducks/indicator/fetchIndicator';
import { editIndicator } from 'reducers/ducks/indicator/editIndicator';
import { fetchIndicatorIds } from 'selectors/indicator.selector';
import { setFetchIndicatorId } from 'reducers/ducks/indicator';

export const VisionIndicator = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const fetchId = useSelector(fetchIndicatorIds)

  return (
    <React.Fragment>
      <DeleteAlert
        handleClose={() => setOpen(false)}
        handleDelete={() => Promise.resolve()}
        open={open}
      />
      <EditGroupIndicator
        id={match.params.id}
        handleClose={() => setOpenEdit(false)}
        handleSubmit={values => dispatch(editIndicator('vision', values))
          .then(() => dispatch(setFetchIndicatorId()))
          .then(() => setOpenEdit(false))
        }
        open={openEdit}
      />
      <IndicatorLoader
        fetchData={() => dispatch(fetchIndicator(match.params.id))}
        id={`${fetchId}${match.params.id}`}
        >
        {
          ({ data }) => (
            <Grid container>
              <Grid item xs={12}>
                <IndicatorView data={data}>
                  <ActionContainer
                    onDelete={() => setOpen(true)}
                    onEdit={() => setOpenEdit(true)}
                  />
                </IndicatorView>
              </Grid>
              <Grid item xs={12}>
                <RecordsContainer>
                  <IndicatorRecordsContainer period={data.period} cases={data.case} plannedValue={data.plannedValue} />
                </RecordsContainer>
              </Grid>
            </Grid>
          )
        }
      </IndicatorLoader>
    </React.Fragment>
  )
}