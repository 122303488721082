import React, { useMemo } from "react";
import { groupBy, path, prop } from "ramda";

import { createField } from "../selectField";
import { useTranslate } from "../translate";
import { useFetchRoles } from "./hooks";
import GroupCheckbox from "shared/components/checkboxGroup/groupCheckbox";
import CheckboxGroup from "shared/components/checkboxGroup/index";
import { Grid, Typography } from "@material-ui/core";
import LoaderContainer from "../loaderContainer";

export const RoleCheckboxes = ({ optionFilter, ...props }) => {
  const { loading, options } = useFetchRoles({ optionFilter });
  const translate = useTranslate();
  const group = groupBy(prop("group"), options);

  return (
    <CheckboxGroup {...props} valueField={path(["id"])}>
      <LoaderContainer fetching={loading}>
        <Grid container spacing={2} style={{ paddingTop: 8 }}>
          {Object.keys(group).map((key) => {
            return (
              <Grid item xs={12}>
                <Grid container key={key}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      {translate(`enums.roleGroupNames.${key}`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      {group[key].map((role) => (
                        <Grid item xs={12} sm={6} md={3} key={role.id}>
                          <GroupCheckbox
                            value={role}
                            label={translate(`enums.roles.${role.name}`)}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </LoaderContainer>
    </CheckboxGroup>
  );
};

export default RoleCheckboxes;
