import { pathOr, path, filter } from 'ramda';
import { createSelector } from 'reselect';

import { selectors as dialogSelector } from 'reducers/ducks/common/dialog';

const emptyObj = {};

export const getTableData = pathOr([], ['department', 'table', 'data']);
export const isFetchingTableData = path(['department', 'table', 'isFetching']);

export const isOpenCreateDialog = path(['department', 'goal', 'goal', 'openCreateDialog']);
export const isOpenEditDialog = path(['department', 'goal', 'goal', 'openEditDialog']);
export const getEditGoalValue = path(['department', 'goal', 'goal', 'editGoalValue']);
export const getGoals = pathOr({}, ['department', 'goal', 'goal', 'goals']);
export const listGoals = createSelector(getGoals, Object.values);
export const listGoalsAssignToStrategicGoal = id => createSelector(listGoals, filter(goal => {
  try {
    return goal.strategicGoals === id
  } catch (error) {
    return false;
  }
}));

export const getOpenedRecordValue = path(['department', 'goal', 'goal', 'recordDialogValues']);
export const isDepartmentGoalRecordDialogOpen = path(['department', 'goal', 'goal', 'recordDialog']);
export const getDepartment = id => pathOr({}, ['department', 'departmentList', 'departments', id]);
export const getDepartmentListObjectArray = pathOr(emptyObj, ['department', 'departmentList', 'departments']);
export const getDepartmentList = createSelector(getDepartmentListObjectArray, Object.values)

//Delete duck
export const isOpenDeleteDialog = path(['department', 'delete', 'open']);
export const getActiveDepartmentForDelete = pathOr(emptyObj, ['department', 'delete', 'department']);

export const deleteDepartmentGoalDialogSelectors = dialogSelector(['department', 'goal', 'delete']);