import React from "react";
import { Grid, makeStyles, Typography, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import AnimatedAvatar from "./animatedAvatar";
import ContactBox from "./contactBox";
import { Email, LinkedIn, Phone } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  avatarFullContainer: {
    height: 285 + 170 / 2,
    marginTop: 170 / 2 + 10,
    padding: "0 8px 0 8px",
  },
  avatarContainer: {
    position: "relative",
    height: 285,
  },
  avatarSpacing: {
    height: 170 / 2,
  },
}));

const CvCard = ({ photo, name, link, phone, email, linkedin }) => {
  const classes = useStyles();

  return (
    <div className={`cv-card-container ${classes.avatarFullContainer}`}>
      <Paper className={classes.avatarContainer}>
        <Grid container spacing={2} className={classes.avatarContainer}>
          <Grid item xs={12} className={classes.avatarSpacing}>
            <AnimatedAvatar photo={photo} />
          </Grid>
          <Grid item xs={12} component={Link} to={link} style={{ textDecoration: "unset"}}>
            <Typography
              variant="h5"
              color="textPrimary"
              style={{
                fontWeight: "bold",
                textDecoration: "unset",
                width: "100%",
              }}
              align="center"
            >
              {name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={1}>
              {!!phone && (
                <Grid item>
                  <a href={`tel:${phone}`}>
                    <ContactBox icon={Phone} />
                  </a>
                </Grid>
              )}
              {!!email && (
                <Grid item>
                  <a href={`mailto:${email}`}>
                    <ContactBox icon={Email} />
                  </a>
                </Grid>
              )}
              {!!linkedin && (
                <Grid item>
                  <a href={linkedin} target="_blank">
                    <ContactBox icon={LinkedIn} />
                  </a>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default CvCard;
