import React from "react";
import { Grid, Button, Collapse } from "@material-ui/core";

import PageHeader from "../../shared/components/pageHeader";
import { withTranslate } from "../../shared/components/translate";
import { ROLES } from "../../common/roles";
import ChartContainer from "./chart";
import CreateDialog from "./create";
import { WithRbac } from "../../common/rbac";
import FilterContainer from "./filterContainer";

const AcknowledgmentContainer = ({ translate }) => {
  const [open, setOpen] = React.useState(false);
  const [openFilter, setOpenFilter] = React.useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const toggleFilter = () => setOpenFilter(open => !open);

  return (
    <Grid container>
      <CreateDialog open={open} handleClose={handleClose} />
      <Grid item xs={12}>
        <PageHeader title={translate("acknowledgment.title")}>
          <Grid item>
            <Button
              variant='outlined'
              color='primary'
              onClick={toggleFilter}
            >
              {translate("common.filter")}
            </Button>
          </Grid>
          <WithRbac action={ROLES["ACKNOWLEDGMENT-CREATE"]}>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleOpen}>
                {translate("acknowledgment.create")}
              </Button>
            </Grid>
          </WithRbac>
        </PageHeader>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={openFilter} unmountOnExit={false}>
          <div style={{ padding: 16, borderBottom: "1px solid #dfdfdf" }}>
            <FilterContainer />
          </div>
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <ChartContainer />
      </Grid>
    </Grid>
  );
};

export default withTranslate(AcknowledgmentContainer);
