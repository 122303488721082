import React from 'react';

import Employee from "./employee";
import Department from "./department";
import Company from "./company/view/employee";
import Strategy from "./strategy";

const SwitchDashboards = ({ type }) => {
  switch (type) {
    case "employee":
      return <Employee />;
    case "department":
      return <Department />;
    case "strategy":
      return <Strategy />;
    case "company":
      return <Company />;
    default:
      return <Employee />;
  }
};

export default SwitchDashboards;