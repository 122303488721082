import React from 'react';
import { Chip } from '@material-ui/core';
import { useTranslate } from 'shared/components/translate';

const UserStatus = ({ user }) => {
  const translate = useTranslate();

  if (!user) {
    return null
  }

  if (!!user.emailConfirmed && !!user.active) {
    return <Chip size="small" variant="contained" color="primary" label={translate('users.table.active')} />
  }

  if (!user.active) {
    return <Chip size="small" variant="contained" label={translate('users.table.inactive')} />
  }

  if (!user.emailConfirmed) {
    return <Chip size="small" variant="contained" color="secondary" label={translate('users.table.notAccepted')} />
  }

  return null
}

export default UserStatus