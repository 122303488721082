import { assoc, compose } from 'ramda';

const TABLE_NAME = "DEPARTMENT_TABLE";
const FETCH_DATA_REQUEST = `${TABLE_NAME}/FETCH_DATA_REQUEST`;
const FETCH_DATA_SUCCESS = `${TABLE_NAME}/FETCH_DATA_SUCCESS`;
const FETCH_DATA_FAILURE = `${TABLE_NAME}/FETCH_DATA_FAILURE`;


const defaultTableState = {
  fetching: false,
  data: [],
}

const reducer = (state = defaultTableState, { type, payload } = {}) => {
  switch (type) {
    case FETCH_DATA_REQUEST:
      return compose(
        assoc('fetching', true),
        assoc('data', []),
      )(state);
    case FETCH_DATA_SUCCESS:
      return compose(
        assoc('fetching', false),
        assoc('data', payload),
      )(state);
    case FETCH_DATA_FAILURE:
      return compose(
        assoc('fetching', false),
        assoc('data', []),
      )(state);
    default:
      return state;
  }
}

export default reducer;

export const requestData = { type: FETCH_DATA_REQUEST };
export const fetchDataFailure = { type: FETCH_DATA_FAILURE };
export function setData(data) {
  return {
    type: FETCH_DATA_SUCCESS,
    payload: data
  }
}

export function fetchData(dispatch, _, { api }) {
  dispatch(requestData);

  return api.get('/private/department/list')
    .then(response => dispatch(setData(response.data)))
    .catch(() => {
      dispatch(fetchDataFailure);
    });
}