import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import { useTranslate } from "shared/components/translate";
import phone from "assets/img/monitorPhone.png";

const LandingPhoto = () => {
  const translate = useTranslate();

  return (
    <div className="landing-photo h-100" style={{ color: "white" }}>
      <Grid container alignItems="center" className="h-100">
        <Grid item xs={12} className="h-100">
          <Grid container alignItems="center" className="h-100" spacing={1}>
            <Grid item xs={6} lg={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    variant="h2"
                    color="inherit"
                    style={{ fontWeight: "bold" }}
                  >
                    {translate("landingPage.mainTitle")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button
                        fullWidth
                        component={Link}
                        to="/auth/login"
                        variant="contained"
                        color="secondary"
                        disableElevation
                      >
                        {translate("landingPage.login")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        fullWidth
                        component={Link}
                        to="/auth/create"
                        color="inherit"
                      >
                        {translate("landingPage.register")}
                      </Button>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} lg={8}>
              <Grid container justify="flex-start" style={{ height: "100%", width: "100%" }}>
                <Grid item xs={12} style={{ height: "100%", width: "100%" }}>
                  <img src={phone} style={{ width: "100%" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default LandingPhoto;
