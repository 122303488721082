import React from 'react';
import { Grid, Button, Typography } from '@material-ui/core';

import InputField from 'shared/components/input';
import { Field } from 'formik';
import { useTranslate } from 'shared/components/translate';

const EnterCommentContainer = () => {
  const translate = useTranslate();

  return (
    <div style={{ padding: 16, minHeight: 300 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            color='primary'
            variant='subtitle1'
          >
            {translate("problems.comments.comment")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Field
            name='message'
            multiline={true}
            fullWidth
            variant='outlined'
            placeholder={translate("problems.comments.enterComment")}
            component={InputField}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                type='submit'
              >
                {translate("problems.comments.comment")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default EnterCommentContainer