import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, DialogActions, Typography, Grid, ListItem, List } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { withTranslate } from 'shared/components/translate';
import { closeDialogAndClearData } from 'reducers/ducks/strategy/deleteGoal';
import { deleteStrategyGoalDialogSelectors } from 'selectors/strategy.selector';
import { getDependantIndicators, deleteIndicatorWithDependantIndicators } from 'reducers/ducks/strategy/operators';
import { setFetchedCompanyStrategy } from 'reducers/ducks/strategy/index';

const DeleteStrategicGoalDialog = ({ translate, ...props }) => {
  const [dependant, setDependant] = React.useState({});
  const dispatch = useDispatch();
  const open = useSelector(deleteStrategyGoalDialogSelectors.isOpen);
  const data = useSelector(deleteStrategyGoalDialogSelectors.getData);

  const handleCloseRecord = () => dispatch(closeDialogAndClearData())

  React.useEffect(() => {
    if (data) {
      dispatch(getDependantIndicators(data.id))
        .then(response => setDependant(response.data))
    }
  }, [data])

  return (
    <Dialog fullWidth open={open} onClose={handleCloseRecord}>
      <DialogTitle>Ištrinti strateginį tikslą</DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1' color='error'>Ištrynus strateginį tikslą bus panaikinti</Typography>
        <Grid container>
          {
            (!!dependant.departmentGoals && dependant.departmentGoals.length) && (
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Skyriaus tikslai
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <List>
                      {
                        dependant.departmentGoals.map(goal => (
                          <ListItem key={goal.id}>
                            {goal.description}
                          </ListItem>
                        ))
                      }
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            )
          }
          {
            (dependant.employeeGoals && !!dependant.employeeGoals.length) && (
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Darbuotojų tikslai
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <List>
                      {
                        dependant.employeeGoals.map(goal => (
                          <ListItem key={goal.id}>
                            {goal.name}
                          </ListItem>
                        ))
                      }
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseRecord}>Atšaukti</Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            dispatch(deleteIndicatorWithDependantIndicators(dependant.id))
              .then(() => dispatch(setFetchedCompanyStrategy))
              .then(() => dispatch(handleCloseRecord))
          }}
        >
          Ištrinti
          </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withTranslate(DeleteStrategicGoalDialog);