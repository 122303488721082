import React from "react";
import { Formik, Form, Field } from "formik";
import { Grid, Button, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { KeyboardDatePicker } from "@material-ui/pickers";

import {
  setSearch,
  setOtherFields,
} from "reducers/ducks/indicator/filterFields";
import SelectStatus from "shared/components/select-status";
import SelectUser from "shared/components/select-user";
import { createField } from "shared/components/datePicker";
import selectDepartment from "shared/components/select-department";
import {
  getSearchField,
  getAllSearchFields,
} from "selectors/indicator.selector";
import { fetchIndicatorFilter } from "reducers/ducks/indicator";
import selectIndicator from "shared/components/select-indicator";
import { useTranslate } from "shared/components/translate";

const DateField = createField(KeyboardDatePicker);

const FilterContainer = () => {
  const dispatch = useDispatch();
  const searchField = useSelector(getSearchField);
  const { search, ...fields } = useSelector(getAllSearchFields);
  const translate = useTranslate();

  return (
    <Formik
      initialValues={fields}
      onSubmit={(values) => {
        dispatch(setOtherFields(values));

        return dispatch(fetchIndicatorFilter());
      }}
    >
      {() => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={3}>
                  <TextField
                    placeholder={translate("common.enter")}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => dispatch(setSearch(e.target.value))}
                    value={searchField}
                    label={translate("indicators.filters.description")}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field
                    fullWidth
                    isMulti
                    name="assignedTo"
                    component={SelectUser}
                    label={translate("indicators.filters.employee")}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field
                    fullWidth
                    isMulti
                    name="department"
                    component={selectDepartment}
                    label={translate("indicators.filters.department")}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field
                    fullWidth
                    isMulti
                    name="departmentGoal"
                    type="department"
                    component={selectIndicator}
                    label={translate("indicators.filters.departmentIndicator")}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field
                    fullWidth
                    isMulti
                    name="strategic"
                    type="strategic"
                    component={selectIndicator}
                    label={translate("indicators.filters.strategyIndicators")}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field
                    fullWidth
                    isMulti
                    name="createdBy"
                    component={SelectUser}
                    label={translate("indicators.filters.createdBy")}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field
                    fullWidth
                    autoOk
                    name="createdAt"
                    component={DateField}
                    label={translate("indicators.filters.createdAt")}
                    format="YYYY-MM-DD"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    size="small"
                  >
                    <Search />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default FilterContainer;
