export const fetchRoleGroups = (dispatch, getState, { api }) => {
  return api.get('/private/roleGroup/list');
}

export const fetchRoleGroup = id => (dispatch, getState, { api }) => {
  return api.get(`/private/roleGroup/id/${id}`);
}

export const editRoleGroup = (id, roleGroup) => (dispatch, getState, { api }) => {
  return api.put(`/private/roleGroup/id/${id}`, roleGroup);
}

export const createRoleGroup = roleGroup => (dispatch, getState, { api }) => {
  return api.post('/private/roleGroup/create', roleGroup);
}

export const findAssignedRoleGroups = id => (dispatch, getState, { api }) => {
  return api.get(`/private/roleGroup/usersAssigned/${id}`);
}

export const deleteRoleGroup = id => (dispatch, getState, { api }) => {
  return api.delete(`/private/roleGroup/id/${id}`);
}