import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchAcknowledgment } from '../../../services/acknowledgment';
import { getAcknowledgments, getAcknowledgmentsMappedFilter } from '../../../selectors/acknowledgment.selector';
import ChartComponent from './chart';

const ChartContainer = (props) => {
  const dispatch = useDispatch();
  const data = useSelector(getAcknowledgments);
  const filter = useSelector(getAcknowledgmentsMappedFilter);

  React.useEffect(() => {
    dispatch(fetchAcknowledgment(filter));
  }, [filter]);

  return (
    <ChartComponent data={data} />
  )
}

export default ChartContainer;