import React from "react";
import { useDispatch } from "react-redux";
import { path } from "ramda";

import { AsyncSelect } from 'shared/components/select';
import { createField } from "./selectField";
import { findIndicatorTemplates } from "reducers/ducks/template";
import { Typography } from "@material-ui/core";
import { useTranslate } from "./translate";

export const SelectTemplateIndicator = (props) => {
  const dispatch = useDispatch();
  const translate = useTranslate();

  return (
    <React.Fragment>
      <Typography variant="caption" color="textSecondary">
        {translate("indicators.form.template")}
      </Typography>
      <AsyncSelect
        {...props}
        defaultOptions
        loadOptions={(val) =>
          dispatch(findIndicatorTemplates(val)).then(
            (response) => response.data
          )
        }
        getOptionValue={path(["id"])}
        getOptionLabel={path(["templateName"])}
        noOptionsMessage={() => {
          translate("common.noRecords");
        }}
        menuPlacement="bottom"
        menuPosition="fixed"
      />
    </React.Fragment>
  );
};

export default createField(SelectTemplateIndicator);
