import React from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import {
  ArrowDownward,
  ArrowUpward,
  KeyboardArrowDown,
  KeyboardArrowUp,
  KeyboardArrowUpSharp,
} from "@material-ui/icons";
import Loader from "../loader";
import { Grid, Typography } from "@material-ui/core";

const Table = ({
  loadData,
  columns,
  data,
  initialState,
  loading,
  pagination,
  selectedRowId
}) => {
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize, sortBy, ...rest },
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState,
      manualPagination: true,
      manualSortBy: true,
      pageCount: pagination.count,

      autoResetPage: false,
      autoResetSortBy: false,
    },
    useSortBy,
    usePagination
  );

  React.useEffect(() => {
    if (loadData) {
      loadData({ pageIndex, pageSize, sortBy });
    }
  }, [loadData, pageIndex, pageSize, sortBy]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <MaUTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <Grid container justify="flex-start" alignItems="center" wrap="nowrap">
                      <Grid item>
                        <Typography
                          variant="inherit"
                          color={column.isSorted ? "primary" : "inherit"}
                        >
                          {column.render("Header")}
                        </Typography>
                      </Grid>
                      <Grid item style={{ height: 24, width: 24 }}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <KeyboardArrowDown
                              fontSize="small"
                              color="primary"
                            />
                          ) : (
                            <KeyboardArrowUp fontSize="small" color="primary" />
                          )
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {loading ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={150}>
                  <Loader />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </MaUTable>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 16 }}>
        <Pagination
          variant="outlined"
          shape="rounded"
          count={pagination?.count}
          page={pageIndex + 1}
          onChange={(e, value) => gotoPage(value - 1)}
        />
      </Grid>
    </Grid>
  );
};

export default Table;
