import React from "react";
import { Route, Switch } from "react-router-dom";

import Acknowledgment from '../components/acknowledgment';
import MyGivenAcknowledgment from '../components/acknowledgment/myGiven';

export default (props) => {
  return (
    <Switch>
      <Route
        path={`${props.match.url}/my-list`}
        component={MyGivenAcknowledgment} />
      <Route
        path={props.match.url}
        component={Acknowledgment} />
    </Switch>
  )
}


