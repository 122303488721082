export const getDependantIndicators = id => (dispatch, getState, { api }) => {
  return api.get(`/private/strategicGoal/dependents/id/${id}`)
    .catch(error => {

      throw error
    })
}

export const deleteIndicatorWithDependantIndicators = id => (dispatch, getState, { api }) => {
  return api.delete(`/private/strategicGoal/dependents/id/${id}`)
    .catch(error => {

      throw error
    })
}