import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { SelectUserIndicator } from "shared/components/select-user-indicator";
import moment from "moment";
import ChartJs from "chart.js";
import { useTheme } from "@material-ui/core";
import { getEmployeeIndicatorDashboard } from "reducers/ducks/dashboard";
import { getDasboardFilter } from "selectors/dashboard";
import { pathOr } from "ramda";
import { useTranslate } from "shared/components/translate";

const UserIndicators = ({ userId }) => {
  const ref = React.useRef();
  const dispatch = useDispatch();
  const [indicator, setIndicator] = React.useState();
  const [data, setData] = React.useState();
  const [chart, setChart] = React.useState();
  const theme = useTheme();
  const filters = useSelector(getDasboardFilter);
  const translate = useTranslate();

  const handleSetIndicator = React.useCallback(
    (val) => {
      setIndicator(val);
      setData();
    },
    [setData, setIndicator]
  );

  React.useEffect(() => {
    const chart = new ChartJs(ref.current, {
      type: "line",
      options: {
        // responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                unit: "week",
                tooltipFormat: "YYYY-WW",
                displayFormats: {
                  millisecond: "YYYY-WW",
                  second: "YYYY-WW",
                  minute: "YYYY-WW",
                  hour: "YYYY-WW",
                  day: "YYYY-WW",
                  week: "YYYY-WW",
                  month: "YYYY-WW",
                  quarter: "YYYY-WW",
                  year: "YYYY-WW",
                },
              },
            },
          ],
          yAxes: [
            {
              id: "records",
              type: "linear",
              position: "left",
            },
            {
              id: "problems",
              type: "linear",
              position: "right",
              scaleLabel: {
                display: true,
                labelString: translate("dashboards.employeeCharts.employeeIndicators.registerProblems"),
              },
            },
          ],
        },
      },
    });

    setChart(chart);
  }, [setChart]);

  React.useEffect(() => {
    if (!!chart) {
      if (!!data) {
        chart.data.datasets = [
          {
            label: pathOr("", ["name"], indicator),
            backgroundColor: theme.palette.primary.main,
            fill: theme.palette.primary.main,
            yAxisID: "records",
            data: pathOr([], ["records"], data)
              .sort(
                (a, b) =>
                  moment(a.recordDate).unix() - moment(b.recordDate).unix()
              )
              .map((record) => ({
                x: record.recordDate,
                y: record.value,
              })),
          },
          {
            label: translate("dashboards.employeeCharts.employeeIndicators.registerProblems"),
            backgroundColor: theme.palette.secondary.main,
            fill: theme.palette.secondary.main,
            yAxisID: "problems",
            data: pathOr([], ["createdProblems"], data)
              .sort(
                (a, b) =>
                  moment(a.createdAt).unix() - moment(b.createdAt).unix()
              )
              .map((record) => ({
                x: moment(record.createdAt, "YYYY-WW"),
                y: record.total,
              })),
          },
        ];
      } else {
        chart.data.datasets = [];
      }

      chart.update();
    }
  }, [indicator, data, chart]);

  React.useEffect(() => {
    if (!!chart) {
      chart.options.scales.yAxes[0].scaleLabel.display = true;
      chart.options.scales.yAxes[0].scaleLabel.labelString = pathOr(
        "",
        ["unit", "description"],
        indicator
      );
      chart.update();
    }
  }, [indicator, chart]);

  React.useEffect(() => {
    if (indicator) {
      dispatch(
        getEmployeeIndicatorDashboard(
          indicator.id,
          moment().startOf("year").toJSON(),
          moment().endOf("year").toJSON()
        )
      ).then((response) => setData(response.data));
    }
  }, [indicator, filters]);

  return (
    <Card elevation={0} style={{ height: "45vh", minHeight: "400px", padding: 16 }}>
      <Grid container alignContent='stretch' direction='row' style={{ height: '100%' }}>
        <Grid item xs={12}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="subtitle1" color="textPrimary">{translate("dashboards.employeeCharts.employeeIndicators.title")}</Typography>
            </Grid>
            <Grid item md={5} xs={12}>
              <SelectUserIndicator
                isClearable
                userId={userId}
                onChange={handleSetIndicator}
                value={indicator}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <canvas ref={ref} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default UserIndicators;
