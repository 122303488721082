import React, { useEffect } from 'react'
import { Link, useRouteMatch, withRouter } from 'react-router-dom';
import { Card, CardHeader, CardContent, Button, Typography, CardActions } from '@material-ui/core';

import { withTranslate } from '../../shared/components/translate';
import { useDispatch } from 'react-redux';
import { confirmDevice } from 'reducers/ducks/user/confirmDevice';

const SuccessConfirmDevice = withRouter(({ translate }) => {
  const dispatch = useDispatch();

  const {
    params: {
      token
    }
  } = useRouteMatch();

  useEffect(() => {
    dispatch(confirmDevice(token))
  }, [dispatch, token]);

  return (
    <Card>
      <CardHeader title={translate('auth.successConfirmDevice.title')} />
      <CardContent >
        <Typography>
          {translate("auth.successConfirmDevice.body")}
        </Typography>
      </CardContent>
      <CardActions>
        <Button component={Link} to="/auth/login" variant="contained" color="secondary">{translate("login.login")}</Button>
      </CardActions>
    </Card>
  );
})

export default withTranslate(SuccessConfirmDevice);