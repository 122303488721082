import React from "react";
import ChartJs from "chart.js";
import { useTranslate } from "shared/components/translate";

const CountProblem = ({ data = [] }) => {
  const ref = React.useRef();
  const [chart, setChart] = React.useState();
  const translate = useTranslate();

  React.useEffect(() => {
    const chart = new ChartJs(ref.current, {
      type: "doughnut",
      options: {
        responsive: false,
        maintainAspectRatio: false,
        legend: false
      },
    });

    setChart(chart);
  }, []);

  React.useEffect(() => {
    if (chart) {
      chart.data = {
        labels: data.map((d) => d.name),
        datasets: [
          {
            label: translate('common.total'),
            backgroundColor: data.map((d) => d.colorCode),
            data: data.map((d) => d.count),
          },
        ],
      };
      chart.update();
    }
  }, [data, chart]);

  return <canvas ref={ref} style={{ width: "100%", height: "100%" }} />;
};

export default CountProblem;
