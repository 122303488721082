import React from 'react';
import { Field } from 'formik';
import { useSelector } from 'react-redux';

import selectUser from '../../../shared/components/select-user';
import { withTranslate } from '../../../shared/components/translate';
import { getAcknowledgmentsMappedFilter } from 'selectors/acknowledgment.selector';

const CreatedBy = ({ translate }) => {
  const filter = useSelector(getAcknowledgmentsMappedFilter);

  return (
    <Field
      required
      isClearable
      name='createdBy'
      menuPlacement='bottom'
      menuPosition='fixed'
      label={translate("acknowledgment.form.createdBy")}
      query={{
        group: filter.group
      }}
      component={selectUser}
    />
  )
}

export default withTranslate(CreatedBy);