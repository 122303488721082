import React, { useState } from 'react';

import LoaderContainer from 'shared/components/loaderContainer';
import NoContentContainer from 'shared/components/noContentContainer';
import { ErrorBoundary } from 'shared/components/errorBoundary';

export const createDataFetcher = (alternative = []) => ({ fetchData, children, id }) => {
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState(alternative);

  React.useEffect(() => {
    setFetching(true);
    fetchData()
      .then(result => {
        setData(result);
      })
      .finally(() => {
        setFetching(false);
      })
  }, [fetchData, setData, setFetching, id]);

  return (
    <NoContentContainer noContent={!fetching && (!data)}>
      <LoaderContainer fetching={fetching}>
        <ErrorBoundary>
          {children({ data, fetching })}
        </ErrorBoundary>
      </LoaderContainer>
    </NoContentContainer>
  )
}

export const DataFetcher = createDataFetcher();