import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export const useMatchMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("md"));
};

export const useMatchPhone = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("xs"));
};

export const useMatchDesktop = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up("md"));
};
