import React from "react";

import { useMatchMobile } from "shared/hooks/responsive";
import Desktop from "./desktop";
import Mobile from "./mobile";

const StrategyDashboard = () => {
    const isMobile = useMatchMobile();

    if (isMobile) {
        return <Mobile />;
    }

    return <Desktop />;
};

export default StrategyDashboard;
