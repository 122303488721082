import React from "react";

import { useMatchMobile } from "shared/hooks/responsive";
import InstroctorMobile from "./instructorCard.mobile";
import InstroctorDesktop from "./instructorCard.desktop";
import "./index.css";

const InstructorCard = (props) => {
  const isMobile = useMatchMobile();

  if (isMobile) {
    return <InstroctorMobile {...props} />;
  }

  return <InstroctorDesktop {...props} />;
};

export default InstructorCard;
