import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, DialogActions, ListItem, Divider, List, ListSubheader } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { withTranslate } from 'shared/components/translate';
import { findAssignedRoleGroups, deleteRoleGroup } from 'reducers/ducks/roleGroup';

const DeleteDepartmentGoalDialog = ({ data, handleClose, reloadList, translate }) => {
  const dispatch = useDispatch();
  const [assigned, setAssigned] = React.useState([]);

  React.useEffect(() => {
    if (data && data.id) {
      dispatch(findAssignedRoleGroups(data.id))
        .then(response => setAssigned(response.data || []))
    }
  },
    [dispatch, data])

  if (!data) {
    return null;
  }

  return (
    <Dialog fullWidth open={!!data} onClose={handleClose}>
      <DialogTitle>{translate("roleGroup.delete.title")}</DialogTitle>
      <DialogContent>
        {translate("roleGroup.delete.roleGroups")} <b>{data.name}</b>

        {
          (assigned && Array.isArray(assigned)) && (
            <List
              subheader={
                <ListSubheader component="div">
                  {translate("roleGroup.delete.roleGroupsAssignedRoles")}
                </ListSubheader>
              }
            >
              {assigned.map(assign => (
                <ListItem key={assign.id}>
                  {`${assign.firstName} ${assign.lastName}`}
                </ListItem>
              ))}
            </List>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{translate("common.cancel")}</Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            dispatch(deleteRoleGroup(data.id))
              .then(() => reloadList())
              .then(() => handleClose())
          }}
        >
          {translate("common.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withTranslate(DeleteDepartmentGoalDialog);