import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { WithRbac } from 'common/rbac';
import { ROLES } from 'common/roles';
import { useTranslate } from 'shared/components/translate';

const DetailViewActions = ({ openEditDialog, openDeleteDialog }) => {
  const translate = useTranslate();

  return (
    <Grid container justify='flex-start' spacing={1} style={{ paddingTop: 32}}>
      <WithRbac action={ROLES["PROBLEMS-DELETE"]}>
        <Grid item>
          <Button variant='contained' color='secondary' onClick={openDeleteDialog}>{translate("common.delete")}</Button>
        </Grid>
      </WithRbac>
      <WithRbac action={ROLES["PROBLEMS-UPDATE"]}>
        <Grid item>
          <Button variant='contained' color='primary' onClick={openEditDialog}>{translate("common.edit")}</Button>
        </Grid>
      </WithRbac>
    </Grid>
  )
}

export default DetailViewActions;