import React from "react";
import { Grid, Avatar, Typography, makeStyles, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  avatar: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    top: -32,
    height: 59,
    width: 59,
    border: `5px solid ${theme.palette.background.default}`,
  },
  icon: {
    fontSize: "2rem",
  },
  advantageCard: {
    padding: "32px 16px 8px 16px",
    height: '100%',
    position: "relative",
    color: theme.palette.text.primary,
  },
  title: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    fontSize: 24,
    color: theme.palette.text.primary,
    fontWeight: "bold"
  },
  description: {
    padding: "8px 16px 8px 8px"
  }
}));

const Advantage = ({ description, icon: Icon, title, color }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.advantageCard}>
      <Avatar className={classes.avatar} style={{ color: color }}>
        <Icon classes={{ root: classes.icon }} />
      </Avatar>
      <Grid container>
        <Grid xs={12}>
          <Grid container spacing={2} wrap="nowrap" alignItems="center">
            <Grid item>
              <Typography className={classes.title}>
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} className={classes.description}>
          <Typography variant="body1" align="left">
            {description}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Advantage;
