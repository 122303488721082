import React from 'react';
import { Card, CardMedia, CardActionArea, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    // margin: '0 16px 8px 0',
    height: '100%',
  },
  media: {
    height: 250,
  },
});

const ImageCard = ({ photo, photoTitle, image }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea disableRipple disableTouchRipple>
        <CardMedia
          className={classes.media}
          image={image}
          title={photoTitle}
        />
      </CardActionArea>
    </Card>
  )
}

export default ImageCard;