import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Slide, Grid } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import { editIndicatorRecord } from 'reducers/ducks/records';
import { useAccess } from 'common/rbac';
import { ROLES } from 'common/roles';
import { reloadPage } from 'shared/utils/goTo';
import NumberField from 'shared/components/numberInput';
import InputField from 'shared/components/input';
import { useTranslate } from 'shared/components/translate';


const editFormSchema = yup.object().shape({
  value: yup.string().required(),
  comment: yup.string().nullable()
})

const skipFormSchema = yup.object().shape({
  comment: yup.string().required()
})

export const EditWrapper = ({ children, value, record }) => {
  const [open, setOpen] = React.useState(false);
  const canAccess = useAccess(ROLES["INDICATOR-RECORDS-UPDATE"]);
  const dispatch = useDispatch();
  const translate = useTranslate();
  const [commentWindowOpen, setCommentWindowOpen] = React.useState(false);

  useEffect(() => {
    if (record?.value == null && open) {
      setCommentWindowOpen(true)
    }
    return () => setCommentWindowOpen(false)
  }, [record, open])

  if ((value == null && !record) || !canAccess) {
    return children;
  }

  return (
    <React.Fragment>
      <Dialog open={open} fullWidth>
        <DialogTitle>{translate("indicators.records.edit")}</DialogTitle>
        <DialogContent>
          <Slide direction="right" in={!commentWindowOpen} exit={false} unmountOnExit>
            <Formik
              initialValues={record}
              validationSchema={editFormSchema}
              onSubmit={(val) => {
                dispatch(editIndicatorRecord(record.id, val))
                  .then(() => reloadPage())
              }}
            >
              <Form noValidate>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Field
                      label={translate("indicators.records.indicator")}
                      component={NumberField}
                      required
                      fullWidth
                      name='value' />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label={translate("indicators.records.comment")}
                      component={InputField}
                      fullWidth
                      rows={4}
                      multiline
                      name='comment' />
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button onClick={() => setOpen(false)}>{translate("common.cancel")}</Button>
                  <Button onClick={() => setCommentWindowOpen(true)} color="secondary" variant="contained">{translate("common.skip")}</Button>
                  <Button type='submit' color="primary" variant="contained">{translate("common.add")}</Button>
                </DialogActions>
              </Form>
            </Formik>
          </Slide>
          <Slide direction="left" in={commentWindowOpen} exit={false} unmountOnExit>
            <Formik
              initialValues={record}
              validationSchema={skipFormSchema}
              onSubmit={(val) => {
                dispatch(editIndicatorRecord(record.id, {
                  ...val,
                  value: undefined
                }))
                  .then(() => reloadPage())
              }}
            >
              <Form noValidate>
                <Field
                  required
                  label={translate("indicators.records.comment")}
                  component={InputField}
                  fullWidth
                  rows={4}
                  multiline
                  name='comment' />
                <DialogActions>
                  <Button onClick={() => setCommentWindowOpen(false)}>{translate("common.fill")}</Button>
                  <Button type='submit' color="primary" variant="contained">{translate("common.save")}</Button>
                </DialogActions>
              </Form>
            </Formik>
          </Slide>
        </DialogContent>
      </Dialog>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => setOpen(true)}
      >
        {children}
      </div>
    </React.Fragment>
  )
}