import React from "react";
import { path, split } from "ramda";
import { useTranslate, getActiveLanguage } from "./translate";
import { useSelector } from "react-redux";

const getPath = split(".");

export const createField = (Component) => ({
  field,
  form: { touched, errors, setFieldValue },
  ...rest
}) => {
  const pt = getPath(field.name);
  const translate = useTranslate();
  const fieldTouched = path(pt, touched);
  const fieldErrors = path(pt, errors);
  const locale = useSelector(getActiveLanguage);

  return (
    <Component
      {...field}
      {...rest}
      InputLabelProps={{ shrink: true }}
      FormHelperTextProps={{
        error: fieldTouched && fieldErrors,
        title: fieldErrors ? translate(fieldErrors) : "",
        hidden: false,
      }}
      labelFunc={(momentVal, invalidLabel) => {
        if (!field.value) {
          return "";
        }
        return momentVal && momentVal.isValid()
          ? momentVal.format(rest.format)
          : "";
      }}
      placeholder={translate("common.select")}
      cancelLabel={translate("common.cancel")}
      okLabel={translate("common.ok")}
      todayLabel={translate("common.today")}
      clearLabel={translate("common.clear")}
      onChange={(val) => {
        try {
          return setFieldValue(field.name, val ? val.toJSON() : val);
        } catch (error) {
          return;
        }
      }}
    />
  );
};

export default createField;
