import React, { useState, useEffect } from 'react';
import RSSelect from 'react-select';

import { useTranslate } from '../translate';
import { useMatchMobile } from 'shared/hooks/responsive';
import DialogSelect from './dialogSelect';

export const AsyncSelect = ({ styles, ...props }) => {
  const translate = useTranslate();
  const mobile = useMatchMobile();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    props.loadOptions()
      .then(data => setOptions(data));
  }, [setOptions])

  if (mobile) {
    return (
      <DialogSelect options={options} {...props} />
    );
  }


  return (
    <RSSelect
      {...props}
      styles={styles}
      options={options}
      noOptionsMessage={() => translate("common.noRecords")}
    />)
}

export default AsyncSelect;
