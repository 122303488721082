import { path } from "ramda";

const SET_DATA = 'MYKPI/TEMPLATE/SET_DATA';
const SET_IS_FETCHING = 'MYKPI/TEMPLATE/SET_IS_FETCHING';

const defaultState = {
  data: [],
  fetching: false,
};

export default (state = defaultState, { type, payload } = {}) => {
  switch (type) {
    case SET_DATA:
      return {
        ...state,
        data: payload
      }
    case SET_IS_FETCHING:
      return {
        ...state,
        fetching: payload
      }
    default:
      return state
  }
}

export const selectors = pt => ({
  getData: path([...pt, 'data']),
  fetching: path([...pt, 'fetching']),
});

const setData = data => ({
  type: SET_DATA,
  payload: data
});

const setIsFetching = fetching => ({
  type: SET_IS_FETCHING,
  payload: fetching
});

export const fetchTemplates = (dispatch, getState, { api }) => {
  return api.get('/private/template/list');
}

export const fetchTemplatesAndStore = (dispatch, getState, { api }) => {
  dispatch(setIsFetching(true));

  return dispatch(fetchTemplates)
    .then(response => {
      dispatch(setData(response.data))
    })
    .finally(() => {
      dispatch(setIsFetching(false));
    })
}

export const editTemplate = (id, template) => (dispatch, getState, { api }) => {
  return api.put(`/private/template/id/${id}`, template);
}

export const createTemplate = template => (dispatch, getState, { api }) => {
  return api.post(`/private/template/create`, template);
}

export const getTemplate = id => (dispatch, getState, { api }) => {
  return api.get(`/private/template/id/${id}`);
}

export const deleteTemplate = id => (dispatch, getState, { api }) => {
  return api.delete(`/private/template/id/${id}`);
}

export const findIndicatorTemplates = query => (_, __, { api }) => {
  return api.get(`/private/template/find`);
}