import React from "react";
import { Grid, Paper, Button, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import PageHeader from "shared/components/pageHeader";
import Table from "./table";
import { WithRbac } from "common/rbac";
import { ROLES } from "common/roles";
import { useTranslate } from "shared/components/translate";

const DepartmentContainer = () => {
  const translate = useTranslate();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <PageHeader title={translate("departments.title")}>
          <WithRbac action={ROLES["DEPARTMENT-CREATE"]}>
            <Link component={RouterLink} to="/department/create">
              <Button color="primary" variant="contained">
                {translate("departments.add")}
              </Button>
            </Link>
          </WithRbac>
        </PageHeader>
      </Grid>
      <Grid item xs={12}>
        <div style={{ padding: 16 }}>
          <Paper>
            <Table />
          </Paper>
        </div>
      </Grid>
    </Grid>
  );
};

export default DepartmentContainer;
