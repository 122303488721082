import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Formik } from "formik";
import {
  Grid,
  Button,
  Link,
  Card,
  CardContent,
  CardActions,
  CardHeader,
} from "@material-ui/core";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { compose, path } from "ramda";

import UserInfo from "./user";
import CompanyInfo from "./company";
import { createUserWithCompany } from "../../services/users.service";
import { withTranslate } from "../../shared/components/translate";
import { validationSchema } from "./validation";
import { getLanguageCode } from "selectors/locale.selectors";
import { Business, Person } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(),
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

function ScrollableTabsButtonAuto({ history, translate }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const languageCode = useSelector(getLanguageCode);

  return (
    <Card className={classes.root}>
      <CardHeader title={translate("company.create.title")} />
      <CardContent>
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            locale: languageCode,
          }}
          onSubmit={(val, { setErrors, setSubmitting }) => {
            setSubmitting(true);
            dispatch(createUserWithCompany(val))
              .then(() => history.push("/auth/login"))
              .catch((err) => {
                const errors = path(["response", "data", "errors"], err);
                if (errors) setErrors(errors);
              })
              .finally(() => setSubmitting(false));
          }}
          render={(props) => (
            <form onSubmit={props.handleSubmit}>
              <Typography color="textSecondary" variant="h6" style={{ padding: '0 0 1em 0', display: 'flex', alignItems: 'center' }}>
                <Person /><span style={{ paddingLeft: '1em' }} />
                {translate("company.create.userTitle")}
              </Typography>
              <UserInfo />
              <Typography
                style={{ padding: '1em 0', display: 'flex', alignItems: 'center' }}
                color="textSecondary"
                variant="h6"
              >
                <Business /><span style={{ paddingLeft: '1em' }} />
                {translate("company.create.companyTitle")}
              </Typography>
              <CompanyInfo />
              <CardActions>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Link component={RouterLink} to="/home">
                      <Button fullWidth>
                        {translate("company.create.cancel")}
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!props.isValid}
                    >
                      {translate("company.create.accept")}
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </form>
          )}
        />
      </CardContent>
    </Card>
  );
}

export default compose(withTranslate, withRouter)(ScrollableTabsButtonAuto);
