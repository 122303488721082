import React, { useEffect, useState } from "react";
import { Paper, Grid, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { path, compose } from "ramda";
import { withRouter } from "react-router-dom";

import PageHeader from "shared/components/pageHeader";
import { withTranslate } from "shared/components/translate";
import { getDepartment, editDepartment } from "reducers/ducks/department";
import Form from "../form";
import validationSchema from "../form/validateSchema";

const EditDepartment = ({ translate, history, match }) => {
  const [department, setDepartment] = useState();
  const dispatch = useDispatch();
  const id = path(["params", "id"], match);

  useEffect(() => {
    dispatch(getDepartment(id)).then((response) => {
      setDepartment(response.data);
    });
  }, [dispatch, id]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader title={translate("departments.edit.title")} />
      </Grid>
      <Grid item xs={12}>
        <div style={{ padding: 16 }}>
          <Paper style={{ padding: 16 }}>
            <Formik
              enableReinitialize
              initialValues={department}
              validationSchema={validationSchema}
              onSubmit={(value, { setSubmitting, setErrors }) => {
                setSubmitting(true);
                dispatch(editDepartment(id, value))
                  .then(() => {
                    history.push("/department");
                  })
                  .catch((error = {}) => {
                    const errors = path(["response", "data", "errors"], error);
                    setErrors(errors);
                  })
                  .finally(() => setSubmitting(false));
              }}
              render={(props) => (
                <Form {...props}>
                  <Grid container spacing={1}>
                    <Grid item onClick={() => history.push("/department")}>
                      <Button>{translate("common.cancel")}</Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={props.isSubmitting || !props.isValid}
                      >
                        {translate("common.edit")}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            />
          </Paper>
        </div>
      </Grid>
    </Grid>
  );
};

export default compose(withRouter, withTranslate)(EditDepartment);
