import React from 'react';
import { Grid, Typography, ListItem, List } from '@material-ui/core';
import { useTranslate } from 'shared/components/translate';
import ImageCard from './image';
import Mistakes from 'assets/img/mistakes.jpg';

const Post2 = () => {
  const translate = useTranslate();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">
          {translate("landingPage.blogPost.post2.title")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ImageCard image={Mistakes} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.blogPost.post2.p1")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <List>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post2.l1")}</Typography></ListItem>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post2.l2")}</Typography></ListItem>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post2.l3")}</Typography></ListItem>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post2.l4")}</Typography></ListItem>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post2.l5")}</Typography></ListItem>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post2.l6")}</Typography></ListItem>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post2.l7")}</Typography></ListItem>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post2.l8")}</Typography></ListItem>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post2.l9")}</Typography></ListItem>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post2.l10")}</Typography></ListItem>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post2.l11")}</Typography></ListItem>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post2.l12")}</Typography></ListItem>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post2.l13")}</Typography></ListItem>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post2.l14")}</Typography></ListItem>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post2.l15")}</Typography></ListItem>
        </List>
      </Grid>
    </Grid>
  )
}

export default Post2;