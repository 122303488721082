import React from "react";
import { Formik, Form } from "formik";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
} from "@material-ui/core";
import { useDispatch } from "react-redux";

import IndicatorForm, {
  validationSchema,
} from "components/indicators/form/group";
import { createGroupIndicator } from "reducers/ducks/indicator/createIndicator";
import { fetchIndicatorFilter } from "reducers/ducks/indicator";
import { useTranslate } from "shared/components/translate";

const CreateGroupIndicator = ({ open, handleClose, ...props }) => {
  const dispatch = useDispatch();
  const translate = useTranslate();

  return (
    <Dialog open={open} onClose={handleClose}>
      <Formik
        onSubmit={(val) => {
          dispatch(createGroupIndicator(val))
            .then(() => dispatch(fetchIndicatorFilter()))
            .then((response) => handleClose());
        }}
        validationSchema={validationSchema}
        initialValues={{
          type: "department",
        }}
      >
        {({ isValid }) => (
          <Form>
            <DialogTitle>
              {translate("indicators.dialogs.department.title")}
            </DialogTitle>
            <DialogContent>
              <IndicatorForm />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>
                {translate("common.cancel")}
              </Button>
              <Button
                disabled={!isValid}
                variant="contained"
                color="primary"
                type="submit"
              >
                {translate("common.create")}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateGroupIndicator;
