import React from 'react';
import { TextField } from '@material-ui/core';
import { path, split } from 'ramda';
import { withTranslate } from './translate';

const getPath = split('.');

export const float = (value, previousValue) => {
    // if (/^\d{0,}[\.|\,]{0,1}\d{0,}$/g.test(value) || value === '') {
    if (/((\b|\+|-)(0|([1-9][0-9]*))(\.[0-9]+)?)\b/g.test(value) || value === '') {

        return value;
    }
    return previousValue;
};

const parseFloat = value => {
    const type = typeof value;

    if (type === 'string') {
        return Number.parseFloat(value.replace(',', '.'))
    }

    return value
}

const NumberField = ({
    field,
    form: { touched, errors },
    translate,
    innerRef,
    ...rest
}) => {
    const pt = getPath(field.name);
    const fieldTouched = path(pt, touched);
    const fieldErrors = path(pt, errors);

    return (
        <TextField
            InputLabelProps={{ shrink: true }}
            placeholder={translate("common.fill")}
            {...field}
            innerRef={innerRef}
            onChange={e => {
                const mockEvent = {
                    target: {
                        name: field.name,
                        value: float(e.target.value, field.value)
                    }
                }
                field.onChange(mockEvent);
            }}
            onBlur={e => {
                const mockEvent = {
                    target: {
                        value: !!field.value ? parseFloat(field.value) : undefined,
                        name: field.name,
                    }
                }
                field.onChange(mockEvent);
                field.onBlur(e)
            }}
            value={field.value == null ? '' : field.value}
            error={fieldTouched && fieldErrors}
            {...rest}
            helperText={rest.helperText || (fieldTouched && fieldErrors ? translate(fieldErrors) : undefined)}
        />
    )
}

export default withTranslate(NumberField)