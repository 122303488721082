import React from 'react';
import { components } from 'react-select';
import { path } from 'ramda';

import { AsyncSelect } from 'shared/components/select';
import { createField } from './selectField';
import { findStatus } from 'reducers/ducks/problems/findStatus';
import { useDispatch } from 'react-redux';
import { useTranslate } from './translate';

const Status = ({ colorCode }) => (
  <span style={{
    height: 16,
    width: 16,
    borderRadius: 4,
    backgroundColor: colorCode,
    border: '1px solid #eee',
    marginRight: 8,
    display: 'inline-block'
  }} />
)

const IconOption = (props) => {
  const { Option } = components;
  const translate = useTranslate();

  return (
    <Option {...props}>
      <Status colorCode={props.data.colorCode} />
      {translate(`enums.statuses.${props.data.name}`)}
    </Option>
  );
}
const RCInput = components.SingleValue;

const Input = (props) => {
  const value = path(['selectProps', 'value'], props);
  const translate = useTranslate();

  return (
    <RCInput {...props}>
      <span style={{
        height: 16,
        width: 16,
        borderRadius: 4,
        backgroundColor: path(['colorCode'], value),
        border: '1px solid #eee',
        marginRight: 8,
        display: 'inline-block'
      }} />
      {path(['description'], value) ? translate(`enums.statuses.${props.data.name}`) : ""}
    </RCInput>
  )
}

const MultiValue = (props) => {
  const value = path(['selectProps', 'value'], props);
  const translate = useTranslate();


  return (
    <>
      <span style={{ margin: '2px 0 0 2px' }}><Status colorCode={props.data.colorCode} /></span>
      {translate(`enums.statuses.${props.data.name}`)}
    </>
  )
}
export const SelectStatus = (props) => {
  const dispatch = useDispatch();
  const translate = useTranslate();

  return (
    <AsyncSelect
      {...props}
      defaultOptions
      loadOptions={val => dispatch(findStatus(val)).then(response => response.data)}
      components={{
        Option: IconOption,
        SingleValue: Input,
        MultiValueLabel: MultiValue
      }}
      getOptionValue={path(["id"])}
      getOptionLabel={val => translate(`enums.statuses.${val.name}`)}
      noOptionsMessage={() => translate("common.noRecords")}
      menuPlacement='bottom'
      menuPosition='fixed' />
  );
}

export default createField(SelectStatus)