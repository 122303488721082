import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";

const InfoChartWrapper = ({ children, label, percentage }) => {
  return (
    <Card elevation={0} style={{ height: "140px", padding: 16 }}>
      <Grid container style={{ height: "100%" }}>
        <Grid item xs={6}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            style={{ lineHeight: "1em" }}
          >
            {label}
          </Typography>
          <Typography
            variant="subtitle2"
            color="error"
            style={{ fontSize: 21 }}
          >
            {percentage || ""}
          </Typography>
        </Grid>
        <Grid item xs={6} className="h-100">
          {children}
        </Grid>
      </Grid>
    </Card>
  );
};

export default InfoChartWrapper;
