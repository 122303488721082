import React from 'react';
import { Collapse, Grid, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { Date as DateContainer } from 'shared/components/date';
import { getFilterFrom, getFilterTill } from 'selectors/indicator.selector';
import Filter from '../filter/recordDateRangeFilter';
import { useTranslate } from 'shared/components/translate';

export const RecordDateRange = () => {
  const [open, setOpen] = React.useState(false);
  const fromDate = useSelector(getFilterFrom);
  const tillDate = useSelector(getFilterTill);
  const translate = useTranslate();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Collapse
          in={open}
          unmountOnExit={false}
        >
          <Filter handleClose={() => setOpen(false)}>
            <Grid container spacing={1}>
              <Grid item>
                <Button onClick={() => setOpen(false)}>{translate("common.collapse")}</Button>
              </Grid>
              <Grid item>
                <Button variant='contained' color='secondary' type='submit'>{translate("common.search")}</Button>
              </Grid>
            </Grid>
          </Filter>
        </Collapse>
      </Grid>
      {
        !open && (
          <Grid item xs={12}>
            <Grid container spacing={1} wrap='nowrap' alignItems='center'>
              <Grid item>
                <Button
                  variant='outlined'
                  color='primary'
                  size='small'
                  onClick={() => setOpen(true)}
                >
                  {translate("indicators.records.selectInterval")}
                </Button>
              </Grid>
              <Grid item>
                <DateContainer format='YYYY-MM-DD' date={fromDate} />
              </Grid>
              <Grid item>
                -
            </Grid>
              <Grid item>
                <DateContainer format='YYYY-MM-DD' date={tillDate} />
              </Grid>
            </Grid>
          </Grid>
        )
      }
    </Grid>
  )
}