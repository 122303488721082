import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const NetworkLegend = ({ colors, description }) => {

  return (
    <Typography variant="subtitle1" color="textPrimary">
      <Grid container alignItems="center" alignContent='center'>
        <Grid item>
          {description}
        </Grid>
        <Grid item style={{ marginTop: 2 }}>
          {
            Object.values(colors).map(color => (
              <div style={{ display: 'inline-block', marginLeft: 4, height: 16, width: 16, backgroundColor: color, borderRadius: 2 }} />
            ))
          }
        </Grid>
      </Grid>
    </Typography>
  )
}

export default NetworkLegend;