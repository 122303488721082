import React from 'react';
import { TableBody, TableRow, TableCell, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { path } from 'ramda';
import moment from 'moment';

import { Date } from '../../../shared/components/date';
import { withTranslate } from '../../../shared/components/translate';
import { actions } from '../../../shared/components/notifications';
import { getMyGivenAcknowledgments } from '../../../selectors/acknowledgment.selector';
import { removeAcknowledgment, fetchMyGivenAcknowledgment } from '../../../services/acknowledgment';

const MyGivenBody = ({ translate }) => {
  const givenAcknowledgments = useSelector(getMyGivenAcknowledgments);
  const dispatch = useDispatch();

  return (
    <TableBody>
      {
        givenAcknowledgments.map((ack) => (
          <TableRow key={ack.id}>
            <TableCell>
              <Date format={'YYYY-MM-DD'} date={ack.createdAt} />
            </TableCell>
            <TableCell>
              {path(['assignTo', 'firstName'], ack)}
              {' '}
              {path(['assignTo', 'lastName'], ack)}
            </TableCell>
            <TableCell>
              {path(['assignTo', 'department', 'name'], ack)}
            </TableCell>
            <TableCell>
              {
                moment(ack.createdAt).isBefore(moment().subtract(1, 'days')) ?
                  '-' : (
                    <Button
                      size='small'
                      color='primary'
                      onClick={() => {
                        dispatch(removeAcknowledgment(ack.id))
                          .then(() => {
                            dispatch(fetchMyGivenAcknowledgment)
                              .then(() => actions.displayMessage('success', translate("notifications.success.delete")))
                              .catch(() => actions.displayMessage('error', translate("notifications.errors.delete")));
                          })
                      }}
                    >
                      {translate("common.remove")}
                    </Button>
                  )
              }
            </TableCell>
          </TableRow>
        ))
      }
    </TableBody>
  )
}

export default withTranslate(MyGivenBody);