import React from "react";
import { Grid, Collapse } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { toggleOpen } from "reducers/ducks/problems/filter/state";
import { isOpenFilter } from "selectors/problem.selector";
import ProblemHeader from "components/problems/problemHeader";
import CreateProblemDialog from "./dialog";
import FilterContainer from "./filter";

export default ({ children }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const toggleFilter = () => dispatch(toggleOpen);
  const openFilter = useSelector(isOpenFilter);

  return (
    <React.Fragment>
      <CreateProblemDialog handleClose={() => setOpen(false)} open={open} />
      <Grid container style={{ backgroundColor: "white" }}>
        <Grid item xs={12}>
          <ProblemHeader
            handleCreate={() => setOpen(true)}
            toggleFilter={toggleFilter}
          />
        </Grid>
        <Grid item xs={12}>
          <Collapse in={openFilter} unmountOnExit={false}>
            <div style={{ padding: 16, borderBottom: "1px solid #dfdfdf" }}>
              <FilterContainer />
            </div>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
