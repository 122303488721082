import React from "react";
import { Typography, Avatar, makeStyles } from "@material-ui/core";
import { TransitionGroup } from "react-transition-group";
import "./index.css";
import { useTranslate } from "shared/components/translate";

const useStyles = makeStyles((theme) => ({
  avatarImage: {
    border: `5px solid ${theme.palette.background.default}`,
  },
}));
const AnimatedAvatar = ({ photo }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <TransitionGroup className="cv-avatar">
      <Avatar
        className={`cv-avatar-image ${classes.avatarImage}`}
        variant="circle"
        style={{ height: 170, width: 170 }}
        src={photo}
      />
    </TransitionGroup>
  );
};

export default AnimatedAvatar;
