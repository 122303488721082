import React from "react";
import { Grid, Typography } from "@material-ui/core";

import moment from "moment";
import { useTranslate } from "shared/components/translate";

export const INDICATOR_TYPES = {
  EMPTY: {
    color: "#fff",
  },
  CURRENT: {
    color: "#C2185B",
  },
  GOOD: {
    color: "rgba(98, 255, 0, .19)",
  },
  BAD: {
    color: "rgba(255, 0, 0, .19)",
  },
  SKIP: {
    color: "rgba(63, 81, 181, .19)",
  },
};

export const getVariant = (variant) => {
  const type = INDICATOR_TYPES[variant];

  if (!type) {
    return INDICATOR_TYPES.EMPTY;
  }

  return type;
};

export const Record = ({ date, title, variant }) => {
  const parsedVariant = getVariant(variant);
  const translate = useTranslate();
  const formatText = () => {
    if (variant === "SKIP") {
      return "-"
    }

    return title != null ? title : translate("common.fill")
  }

  return (
    <div
      style={{
        height: 120,
        width: 120,
        padding: 16,
        borderRadius: 2,
        backgroundColor: parsedVariant.color,
        boxShadow: "0px 2px 6px 0px rgba(0,0,0,0.19)",
      }}
    >
      <Grid container style={{ height: "100%" }}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            align="center"
            style={{ fontWeight: "bold" }}
          >
            {`${moment(date).weekYear()}-${moment(date).format(
              "WW"
            )} ${translate("common.weekMin")}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Typography
                variant="h4"
                color="primary"
                align="center"
                style={{ fontSize: "2em" }}
              >
                {formatText()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
