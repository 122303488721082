import React from 'react'
import { Link, withRouter } from 'react-router-dom';
import { Card, CardHeader, CardContent, Button, Typography, CardActions } from '@material-ui/core';

import { withTranslate } from '../../shared/components/translate';

const ConfirmDevice = withRouter(({ translate }) => {

  return (
    <Card>
      <CardHeader title={translate('auth.confirmDevice.title')} />
      <CardContent >
        <Typography>
          {translate("auth.confirmDevice.body")}
        </Typography>
      </CardContent>
      <CardActions>
        <Button component={Link} to="/auth/login">{translate("common.back")}</Button>
      </CardActions>
    </Card>
  );
})

export default withTranslate(ConfirmDevice);