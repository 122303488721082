import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslate } from 'shared/components/translate';
import YoutubeVideo from './youtubeVideo';

const DesktopGuide = () => {
  const translate = useTranslate();

  return (
    <Grid container justify="center">
      <Grid item xs={11} lg={9}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Typography variant="h4" color="textPrimary">
              {translate("landingPage.guide.title")}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <YoutubeVideo />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DesktopGuide