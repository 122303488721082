export const findIndicator = type => q => (_, __, { api }) => {
  return api.get(`/private/indicator/find/type/${type}`)
}

export const findUserIndicators = id => q => (_, __, { api }) => {
  return api.get(`/private/indicator/find/user/${id}`)
}

export const findDepartmentIndicators = id => q => (_, __, { api }) => {
  return api.get(`/private/indicator/find/department/${id}`)
}