import React from 'react';
import { Grid, Divider } from '@material-ui/core';

import UserChart from './userChart';

const Charts = ({ selected }) => {
  return (
    <Grid container spacing={2}>
      {
        Object.values(selected)
          .filter(val => !!val)
          .map(department => {
            return (
              <Grid item xs={12} key={department.id}>
                <UserChart department={department} />
              </Grid>
            )
          })
      }

    </Grid>
  )
}

export default Charts;