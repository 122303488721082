import React from "react";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Formik, Field } from "formik";
import { path } from "ramda";
import moment from "moment";

import { getFilterFrom, getFilterTill } from "selectors/indicator.selector";
import { createField } from "shared/components/datePicker";
import { validationSchemaRecordDateRange } from "./validationSchema";
import { setRangeDate } from "reducers/ducks/indicator/recordDateRange";
import { useTranslate } from "shared/components/translate";

const DateField = createField(KeyboardDatePicker);

const Filter = ({ children, handleClose }) => {
  const from = useSelector(getFilterFrom);
  const till = useSelector(getFilterTill);
  const dispatch = useDispatch();
  const translate = useTranslate();

  return (
    <Formik
      validationSchema={validationSchemaRecordDateRange}
      enableReinitialize
      initialValues={{ from, till }}
      onSubmit={(val) => {
        dispatch(
          setRangeDate(
            moment(val.from).startOf("month").toJSON(),
            moment(val.till).endOf("month").toJSON()
          )
        );
        handleClose();
      }}
    >
      {(formProps) => (
        <form onSubmit={formProps.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={4}>
              <Field
                fullWidth
                required
                autoOk
                views={["month"]}
                variant="inline"
                name="from"
                label={translate("indicators.filters.from")}
                format="YYYY-MM"
                maxDate={path(["values", "to"], formProps)}
                maxDateMessage={translate("indicators.filters.maxDateMessage")}
                component={DateField}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <Field
                fullWidth
                required
                autoOk
                variant="inline"
                views={["month"]}
                name="till"
                label={translate("indicators.filters.till")}
                format="YYYY-MM"
                minDateMessage={translate("indicators.filters.minDateMessage")}
                minDate={path(["values", "from"], formProps)}
                component={DateField}
              />
            </Grid>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default Filter;
