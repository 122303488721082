import moment from "moment";

const NAME = 'DASHBOARD_FILTER';

const SET_FIELDS = `${NAME}/SET_FIELDS`

const defaultState = {
    from: moment().startOf('year').toJSON(),
    till: moment().endOf('year').toJSON(),
}

export default (state = defaultState, { type, payload } = {}) => {
    switch (type) {
        case SET_FIELDS:
            return {
                ...state,
                ...payload
            }
        default:
            return state
    }
}

export const setDashbordFields = (values) => ({
    type: SET_FIELDS,
    payload: values
})