import React from 'react';
import { useMatchMobile } from 'shared/hooks/responsive';

import DesktopGuide from './desktop'
const Guide = () => {
  // const isMobile = useMatchMobile();

  // // if (isMobile) {
  // //   return <Mobile />
  // // }

  return <DesktopGuide />
}

export default Guide