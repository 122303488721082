export default class EventDispatcher {
    events = {};
    on(event, callback) {
        const handlers = this.events[event] || [];
        handlers.push(callback);
        this.events[event] = handlers;
    }

    trigger(event, data) {
        const handlers = this.events[event];

        if (!handlers || handlers.length < 1) {
            return;
        }

        [].forEach.call(handlers, handler => handler(data));
    }

    off(event) {
        if (this.events[event]) {
            delete this.events[event];
        }
    }
}
