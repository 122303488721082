import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useTranslate } from "shared/components/translate";

const EndedSupscription = () => {
  const translate = useTranslate();
  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Grid container justify="center">
          <Grid item>
            <Typography variant="h2" color="primary" align="center">
              {translate("landingPage.supscription.title")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="body1" color="textSecondary">
          {translate("landingPage.supscription.helperText")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EndedSupscription;
