import React from "react";
import { Grid, Divider } from "@material-ui/core";

import UserChart from "./userChart";

const Charts = ({ selected }) => {
  return (
    <div>
      <Grid container spacing={1}>
        {Object.values(selected)
          .filter((val) => !!val)
          .map((user) => {
            return (
              <Grid item xs={12} key={user.id}>
                <UserChart user={user} />
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

export default Charts;
