import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';

const Loader = () => {
  return (
    <Grid container justify='center' alignItems='center' style={{ height: '100%' }}>
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  )
}

export default Loader;