import React from 'react'
import { Add } from '@material-ui/icons';
import { Card, CardContent, Grid, Button, Link, Collapse } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Table from './table';
import PageHeader from 'shared/components/pageHeader';
import { withTranslate } from 'shared/components/translate';
import { WithRbac } from 'common/rbac';
import { ROLES } from 'common/roles';
import { fetchUserList } from 'services/users.service';
import Filter from './filter';

const Users = ({ translate }) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = React.useState({});
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    dispatch(fetchUserList(filter));
  }, [dispatch, filter]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <PageHeader title={translate("users.title")} >
          <Grid container justify='flex-end' spacing={1}>
            <Grid item>
              <Button variant='outlined' color='primary' onClick={() => setOpen(!open)}>
                {translate("common.filter")}
              </Button>
            </Grid>
            <WithRbac action={ROLES["USER-CREATE"]}>
              <Grid item>
                <Link component={RouterLink} to='/users/create'>
                  <Button variant='contained' color='primary'>
                    <Add />
                    {translate("common.add")}
                  </Button>
                </Link>
              </Grid>
            </WithRbac>
          </Grid>
        </PageHeader>
      </Grid>
      <Grid item xs={12}>
        <div style={{ padding: 16 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Collapse in={open}>
                <Grid container>
                  <Grid item xs={12}>
                    <Filter onSubmit={val => setFilter(val)} />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <div style={{ overflow: 'auto', maxWidth: '100vw' }}>
                    <Table />
                  </div>
                </CardContent>
              </Card>
            </Grid></Grid>
        </div>
      </Grid>

    </Grid>
  );
}

export default withTranslate(Users);