import { useMemo } from "react";
import { Business, Equalizer, People, Person } from '@material-ui/icons';

import { useTranslate } from "shared/components/translate";
import { ROLES } from "common/roles";

export const useDashboardDropdownOptions = () => {
  const translate = useTranslate();

  return useMemo(() => [
    {
      title: translate('dashboards.option.company'),
      type: "company",
      roles: [ROLES["INDICATOR-READ-VISION"]],
      icon: Business
    },
    {
      title: translate('dashboards.option.strategy'),
      type: "strategy",
      roles: [ROLES["INDICATOR-READ-STRATEGY"]],
      icon: Equalizer
    },
    {
      title: translate('dashboards.option.department'),
      type: "department",
      roles: [ROLES["INDICATOR-READ-DEPARTMENT"]],
      icon: People
    },
    {
      title: translate('dashboards.option.employee'),
      type: "employee",
      roles: [ROLES["INDICATOR-READ-EMPLOYEE"]],
      icon: Person
    },
  ], [translate])
}