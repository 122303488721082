import {
  createSetPage,
  createSetSize,
  createSetTotal,
  createSetPaginationFromResponse,
  selectors as paginationSelectors
} from 'reducers/ducks/common/pagination';


export const NAME = 'PROBLEMS_PAGINATION';

export const selectors = paginationSelectors(['problem', 'list', 'pagination']);

export const setPage = createSetPage(NAME);
export const setTotal = createSetTotal(NAME);
export const setSize = createSetSize(NAME);
export const setPaginationFromResponse = createSetPaginationFromResponse(NAME);
