import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Table, TableHead, TableRow, TableCell } from "@material-ui/core";

import DepartmentBody from "./body";
import { fetchData } from "reducers/ducks/department/table";
import DeleteDialog from "./deleteDepartmentDialog";
import { WithRbac } from "common/rbac";
import { ROLES } from "common/roles";
import { useTranslate } from "shared/components/translate";

const DepartmentTable = () => {
  const dispatch = useDispatch();
  const translate = useTranslate();

  useEffect(() => {
    dispatch(fetchData);
  }, [dispatch]);

  return (
    <React.Fragment>
      <DeleteDialog />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{translate("departments.table.name")}</TableCell>
            <WithRbac action={ROLES["DEPARTMENT-UPDATE"]}>
              <TableCell>{translate("departments.table.actions")}</TableCell>
            </WithRbac>
          </TableRow>
        </TableHead>
        <DepartmentBody />
      </Table>
    </React.Fragment>
  );
};

export default DepartmentTable;
