import { path } from 'ramda';

export const fetchCurrentUser = (dispatch, getState, { api }) => {
  dispatch({ type: 'user/current_user_request' });

  return api.get('/private/user/current')
    .then((result) => {
      dispatch({
        type: 'user/current_user_success',
        payload: result.data
      });

      return result
    });
}