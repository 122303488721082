import { pathOr } from 'ramda';
import { createSelector } from 'reselect';

const emptyObj = {};
const emptyArray = [];

export const getAcknowledgments = pathOr(emptyArray, ['acknowledgment', 'list']);
export const getMyGivenAcknowledgments = pathOr(emptyArray, ['acknowledgment', 'myList']);
export const getAcknowledgmentsFilter = pathOr(emptyObj, ['acknowledgment', 'filter']);

export const getAcknowledgmentsMappedFilter = createSelector(getAcknowledgmentsFilter, filter => {
  const mappedFilter = {}

  if (filter.assignedTo) {
    mappedFilter.assignTo = filter.assignedTo.map(user => user.id)
  }
  if (filter.group) {
    mappedFilter.group = filter.group.map(group => group.id)
  }

  return mappedFilter
})