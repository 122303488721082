const createIndicator = (type) => values => (_, __, { api }) => {
  return api.post(
    `/private/indicator/${type}/create`,
    values
  )
}

export const createEmployeeIndicator = values => async dispatch => {
  const create = createIndicator('employee');

  for (const assign of values.assignTo) {
    await dispatch(create({ ...values, assignTo: assign }))
  }

  return true;
};

export const createGroupIndicator = createIndicator('department');
export const createStrategicIndicator = createIndicator('strategic');
export const createVisionIndicator = createIndicator('vision');
export const createMission = (values) => (_, __, { api }) => {
  return api.post( `/private/mission/create`, values);
}
export const editMission = (values) => (_, __, { api }) => {
  return api.put( `/private/mission/edit`, values);
}