import React from "react";
import ChartJs from "chart.js";
import { useTheme } from "@material-ui/core";
import { useTranslate } from "shared/components/translate";

const CountProblem = ({ data }) => {
  const ref = React.useRef();
  const theme = useTheme();
  const [chart, setChart] = React.useState();
  const translate = useTranslate();

  React.useEffect(() => {
    const chart = new ChartJs(ref.current, {
      type: "doughnut",
      options: {
        // responsive: true,
        maintainAspectRatio: true,
        legend: false,
      },
    });

    setChart(chart);
  }, [setChart]);

  React.useEffect(() => {
    if (chart) {
      chart.data = {
        labels: [
          translate('dashboards.employeeCharts.indicatorFulfillment.achieved'),
          translate('dashboards.employeeCharts.indicatorFulfillment.notReached')],
        datasets:
          data == null
            ? []
            : [
              {
                label: "Įvykdyta",
                backgroundColor: [
                  theme.palette.primary.main,
                  theme.palette.error.main,
                ],
                data: [data, 100 - data],
              },
            ],
      };
      chart.update();
    }
  }, [data, chart]);

  return <canvas ref={ref} style={{ width: "100%", height: "100%" }} />;
};

export default CountProblem;
