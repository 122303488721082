import { assoc } from 'ramda';

const defaultState = {
  list: [],
};

export const reducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'user/current_user_success':
      return assoc('current', action.payload, state);
    case 'user/token_success':
      return assoc('token', action.payload, state);
    case 'user/fetch_user_list_success':
      return assoc('list', action.payload, state);
    default:
      return state;
  }
}