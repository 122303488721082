import React from "react";
import { Grid, Typography, Avatar } from "@material-ui/core";

const UserAvatar = ({ firstName, lastName }) => {
  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <Avatar color="primary">{firstName[0]}</Avatar>
      </Grid>
      <Grid item>
        <Typography color="textSecondary" variant="subtitle2">
          {firstName} {lastName}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default UserAvatar;
