import React from "react";
import { Route, Switch } from "react-router-dom";

import UsersTable from '../components/users/table';
import Create from '../components/users/create';
import Edit from '../components/users/edit';

export default (props) => {
  return (
    <Switch>
      <Route
        path={`${props.match.url}/edit/:id`}
        component={Edit} />
      <Route
        path={`${props.match.url}/create`}
        component={Create} />
      <Route
        path={`${props.match.url}`}
        component={UsersTable} />
    </Switch>
  )
}


