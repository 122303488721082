import React from 'react';
import { Grid } from '@material-ui/core';
import StrategyList from './list';
import SearchContainer from './list/searchContainer';
import StrategyChart from './charts';

const StrategyDashboard = () => {
  const [selected, setSelected] = React.useState({});
  const [search, setSearch] = React.useState("");

  return (
    <div style={{ padding: 16 }}>
      <Grid container wrap='nowrap' style={{ height: '100%' }} spacing={1}>
        <Grid item xs={3}>
          <SearchContainer handleSearch={setSearch} />
          <StrategyList search={search} selected={selected} setSelected={setSelected} />
        </Grid>
        <Grid item xs={9}>
          <StrategyChart selected={selected} />
        </Grid>
      </Grid>
    </div>
  )
}

export default StrategyDashboard;