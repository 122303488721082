import React from "react";
import { Grid } from "@material-ui/core";
import { path } from "ramda";

import { IndicatorContainer } from "components/indicators/common/indicatorContainer";
import { IndicatorDescriptionItem } from "components/indicators/common/indicatorDescriptionItem";
import { useTranslate } from "shared/components/translate";

export const IndicatorView = ({ children, data }) => {
  const translate = useTranslate();

  return (
    <IndicatorContainer>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <IndicatorDescriptionItem title={translate("indicators.views.description")}>
            {data.name}
          </IndicatorDescriptionItem>
        </Grid>
        <Grid item xs={12}>
          <IndicatorDescriptionItem title={translate("indicators.views.period")}>
            {data.period.description}
          </IndicatorDescriptionItem>
        </Grid>
        <Grid item xs={12}>
          <IndicatorDescriptionItem title={translate("indicators.views.unit")}>
            {translate(`enums.units.${data.unit.name}`)}
          </IndicatorDescriptionItem>
        </Grid>
        <Grid item xs={12}>
          <IndicatorDescriptionItem title={translate("indicators.views.plannedValue")}>
            {data.plannedValue}
          </IndicatorDescriptionItem>
        </Grid>
        <Grid item xs={12}>
          <IndicatorDescriptionItem title={translate("indicators.views.case")}>
            {data.case.description}
          </IndicatorDescriptionItem>
        </Grid>
        <Grid item xs={12}>
          <IndicatorDescriptionItem title={translate("indicators.views.assignTo")}>
            {path(["assignTo", "firstName"], data)}{" "}
            {path(["assignTo", "lastName"], data)}
          </IndicatorDescriptionItem>
        </Grid>
        <Grid item xs={12}>
          <IndicatorDescriptionItem title={translate("indicators.views.createdBy")}>
            {path(["createdBy", "firstName"], data)}{" "}
            {path(["createdBy", "lastName"], data)}
          </IndicatorDescriptionItem>
        </Grid>
        <Grid item xs={12}>
          <IndicatorDescriptionItem title={translate("indicators.views.groupIndicator")}>
            {path(["parentIndicator", "name"], data)}
          </IndicatorDescriptionItem>
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </IndicatorContainer>
  );
};
