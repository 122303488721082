import React from "react";
import { Route, Switch } from "react-router-dom";

import { GroupIndicator } from "components/indicators/detailView/group";
import { StrategyIndicator } from "components/indicators/detailView/strategy";
import { EmployeeIndicator } from "components/indicators/detailView/employee";
import { VisionIndicator } from "components/indicators/detailView/vision";
import { MissionIndicator } from "components/indicators/detailView/mission";
import IndicatorContainer from 'components/indicators';

export default () => {
  return (
    <IndicatorContainer>
      <Switch>
        <Route
          path={`/strategy/employeeIndicator/:id`}
          component={EmployeeIndicator}
        />
        <Route
          path={`/strategy/groupIndicator/:id`}
          component={GroupIndicator}
        />
        <Route
          path={`/strategy/strategy/:id`}
          component={StrategyIndicator}
        />
        <Route
          path={`/strategy/vision/:id`}
          component={VisionIndicator}
        />
        <Route
          path={`/strategy/mission/:id`}
          component={MissionIndicator}
        />
      </Switch>
    </IndicatorContainer>
  )
}


