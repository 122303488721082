import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { Whatshot, Poll, Visibility, SupervisorAccount } from "@material-ui/icons";

import { useTranslate } from "shared/components/translate";
import Advantage from "./advantage";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("lg")]: {
      marginBottom: theme.spacing(8)
    }
  },
  titleContainer: {
    marginBottom: theme.spacing(8)
  }
}));

const Advantages = () => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Grid container justify="center">
      <Grid item xs={11} lg={9}>
        <Grid container>
          <Grid item xs={12} className={classes.titleContainer}>
            <Typography variant="h4" color="textPrimary">{translate("landingPage.advantages.title")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={4} xs={12} className={classes.cardContainer}>
                <Advantage
                  description={translate(
                    "landingPage.advantages.first.description"
                  )}
                  color="#313CDC"
                  title={translate("landingPage.advantages.first.title")}
                  icon={SupervisorAccount}
                />
              </Grid>
              <Grid item lg={4} xs={12} className={classes.cardContainer}>
                <Advantage
                  description={translate(
                    "landingPage.advantages.second.description"
                  )}
                  color="#F50057"
                  title={translate("landingPage.advantages.second.title")}
                  icon={Poll}
                />
              </Grid>
              <Grid item lg={4} xs={12} className={classes.cardContainer}>
                <Advantage
                  description={translate(
                    "landingPage.advantages.third.description"
                  )}
                  color="#ECC436"
                  title={translate("landingPage.advantages.third.title")}
                  icon={Visibility}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Advantages;
