import { combineReducers } from "redux";

import listReducer from "./list";
import filterReducer from "./filter";
import createNamedWrapperReducer from "../common/utils";
import tableReducer from "../common/tablePagination";

export * from "./operators";

export default combineReducers({
  list: listReducer,
  filter: filterReducer,
  table: createNamedWrapperReducer(tableReducer, "@PROBLEMS_TABLE"),
});
