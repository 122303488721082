import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Grid } from '@material-ui/core';

import { getFilterFrom, getFilterTill } from 'selectors/indicator.selector';

const getStartingDay = (val, period) => moment(val).startOf(period);
const getEndingDay = (val, period) => moment(val).endOf(period);

export const getWeeksArray = (
  from,
  till,
  period
) => {
  const firstDate = getStartingDay(from, period)
  const lastDate = getEndingDay(till, period);
  const weeks = Math.round(moment.duration(lastDate.valueOf() - firstDate.valueOf()).as(period));

  if (weeks <= 0) {
    return [0].map(week => moment(week, period).toJSON());
  }

  return Array
    .from(Array(Number.parseInt(weeks)).keys())
    .map(week => moment(firstDate).endOf(period).add(week, period).toJSON());
}

export const Timeline = ({ children, period }) => {
  const fromDate = useSelector(getFilterFrom);
  const tillDate = useSelector(getFilterTill);
  const weeks = getWeeksArray(fromDate, tillDate, period);

  return (
    <Grid container spacing={1}>
      {
        weeks.map(week => children({ week }))
      }
    </Grid>
  )
}
