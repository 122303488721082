import React from "react";
import { Card, CardHeader, CardContent, Grid, Typography } from "@material-ui/core";

const ChartWrapper = ({
  children,
  label,
  height = 250,
  minHeight = 250,
  withOverflow,
}) => {
  const styles = { height: height, minHeight };
  if (withOverflow) {
    styles.overflowY = "auto";
  }
  return (
    <Card elevation={0} style={{ ...styles, height: "100%" }}>
      <Grid container alignContent="stretch" style={{ height: "100%" }}>
        <Grid item xs={12}>
          <div style={{ padding: 16 }}>
            <Typography variant="subtitle1" color="textPrimary">{label}</Typography>

          </div>
        </Grid>
        <Grid item xs={12}>
          <CardContent style={{ height: "100%" }}>{children}</CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ChartWrapper;
