import React from "react";
import { List, ListSubheader, Button, Collapse, Grid } from "@material-ui/core";
import { useRouteMatch } from "react-router";
import { Add, Remove } from "@material-ui/icons";

import ListLoader from "./listLoader";

export const IndicatorListContainer = ({ title, children, fetchData }) => {
  const match = useRouteMatch();
  const [open, setOpen] = React.useState(true);

  return (
    <List
      subheader={
        <ListSubheader>
          <Grid
            container
            justify="space-between"
            onClick={() => setOpen(!open)}
            style={{ cursor: "pointer" }}
          >
            <Grid item>{title}</Grid>
            <Grid item>
              <Button variant="text" color="primary" size="small">
                {open ? <Remove /> : <Add />}
              </Button>
            </Grid>
          </Grid>
        </ListSubheader>
      }
    >
      <Collapse in={open} unmountOnExit={false}>
        <ListLoader fetchData={fetchData}>
          {({ data }) =>
            data.map((el) =>
              children({ data: el, selectedId: match.params.id })
            )
          }
        </ListLoader>
      </Collapse>
    </List>
  );
};
