import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import {
  deleteDepartmentCloseDialog,
  closeDialogAndClearActiveDepartment,
} from "reducers/ducks/department/delete";
import {
  isOpenDeleteDialog,
  getActiveDepartmentForDelete,
} from "selectors/department.select";
import { fetchData } from "reducers/ducks/department/table";
import { useTranslate } from "shared/components/translate";

const DeleteDialog = () => {
  const dispatch = useDispatch();
  const open = useSelector(isOpenDeleteDialog);
  const { name, id } = useSelector(getActiveDepartmentForDelete);
  const translate = useTranslate();

  return (
    <Dialog open={open}>
      <DialogTitle>{translate("departments.deleteDialog.title")}</DialogTitle>
      <DialogContent>{name}</DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(closeDialogAndClearActiveDepartment)}>
          {translate("common.cancel")}
        </Button>
        <Button
          onClick={() => {
            dispatch(deleteDepartmentCloseDialog(id)).then(() =>
              dispatch(fetchData)
            );
          }}
        >
          {translate("common.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
