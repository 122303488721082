import React from "react";
import { Route, Switch } from "react-router-dom";

import RoleGroup from 'components/roleGroup';
import CreateRoleGroup from 'components/roleGroup/create';
import EditRoleGroup from 'components/roleGroup/edit';

export default (props) => {
  return (
    <Switch>
      <Route
        path={`${props.match.url}/edit/:id`}
        component={EditRoleGroup} />
      <Route
        path={`${props.match.url}/create`}
        component={CreateRoleGroup} />
      <Route
        path={`${props.match.url}`}
        component={RoleGroup} />
    </Switch>
  )
}


