import React from 'react';
import ChartJs from 'chart.js';
import { useTheme, Card, Grid, Typography, CardContent } from '@material-ui/core';
import moment from 'moment';
import { SelectDepartmentIndicator } from 'shared/components/select-department-indicator';
import { getDepartmentIndicatorDashboard } from 'reducers/ducks/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import { path, pathOr } from 'ramda';
import { getDasboardFilter } from 'selectors/dashboard';
import { useTranslate } from 'shared/components/translate';

const formatLabel = (data, key) => {
  const indicatorLabel = pathOr('', ['employee', key, 0, 'name'], data);
  const first_name = pathOr('', ['employee', key, 0, 'first_name'], data);
  const last_name = pathOr('', ['employee', key, 0, 'last_name'], data);

  return indicatorLabel ? `${first_name} ${last_name}: ${indicatorLabel}` : null;
}

const CountProblem = ({ departmentId }) => {
  const ref = React.useRef();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [indicator, setIndicator] = React.useState();
  const [data, setData] = React.useState();
  const [chart, setChart] = React.useState();
  const filters = useSelector(getDasboardFilter)
  const translate = useTranslate();

  const handleSetIndicator = React.useCallback(val => {
    setIndicator(val);
    setData();
  }, [setData, setIndicator])

  React.useEffect(() => {
    if (indicator) {
      dispatch(getDepartmentIndicatorDashboard(departmentId, indicator.id))
        .then(response => setData(response.data))
    }
  }, [indicator, setData, filters])

  React.useEffect(() => {
    const chart = new ChartJs(ref.current, {
      type: 'line',
      options: {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              unit: 'week',
              tooltipFormat: 'YYYY-WW',
              displayFormats: {
                'millisecond': 'YYYY-WW',
                'second': 'YYYY-WW',
                'minute': 'YYYY-WW',
                'hour': 'YYYY-WW',
                'day': 'YYYY-WW',
                'week': 'YYYY-WW',
                'month': 'YYYY-WW',
                'quarter': 'YYYY-WW',
                'year': 'YYYY-WW',
              }
            },
          }]
        }
      }
    })

    setChart(chart);
  }, [setChart]);

  React.useEffect(() => {
    if (!!chart) {
      const customData = []
      if (path(['department', 0, 'name'], data)) {
        customData.push({
          label: pathOr(null, ['department', 0, 'name'], data),
          backgroundColor: theme.palette.primary.main,
          fill: theme.palette.primary.main,
          data: pathOr([], ['department'], data).map(d => ({
            x: moment(d.date, 'YYYY-WW'),
            y: Math.round(d.percentage)
          }))
        })
      }

      Object.keys(pathOr({}, ['employee'], data))
        .forEach(key => {
          customData.push({
            label: formatLabel(data, key),
            backgroundColor: theme.palette.secondary.main,
            fill: theme.palette.secondary.main,
            data: pathOr([], ['employee', key], data)
              .map(d => ({
                x: moment(d.date, 'YYYY-WW'),
                y: Math.round(d.percentage)
              }))
          })
        })
      chart.data.datasets = customData;
      chart.update();
    }
  }, [data])

  return (
    <Card elevation={0} style={{ height: "45vh", minHeight: "400px" }}>
      <CardContent style={{ height: "100%", padding: 16 }}>
        <Grid container alignContent='stretch' style={{ height: '100%' }}>
          <Grid item xs={12}>
            <Grid container justify='space-between'>
              <Grid item>
                <Typography variant="subtitle1" color="textPrimary" style={{ lineHeight: "1em" }}>{translate('dashboards.departmentCharts.departmentIndicators.title')}</Typography>
              </Grid>
              <Grid item xs={12} lg={5}>
                <SelectDepartmentIndicator isClearable departmentId={departmentId} onChange={handleSetIndicator} value={indicator} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <canvas ref={ref} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default CountProblem;