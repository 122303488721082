import { path } from 'ramda';

import { SET_GOALS } from 'reducers/ducks/department/goal';

const SET_DEPARTMENT_ROW = 'MYKPI/COMPANY_STRATEGY/DEPARTMENT_ROW';
const defaultState = {}

export const selectors = pt => {
  return {
    isOpen(name) {
      return path([...pt, name])
    },
  }
}

const formatName = goal => `${goal.strategicGoals}/${goal.department}`;

export default (state = defaultState, { type, payload, name } = {}) => {
  switch (type) {
    case SET_GOALS:
      return Object.values(payload)
        .reduce(
          (acc, goal) => {
            const goalName = formatName(goal)
            return {
              ...acc,
              [goalName]: state[goalName] || false
            }
          },
          {}
        )
    case SET_DEPARTMENT_ROW:
      return {
        ...state,
        [name]: payload
      }
    default:
      return state;
  }
}

export const openDepartmentRow = name => ({
  type: SET_DEPARTMENT_ROW,
  payload: true,
  name
});

export const closeDepartmentRow = name => ({
  type: SET_DEPARTMENT_ROW,
  payload: false,
  name
});

export const toggleOpenDepartmentRow = (name, open) => ({
  type: SET_DEPARTMENT_ROW,
  payload: !open,
  name
});