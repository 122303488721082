import React from "react";
import { useTranslate } from "shared/components/translate";
import { List, ListItem } from "@material-ui/core";

import InstructorCard from "./instructorCard";
import Rokas from "assets/img/rokasBig.jpg";

const RokasCv = () => {
  const translate = useTranslate();

  return (
    <InstructorCard
      name="Rokas Milovanovas"
      imageUrl={Rokas}
      aboutMe={
        <>
          {translate("landingPage.cv.rokas.aboutMe")}
          <List>
            <ListItem>{translate("landingPage.cv.rokas.aboutMeL1")}</ListItem>
            <ListItem>{translate("landingPage.cv.rokas.aboutMeL2")}</ListItem>
            <ListItem>{translate("landingPage.cv.rokas.aboutMeL3")}</ListItem>
          </List>
        </>
      }
      earasOfExperiance={
        <List>
          <ListItem>
            {translate("landingPage.cv.rokas.earasOfExperianceL1")}
          </ListItem>
          <ListItem>
            {translate("landingPage.cv.rokas.earasOfExperianceL2")}
          </ListItem>
          <ListItem>
            {translate("landingPage.cv.rokas.earasOfExperianceL3")}
          </ListItem>
        </List>
      }
      leadsPrograms={
        <List>
          <ListItem>
            {translate("landingPage.cv.rokas.leadsProgramsL1")}
          </ListItem>
          <ListItem>
            {translate("landingPage.cv.rokas.leadsProgramsL2")}
          </ListItem>
          <ListItem>
            {translate("landingPage.cv.rokas.leadsProgramsL3")}
          </ListItem>
          <ListItem>
            {translate("landingPage.cv.rokas.leadsProgramsL4")}
          </ListItem>
          <ListItem>
            {translate("landingPage.cv.rokas.leadsProgramsL5")}
          </ListItem>
          <ListItem>
            {translate("landingPage.cv.rokas.leadsProgramsL6")}
          </ListItem>
        </List>
      }
      email="rokas@letslean.lt"
      phone="+370 686 92737"
      linkedIn="https://www.linkedin.com/in/rokas-milovanovas-0bbb8b150/"
    />
  );
};

export default RokasCv;
