import React from "react";
import { Route, Switch } from "react-router-dom";
import { Grid } from '@material-ui/core';

import DetailView from 'components/problems/detailView';
import CommentContainer from 'components/problems/comments';
import InitialProblemContainer from 'components/problems/initialProblemContainer';
import TableComponent from 'components/problems/table';

const ConnectedContainer = ({ url }) => (
  <Switch>
    <Route path={`${url}/problem/:id`}>
      {
        ({ match }) => (
          <Grid container>
            <Grid item xs={12}>
              <DetailView id={match.params.id} />
            </Grid>
            <Grid item xs={12}>
              <CommentContainer id={match.params.id} />
            </Grid>
          </Grid>
        )
      }
    </Route>
  </Switch>
);

export default (props) => {
  return (
    <Switch>
      <Route
        path={props.match.url}
      >
        <InitialProblemContainer>
          <TableComponent>
            <Switch>
              <Route path={`${props.match.url}/problem/:id`}>
                <ConnectedContainer url={props.match.url} />
              </Route>
            </Switch>
          </TableComponent>
        </InitialProblemContainer>
      </Route>
    </Switch>
  )
}


