import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, CircularProgress, Typography, Divider } from '@material-ui/core';
import { Formik, Form } from 'formik';

import Comment from './comment';
import EnterComment from './enterComment';
import { getProblemComments, addProblemComment } from 'reducers/ducks/problems/comments';
import Loader from 'shared/components/loader';
import { useTranslate } from 'shared/components/translate';

const CommentsContainer = ({ id }) => {
  const dispatch = useDispatch();
  const [comments, setComments] = useState([]);
  const [fetching, setFetching] = useState(false);
  const translate = useTranslate();

  React.useEffect(() => {
    setFetching(true);
    dispatch(getProblemComments(id))
      .then(response => {
        setComments(response.data)
      })
      .finally(() => {
        setFetching(false);
      });
  }, [dispatch, id, setComments, setFetching]);

  if (fetching) {
    return (
      <Loader />
    )
  }

  return (
    <Grid container>
      {
        comments.length !== 0 && (
          <Grid item xs={12}>
            <div style={{ padding: '16px 16px 0 16px' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography color='primary' variant='subtitle1'>{translate("problems.comments.comments")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {
                      comments.map(comment => (
                        <Grid item xs={12} key={comment.id}>
                          <Comment comment={comment} />
                        </Grid>
                      ))
                    }
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider variant='fullWidth' />
                </Grid>
              </Grid>
            </div>
          </Grid>
        )
      }

      <Grid item xs={12}>
        <Formik
          onSubmit={(values, { setValues }) => {
            dispatch(addProblemComment(id, values))
              .then(() => {
                setValues({});
                setFetching(true);
                dispatch(getProblemComments(id))
                  .then(response => {
                    setComments(response.data)
                  })
                  .finally(() => {
                    setFetching(false);
                  });
              });
          }}
        >
          <Form>
            <EnterComment />
          </Form>
        </Formik>
      </Grid>
    </Grid>
  )
}


export default CommentsContainer