import { createRequestMessageHandler } from "shared/utils/httpRequestMessageHandler";

const getCommentsMessageHandler = createRequestMessageHandler([
  {
    title: "Nepavyko gauti komentarų",
    predicate() {
      return true;
    }
  }
]);

export const getProblemComments = id => (dispatch, _, { api }) => {
  return api
    .get(`/private/problems/problem/${id}/comments`)
    .catch(error => {
      dispatch(getCommentsMessageHandler(error));

      throw error;
    });
}

const addCommentsMessageHandler = createRequestMessageHandler([
  {
    title: "Nepavyko pridėti komentaro",
    predicate() {
      return true;
    }
  }
]);

export const addProblemComment = (id, comment) => (dispatch, _, { api }) => {
  return api
    .post(`/private/problems/problem/${id}/comment`, comment)
    .catch(error => {
      dispatch(addCommentsMessageHandler(error));

      throw error;
    });
}