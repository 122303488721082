import React from 'react';
import { useDispatch } from 'react-redux';
import { path } from 'ramda';

import { Select } from 'shared/components/select';
import { createField } from './selectField';
import { findUserIndicators } from 'reducers/ducks/indicator/findIndicator';
import { useTranslate } from './translate';

export const SelectUserIndicator = ({ userId, ...props }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const translate = useTranslate();

  React.useEffect(() => {
    setLoading(true);

    dispatch(findUserIndicators(userId)(search))
      .then(response => setOptions(response.data))
      .finally(() => setLoading(false));

  }, [dispatch, userId, search, setLoading, setOptions]);


  return (
    <Select
      {...props}
      // defaultOptions
      inputValue={search}
      isDisabled={!userId}
      isLoading={loading}
      options={options}
      placeholder={translate("common.select")}
      onInputChange={setSearch}
      getOptionValue={path(["id"])}
      getOptionLabel={path(["name"])}
      noOptionsMessage={() => translate("common.noRecords")}
      menuPlacement='bottom'
      menuPosition='fixed'
    />
  );
}

export default createField(SelectUserIndicator)