import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { getEmployeeDashboard } from "reducers/ducks/dashboard";
import CountUniqueStatus from "./countUniquestatus";
import Acknowledgments from "./acknowledgments";
import IndicatorComplete from "./indicatorComplete";
import UserIndicators from "./indicator";
import { getDasboardFilter } from "selectors/dashboard";
import { useTranslate } from "shared/components/translate";
import InfoChartWrapper from "components/dashboards/infoChartWrapper";

const Charts = ({ user }) => {
  const [data, setData] = React.useState({});
  const dispatch = useDispatch();
  const filters = useSelector(getDasboardFilter);
  const translate = useTranslate();

  React.useEffect(() => {
    dispatch(getEmployeeDashboard(user.id)).then((response) => {
      setData(response.data);
    });
  }, [dispatch, filters]);

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div style={{ height: 48 }}>
            <Grid container alignItems="center" className="h-100">
              <Grid item>
                <Typography
                  variant="subtitle1"
                  color="primary"
                >{`${user.firstName} ${user.lastName}`}</Typography>

              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={4}>
              <InfoChartWrapper percentage={data.indicatorComplete != null && `${data.indicatorComplete}%`} label={translate('dashboards.employeeCharts.indicatorFulfillment.title')}>
                <IndicatorComplete data={data.indicatorComplete} />
              </InfoChartWrapper>
            </Grid>
            <Grid item xs={12} lg={4}>
              <InfoChartWrapper label={translate('dashboards.employeeCharts.problemsByStatus.title')}>
                <CountUniqueStatus data={data.uniqueProblemStatus} />
              </InfoChartWrapper>
            </Grid>
            <Grid item xs={12} lg={4}>
              <InfoChartWrapper label={translate('dashboards.employeeCharts.acknowledgment.title')} height="45vh" minHeight="400px">
                <Acknowledgments data={data.acknowledgments} />
              </InfoChartWrapper>
            </Grid>
            <Grid item xs={12}>
              <UserIndicators userId={user.id} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Charts;
