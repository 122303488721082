import React from "react";
import {
  Grid,
  Typography,
} from "@material-ui/core";
import styles from "./index.module.css";


const PageHeader = (props) => {
  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={styles.container}
    >
      <Grid item>
        <Typography noWrap color="textSecondary" variant="h5">
          {props.title}
        </Typography>
      </Grid>
      {props.children && (
        <Grid item xs="auto">
          <Grid container justify="flex-end" spacing={1}>
            {props.children}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default PageHeader;
