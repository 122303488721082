import React from "react";
import { useMatchMobile } from "shared/hooks/responsive";

import Mobile from "./mobile";
import Desktop from "./desktop";

const Indicators = ({ children }) => {
  const isMobile = useMatchMobile();

  if (isMobile) {
    return <Mobile>{children}</Mobile>;
  }

  return <Desktop>{children}</Desktop>;
};

export default Indicators;
