import React from "react";
import { useDispatch } from "react-redux";
import { path } from "ramda";

import { createField } from "./selectField";
import { Select } from "shared/components/select";

const fetchDepartments = () => (_, __, { api }) => {
  return api
    .get("/private/department/list")
    .then((response) => response.data)
    .catch(() => []);
};

const SelectDepartment = (props) => {
  const dispatch = useDispatch();
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    dispatch(fetchDepartments())
      .then((data) => setOptions(data))
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, setOptions]);

  return (
    <Select
      {...props}
      isLoading={loading}
      options={options}
      getOptionValue={path(["id"])}
      getOptionLabel={path(["name"])}
      menuPlacement="bottom"
      menuPosition="fixed"
    />
  );
};

export default createField(SelectDepartment);
