import React from 'react';

import CreateEmployeeIndicator from './employee';
import CreateGroupIndicator from './group';
import CreateStrategy from './strategic';
import CreateVision from './vision';

export const CreateDialogs = ({ type, children, handleClose, ...props }) => {
  switch (type) {
    case 'employeeIndicator':
      return <CreateEmployeeIndicator open={true} handleClose={handleClose} />
    case 'groupIndicator':
      return <CreateGroupIndicator open={true} handleClose={handleClose} />
    case 'strategic':
      return <CreateStrategy open={true} handleClose={handleClose} />
    case 'vision':
      return <CreateVision open={true} handleClose={handleClose} />
    default:
      return null;
  }
}