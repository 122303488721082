import React from "react";
import { Route, Switch } from "react-router-dom";

import DepartmentList from 'components/department';
import CreateDepartment from 'components/department/create';
import EditDepartment from 'components/department/edit';

export default (props) => {
  return (
    <Switch>
      <Route
        path={`${props.match.url}/edit/:id`}
        component={EditDepartment} />
      <Route
        path={`${props.match.url}/create`}
        component={CreateDepartment} />
      <Route
        path={`${props.match.url}`}
        component={DepartmentList} />
    </Switch>
  )
}


