import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography, Button, Divider } from '@material-ui/core';
import { Form, Formik, Field } from 'formik';

import { getCurrentUser } from '../../../selectors/user.selector';
import InputField from '../../../shared/components/input';
import { withTranslate } from '../../../shared/components/translate';
import AuthInfo from '../form/authInfo';
import { securityValidationSchema, baseValidationSchema } from '../form/validation';
import { changeOwnPassword, changeOwnProfile } from '../../../services/users.service';
import { fetchCurrentUser } from '../../../services/currentUser.service';

const Profile = ({ translate }) => {
  const user = useSelector(getCurrentUser);
  const dispatch = useDispatch();

  return (
    <div style={{ padding: 16 }}>


      <Grid container spacing={2}>

        <Grid item xs={12}>
          <Formik
            enableReinitialize
            validationSchema={baseValidationSchema}
            initialValues={user}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);

              dispatch(changeOwnProfile(values))
                .then(() => dispatch(fetchCurrentUser))
                .finally(() => setSubmitting(false));
            }}
          >
            <Form>
              <Grid container spacing={1} alignItems='center'>
                <Grid item xs={12}>
                  <Typography variant='subtitle2'>
                    {translate("users.form.basic.title")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    fullWidth
                    required
                    disabled
                    label={translate("users.form.basic.email")}
                    component={InputField}
                    type='email'
                    name='email'
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    fullWidth
                    required
                    label={translate("users.form.basic.firstName")}
                    component={InputField}
                    name='firstName'
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    fullWidth
                    required
                    label={translate("users.form.basic.lastName")}
                    component={InputField}
                    name='lastName'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type='submit'
                    color='secondary'
                    variant='outlined'
                  >
                    {translate("users.form.updateInfo")}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Grid>
        <Grid item xs={12}>
          <Divider variant='fullWidth' />
        </Grid>
        <Grid item xs={12}>
          <Formik
            validationSchema={securityValidationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);

              dispatch(changeOwnPassword(values))
                .then(() => resetForm({}))
                .finally(() => setSubmitting(false));
            }}
          >
            <Form>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <AuthInfo />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type='submit'
                    color='secondary'
                    variant='outlined'
                  >
                    {translate("users.form.changePassword")}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Grid>
      </Grid>
    </div>
  )
}

export default withTranslate(Profile);