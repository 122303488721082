import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useTranslate } from "shared/components/translate";

const PriceText = ({ sliderValue, priceTags }) => {
  const translate = useTranslate();

  return (
    <Grid container justify="center" alignItems="flex-end">
      <Grid item>
        <Typography
          component="span"
          color="primary"
          style={{
            fontSize: 72,
            fontWeight: "bold",
            lineHeight: 0,
          }}
        >
          {priceTags[sliderValue].monthPrice}
        </Typography>
        <Typography component="span" style={{ fontSize: 12 }}>
          {translate("landingPage.price.perMonth")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="center">
          <Grid item>
            <Typography
              component="span"
              color="secondary"
              align="center"
              style={{ fontSize: 12, fontWeight: "bold" }}
            >
              {priceTags[sliderValue].label}
            </Typography>
            <Typography
              align="center"
              component="span"
              style={{ fontSize: 12 }}
            >
              {" "}
              {translate("landingPage.price.users")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PriceText;
