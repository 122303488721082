import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import LanguageSelector from "shared/components/languageSelector";

const Navigation = () => {
  return (
    <div className="navigation" style={{ color: "white", paddingTop: 16 }}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={2}>
          <Typography variant="h5" color="inherit">
            <Link
              style={{ textDecoration: "unset", color: "inherit" }}
              to="/home"
            >
              myKPI.eu
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={1} wrap="nowrap" justify="flex-end">
            <Grid item>
              <LanguageSelector />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Navigation;
