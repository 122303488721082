import React from "react";
import { Route, Switch } from "react-router-dom";

import MainLayout from "../shared/layouts/main-layout";
import Problems from "../components/problems/index";
import UserRoutes from "./users.routes";
import IndicatorRoute from "./indicator.routes";
import AcknowledgmentRoute from "./acknowledgment.routes";
import DepartmentRoute from "./department.routes";
import Profile from "../components/users/profile";
import StrategyRoutes from "./strategy.routes";
import RoleGroupRoutes from "./roleGroup.routes";
import TemplateRoutes from "./template.routes";
import ProblemRoutes from "./problem.routes";

import { ROLES } from "common/roles";
import { withRbacAccess } from "common/rbac";
import Dashboard from "components/dashboards";

export default (props) => (
  <MainLayout>
    <Switch>
      <Route
        path="/problems"
        // component={withRbacAccess(ProblemRoutes, ROLES.PROBLEM["problem:list"])} />
        component={ProblemRoutes}
      />
      <Route path="/acknowledgment" component={AcknowledgmentRoute} />
      {/* component={withRbacAccess(AcknowledgmentRoute, ROLES.ACKNOWLEDGMENT["acknowledgment:list"])} /> */}
      <Route path="/profile" component={Profile} />
      <Route path="/department" component={DepartmentRoute} />
      {/* component={withRbacAccess(DepartmentRoute, ROLES.DEPARTMENT["department:list"])} /> */}
      <Route path="/users" component={UserRoutes} />
      {/* component={withRbacAccess(UserRoutes, ROLES.USER["user:list"])} /> */}
      <Route path="/roleGroup" component={RoleGroupRoutes} />
      {/* component={withRbacAccess(RoleGroupRoutes, ROLES.ROLE_GROUP["roleGroup:list"])} /> */}
      <Route path="/template" component={TemplateRoutes} />
      {/* component={withRbacAccess(TemplateRoutes, ROLES.TEMPLATES.READ)} /> */}
      <Route
        path={`/strategy`}
        component={IndicatorRoute}
        // component={withRbacAccess(IndicatorRoute, ROLES.INDICATOR["indicator:list"])}
      />
      <Route path="/" exact component={Dashboard} />
    </Switch>
  </MainLayout>
);
