import React from "react";
import { Grid } from "@material-ui/core";

import Employee from "./list";
import SearchContainer from "./list/searchContainer";
import EmployeeCharts from "./charts";

const EmployeeDashboard = () => {
  const [selected, setSelected] = React.useState({});
  const [search, setSearch] = React.useState("");

  return (
    <div style={{ padding: 16 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={3}>
          <div>
            <SearchContainer handleSearch={setSearch} />
            <Employee
              search={search}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={9}>
          <div>
            <EmployeeCharts selected={selected} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default EmployeeDashboard;
