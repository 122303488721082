import React from "react";
import { Field } from "formik";
import { Grid } from "@material-ui/core";
import * as Yup from "yup";

import InputField from "shared/components/input";
import { withTranslate } from "shared/components/translate";

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
});

const Mission = ({ translate }) => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Field
        required
        fullWidth
        name="name"
        multiline
        rows={4}
        label={translate("indicators.form.missionDescription")}
        component={InputField}
      />
    </Grid>
  </Grid>
);

export default withTranslate(Mission);
