import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Field } from 'formik';

import InputField from '../../../shared/components/input';
import { withTranslate } from '../../../shared/components/translate';

const AuthInfo = ({ translate }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant='subtitle2'>
          {translate("users.form.auth.title")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Field
          fullWidth
          required
          label={translate("users.form.auth.password")}
          component={InputField}
          autoComplete='new-password'
          type='password'
          name='password'
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Field
          fullWidth
          required
          component={InputField}
          label={translate("users.form.auth.repeatedPassword")}
          autoComplete='new-password'
          type='password'
          name='repeatedPassword'
        />
      </Grid>
    </Grid>
  )
}

export default withTranslate(AuthInfo);