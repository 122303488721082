import React from "react";
import { Formik } from "formik";
import { Button, DialogContent, DialogActions } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { path } from "ramda";

import { withTranslate } from "../../shared/components/translate";
import { createValidationSchema } from "./validation";
import ProblemForm from "./form";
import { createProblem } from "reducers/ducks/problems";
import { fetchProblemsTable } from "reducers/ducks/problems/table";

const CreateProblem = ({ handleClose, translate, initialValues }) => {
  const dispatch = useDispatch();

  return (
    <Formik
      enableReinitialize
      validationSchema={createValidationSchema}
      initialValues={initialValues || {}}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        Promise.all(
          values.assignTo?.map((assignTo) =>
            dispatch(
              createProblem({
                ...values,
                assignTo,
              })
            )
          )
        )
          .then(() => {
            handleClose();
          })
          .then(() => dispatch(fetchProblemsTable()))
          .catch((err) => {
            const errors = path(["response", "data", "errors"], err);
            if (errors) actions.setErrors(errors);
          })
          .finally(() => actions.setSubmitting(false));
      }}
      render={(props) => {
        return (
          <form onSubmit={props.handleSubmit}>
            <DialogContent dividers>
              <ProblemForm
                multiAssignees
                values={props.values}
                setValue={props.setFieldValue}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
                disabled={props.isSubmitting}
              >
                {translate("common.cancel")}
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!props.isValid || props.isSubmitting}
              >
                {translate("common.create")}
                {props.values.assignTo?.length > 1
                  ? ` - ${props.values.assignTo?.length}`
                  : ""}
              </Button>
            </DialogActions>
          </form>
        );
      }}
    />
  );
};

export default withTranslate(CreateProblem);
