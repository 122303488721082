import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import moment from 'moment';
import { Grid } from '@material-ui/core';

import { DataFetcher } from 'shared/components/dataFetcher';
import { getFilterFrom, getFilterTill } from 'selectors/indicator.selector';
import { Record } from './common/recordContainer';
import { Timeline } from './common/timeline';
import { RecordVariantWrapper } from './common/recordVariant';
import { EditWrapper } from './common/recordEditWrapper';
import { CreateWrapper } from './common/recordCreateWrapper';
import { listRecords } from 'reducers/ducks/records/listRecords';

const findRecordFromDate = (week, recordList = []) => {
  const mWeek = moment(week);

  return recordList.find(record => {
    const mRecord = moment(record.recordDate);

    return mRecord.week() === mWeek.week() && mRecord.year() === mWeek.year();
  })
}

export const IndicatorRecordsContainer = ({ cases, plannedValue, period }) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const from = useSelector(getFilterFrom);
  const till = useSelector(getFilterTill);
  return (
    <DataFetcher
      id={match.params.id}
      fetchData={() => dispatch(listRecords(match.params.id, from, till)).then(response => response ? response.data : [])}
    >
      {
        ({ data = [] }) => {
          return (
            <Timeline period={period.name}>
              {
                ({ week }) => {
                  const record = findRecordFromDate(week, data);

                  return (
                    <Grid
                      item
                      key={week}
                    >
                      <CreateWrapper record={record} comment={record?.comment} indicatorId={match.params.id} week={week} value={record ? record.value : undefined}>
                        <EditWrapper record={record} value={record ? record.value : undefined}>
                          <RecordVariantWrapper
                            week={week}
                            cases={cases.name}
                            plannedValue={plannedValue}
                            value={record ? record.value : undefined}
                            comment={record?.comment}
                          >
                            {
                              ({ variant }) => (
                                <Record
                                  variant={variant}
                                  date={week}
                                  title={record ? record.value : undefined}
                                />
                              )
                            }
                          </RecordVariantWrapper>
                        </EditWrapper>
                      </CreateWrapper>
                    </Grid>
                  )
                }
              }
            </Timeline>
          )
        }
      }
    </DataFetcher>
  )
}