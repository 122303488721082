import React from "react";
import { TableBody, TableRow, TableCell } from "@material-ui/core";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { compose } from "ramda";

import { getTableData, isFetchingTableData } from "selectors/department.select";
import { withTranslate } from "shared/components/translate";
import { openDeleteDialogSetActive } from "reducers/ducks/department/delete";
import MoreButton from "shared/components/moreButton";
import { WithRbac } from "common/rbac";
import { ROLES } from "common/roles";

const DepartmentBody = ({ translate, history }) => {
  const departments = useSelector(getTableData);
  const fetching = useSelector(isFetchingTableData);
  const dispatch = useDispatch();

  const handleClick = (department) => (event) => {
    switch (event) {
      case "delete":
        return dispatch(openDeleteDialogSetActive(department));
      default:
        return history.push(`/department/edit/${department.id}`);
    }
  };

  if (!fetching && departments.length === 0) {
    return (
      <TableBody>
        <TableRow>
          <TableCell size="small" align="center" colSpan={2}>
            {translate("common.noRecords")}
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {departments.map((department) => (
        <TableRow key={department.id}>
          <TableCell size="small">{department.name}</TableCell>
          <WithRbac action={ROLES["DEPARTMENT-UPDATE"]}>
            <TableCell size="small">
              <MoreButton
                main={{ title: translate("common.edit"), event: "edit" }}
                options={[
                  {
                    title: translate("common.delete"),
                    event: "delete",
                    action: ROLES["DEPARTMENT-DELETE"],
                  },
                ]}
                handleClick={handleClick(department)}
              />
            </TableCell>
          </WithRbac>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default compose(withTranslate, withRouter)(DepartmentBody);
