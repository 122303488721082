import React from "react";
import { Form, Field } from "formik";
import { Grid } from "@material-ui/core";

import TextField from "shared/components/input";
import { useTranslate } from "shared/components/translate";

const DepartmentForm = ({ children }) => {
  const translate = useTranslate();

  return (
    <Form>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={4}>
          <Field
            required
            fullWidth
            name="name"
            label={translate("departments.form.name")}
            component={TextField}
          />
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Form>
  );
};

export default DepartmentForm;
