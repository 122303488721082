import { stringify } from 'qs';

import fetchReducer, { createSetData, createSetFetching } from 'reducers/ducks/common/fetch';
import paginationReducer from 'reducers/ducks/common/pagination';
import { createRequestMessageHandler } from 'shared/utils/httpRequestMessageHandler';
import { getFormattedFilterFields } from 'selectors/problem.selector';
import { combineReducers } from 'redux';
import createNamedWrapperReducer from 'reducers/ducks/common/utils';
import { NAME as paginationName, setPaginationFromResponse } from './pagination';
import { selectors as paginationSelectors } from './pagination';

export const NAME = 'FETCH_PROBLEMS_LIST';

export const setData = createSetData(NAME);
export const setFetching = createSetFetching(NAME);
export const startFetching = setFetching(true);
export const stopFetching = setFetching(false);

export default combineReducers({
  problems: createNamedWrapperReducer(fetchReducer, NAME),
  pagination: createNamedWrapperReducer(paginationReducer, paginationName)
})

// Operators

const messageHandler = createRequestMessageHandler();

export const fetchProblems = query => (dispatch, _, { api }) => {
  return api
    .get(`/private/problems/list?${query}`)
    .catch(error => {
      dispatch(messageHandler(error));

      throw error;
    })
}

export const fetchProblemsToStore = () => dispatch => {
  dispatch(startFetching);
  const filterParams = dispatch(getFilterParams)

  return dispatch(fetchProblems(filterParams))
    .then(response => {
      dispatch(setData(response.data.rows))
      // dispatch(setPaginationFromResponse(response.data.rows))
    })
    .finally(() => {
      dispatch(stopFetching);
    })
}

export const getFilterParams = (_, getState) => {
  const filterParams = getFormattedFilterFields(getState());
  const pagination = paginationSelectors.getAll(getState());

  return stringify({
    ...filterParams,
    ...pagination
  })
}