import React from 'react';
import { path, assoc, compose } from 'ramda';
import moment from 'moment';

import { Date } from 'shared/components/date';

export const formatNewProblem = problem => compose(
  assoc('status', path(['status', 'value'], problem)),
)(problem);

const isDateBefore = (date) => {
  return date && moment().isBefore(moment(date))
}

export const getFirstUntilDate = (problem) => {
  if (isDateBefore(problem?.firstDate?.date)) {
    return <Date date={problem?.firstDate?.date} format="YYYY-MM-DD" />
  }

  if (isDateBefore(problem?.secondDate?.date)) {
    return <Date date={problem?.secondDate?.date} format="YYYY-MM-DD" />
  }

  if (isDateBefore(problem?.thirdDate?.date)) {
    return <Date date={problem?.thirdDate?.date} format="YYYY-MM-DD" />
  }

  return null;
}

export const getLastUntilDate = (problem) => {
  if (!problem?.endDate) {
    return null
  }

  return <Date date={problem?.endDate} format="YYYY-MM-DD" />
}
