import React from 'react';
import ChartJs from 'chart.js';
import { useTheme, Card, Grid, Typography, CardContent } from '@material-ui/core';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { path, pathOr } from 'ramda';
import { useTranslate } from 'shared/components/translate';

const CountProblem = ({ data }) => {
    const ref = React.useRef();
    const theme = useTheme();
    const [chart, setChart] = React.useState();
    const translate = useTranslate();

    React.useEffect(() => {
        const chart = new ChartJs(ref.current, {
            type: 'line',
            options: {
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                    xAxes: [{
                        type: 'time',
                        time: {
                            unit: 'week',
                            tooltipFormat: 'YYYY-WW',
                            displayFormats: {
                                'millisecond': 'YYYY-WW',
                                'second': 'YYYY-WW',
                                'minute': 'YYYY-WW',
                                'hour': 'YYYY-WW',
                                'day': 'YYYY-WW',
                                'week': 'YYYY-WW',
                                'month': 'YYYY-WW',
                                'quarter': 'YYYY-WW',
                                'year': 'YYYY-WW',
                            }
                        },
                    }]
                }
            }
        })

        setChart(chart);
    }, [setChart]);

    React.useEffect(() => {
        if (!!chart) {
            chart.data.datasets = [
                {
                    label: pathOr('', ['strategy', 0, 'name'], data),
                    backgroundColor: theme.palette.primary.main,
                    fill: theme.palette.primary.main,
                    data: pathOr([], ['strategy'], data).map(d => ({
                        x: moment(d.date, 'YYYY-WW'),
                        y: Math.round(d.percentage)
                    }))
                },
                ...Object.keys(pathOr({}, ['department'], data))
                    .map(key => ({
                        label: pathOr('', ['department', key, 0, 'name'], data),
                        backgroundColor: theme.palette.secondary.main,
                        fill: theme.palette.secondary.main,
                        data: pathOr([], ['department', key], data)
                            .map(d => ({
                                x: moment(d.date, 'YYYY-WW'),
                                y: Math.round(d.percentage)
                            }))
                    }))
            ];
            chart.update();
        }
    }, [data])

    return (
        <Card elevation={0} style={{ height: "45vh", minHeight: "400px" }}>
            <CardContent style={{ height: "100%", padding: 16 }}>
                <Grid container alignContent='stretch' direction='row' style={{ height: '100%' }}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" color="textPrimary">{translate("dashboards.strategyCharts.strategyIndicator.title")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <canvas ref={ref} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default CountProblem;