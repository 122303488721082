import React from "react";
import { useMatchMobile } from "shared/hooks/responsive";

import Mobile from "./mobile";
import Desktop from "./desktop";

const DepartmentDashboard = () => {
  const isMobile = useMatchMobile();

  if (isMobile) {
    return <Mobile />;
  }

  return <Desktop />;
};

export default DepartmentDashboard;
