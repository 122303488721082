import React from 'react';
import { Paper, Grid, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { path, compose } from 'ramda';
import { withRouter } from 'react-router-dom';

import PageHeader from 'shared/components/pageHeader';
import { withTranslate } from 'shared/components/translate';
import { createTemplate } from 'reducers/ducks/template';
import TemplateForm from '../form';
// import validationSchema from '../form/validateSchema';

const CreateDepartment = ({ translate, history }) => {
  const dispatch = useDispatch();

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader title={translate("template.create.title")} />
      </Grid>
      <Grid item xs={12}>
        <div style={{ padding: 16 }}>
          <Paper style={{ padding: 16 }}>
            <Formik
              // validationSchema={validationSchema}
              onSubmit={(value, { setSubmitting, setErrors }) => {
                setSubmitting(true);
                dispatch(createTemplate(value))
                  .then(() => {
                    history.push('/template');
                  })
                  .catch((error = {}) => {
                    const errors = path(['response', 'data', 'errors'], error)
                    setErrors(errors);
                  })
                  .finally(() => setSubmitting(false));
              }}
              render={(props) => (
                <Form {...props} >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TemplateForm />
                    </Grid>
                    <Grid item onClick={() => history.push('/template')}>
                      <Button>{translate('common.cancel')}</Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        variant='contained'
                        type='submit'
                        disabled={props.isSubmitting || !props.isValid}
                      >
                        {translate('common.create')}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            />
          </Paper>
        </div>
      </Grid>
    </Grid>
  )
}

export default compose(
  withRouter,
  withTranslate
)(CreateDepartment);