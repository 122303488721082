import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { withTranslate } from 'shared/components/translate';
import { isOpenVisionDialog, getCompanyVision } from 'selectors/strategy.selector';
import Vision, { visionValidationSchema } from '../forms/vision';
import { closeDialog, saveCompanyVision, fetchAndSaveCompanyVision } from 'reducers/ducks/vision';

const CreateStrategicGoalDialog = ({ translate, ...props }) => {
  const dispatch = useDispatch();
  const open = useSelector(isOpenVisionDialog);
  const vision = useSelector(getCompanyVision);
  const handleCloseRecord = () => dispatch(closeDialog)

  return (
    <Dialog fullWidth open={open} onClose={handleCloseRecord}>
      <DialogTitle>{translate("indicators.recordForm.edit")}</DialogTitle>
      <Formik
        enableReinitialize
        initialValues={{ vision }}
        validationSchema={visionValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          dispatch(saveCompanyVision(values))
            .then(() => dispatch(fetchAndSaveCompanyVision))
            .then(() => handleCloseRecord())
            .finally(() => setSubmitting(false));
        }}
        render={formProps => (
          <Form>
            <DialogContent>
              <Vision />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseRecord}>{translate("common.cancel")}</Button>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                disabled={!formProps.isValid || formProps.isSubmitting}
              >
                {translate("common.edit")}
              </Button>
            </DialogActions>
          </Form>
        )}
      />
    </Dialog>
  )
}

export default withTranslate(CreateStrategicGoalDialog);