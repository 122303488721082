import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

import { useTranslate } from "shared/components/translate";
import { Map, Email, Phone, AccessTime } from "@material-ui/icons";
import ContactsInfo from "./contactsInfo";

const Footer = () => {
  const translate = useTranslate();

  return (
    <Grid
      container
      style={{ backgroundColor: "#4A4A4A", minHeight: 312, color: "white" }}
      justify="center"
    >
      <Grid item xs={11} lg={9}>
        <Grid container style={{ margin: "92px 0" }}>
          <Grid item xs={12} lg={4}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ fontSize: 19 }}>
                  {translate("landingPage.footer.aboutUs")}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={9}>
                <Typography variant="body1" align="justify">
                  {translate("landingPage.footer.aboutUsText")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ fontSize: 19 }}>
                  {translate("landingPage.footer.contacts")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ContactsInfo
                  icon={<Map />}
                  label={translate("landingPage.footer.street")}
                />
              </Grid>
              <Grid item xs={12}>
                <ContactsInfo
                  icon={<Email />}
                  label={
                    <a
                      style={{ color: "inherit", textDecoration: "unset" }}
                      href="mailto:info@mykpi.eu"
                    >
                      info@mykpi.eu
                    </a>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <ContactsInfo
                  icon={<Phone />}
                  label={
                    <Grid container spacing={1}>
                      <Grid item xs={12} lg={6}>
                        <a
                          style={{ color: "inherit", textDecoration: "unset" }}
                          href="tel:+370 607 94278"
                        >
                          +370 607 94278
                      </a>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <a
                          style={{ color: "inherit", textDecoration: "unset" }}
                          href="tel:+370 686 92737"
                        >
                          +370 686 92737
                      </a>
                      </Grid>
                    </Grid>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <ContactsInfo icon={<AccessTime />} label="I-V: 8:00 - 17:00" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ fontSize: 19 }}>
                  {translate("landingPage.footer.details")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">{translate("landingPage.footer.uab")} „Efektyvi vadyba“</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {translate("landingPage.footer.companyCode")}: 304640043
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {translate("landingPage.footer.pvmCode")}: LT100011525212
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {translate("landingPage.footer.as")}: LT217044060008179342
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  component={Link}
                  to="/home/privacy-policy"
                  variant="subtitle1"
                  style={{
                    fontSize: 19,
                    textDecoration: "unset",
                    color: "inherit",
                  }}
                >
                  {translate("landingPage.privacyPolicy.title")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
