import React from "react";

import { useMatchMobile, useMatchPhone } from "shared/hooks/responsive";
import LandingPhotoMobile from "./landingPhoto.mobile";
import LandingPhotoDesktop from "./landingPhoto.desktop";

const LandingPhotoSwitch = () => {
  const isMobile = useMatchPhone();

  if (isMobile) {
    return <LandingPhotoMobile />;
  }

  return <LandingPhotoDesktop />;
};

export default LandingPhotoSwitch;
