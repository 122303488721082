import React from 'react';

export const createNoContentContainer = (NoContentComponent = null) => ({ noContent, children }) => {
  if (noContent) {
    return NoContentComponent ? <NoContentComponent /> : null
  }

  return children
}

export default createNoContentContainer();