import React from 'react';
import { Menu } from '@material-ui/icons';
import { TextField } from '@material-ui/core';

import { useTranslate } from 'shared/components/translate';

const getInputLabel = (props) => {
  if (!props.isMulti) {
    return props.value ? props.getOptionLabel(props.value) : '';
  }

  if (Array.isArray(props.value)) {
    return props.value.map(opt => props.getOptionLabel(opt)).join(', ');
  }

  return '';
}

const DialogInput = ({ options, setOpen, ...props }) => {
  const translate = useTranslate();

  return (<TextField
    label=" "
    fullWidth
    InputProps={{ readOnly: true, endAdornment: <Menu color="primary" />, style: { cursor: "pointer" } }}
    InputLabelProps={{ shrink: true }}
    placeholder={translate("common.select")}
    onClick={() => setOpen(true)}
    value={getInputLabel(props)}
  />);
}

export default DialogInput