import React from 'react';
import { without, compose, assoc } from 'ramda';
import { Snackbar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

export const NAME = 'notifications';

export const ACTION_TYPES = {
  DISPLAY_MESSAGE: `${NAME}/DISPLAY_MESSAGE`,
  DELETE_MESSAGE: `${NAME}/DELETE_MESSAGE`,
};

const defaultState = {
  messages: [],
}

export const reducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case ACTION_TYPES.DISPLAY_MESSAGE:
      return assoc('messages', [...state.messages, payload], state);
    case ACTION_TYPES.DELETE_MESSAGE:
      return assoc('messages', without([payload.message], state.messages), state);
    default:
      return state;
  }
};

export const selectors = {
  messages: state => state[NAME].messages,
};

export const actions = {
  displayMessage: (type, text) => ({
    type: ACTION_TYPES.DISPLAY_MESSAGE,
    payload: {
      type,
      text,
    },
  }),
  deleteMessage: (message) => ({
    type: ACTION_TYPES.DELETE_MESSAGE,
    payload: { message },
  }),
};

class NotificationClass extends React.Component {
  handleRequestClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.props.onRequestClose(this.props.message);
  }

  render() {
    const { message, classes } = this.props;
    return (
      <Snackbar
        open
        classes={{ root: classes.root }}
        message={message.text}
        autoHideDuration={3e3}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        onClose={this.handleRequestClose}
        onClick={this.handleRequestClose}
      />
    );
  }
}

const notificationStyles = {
  root: {
    marginTop: '10vh',
  },
};

export const Notification = compose(
  connect(
    undefined,
    dispatch => ({
      onRequestClose(message) {
        dispatch(actions.deleteMessage(message));
      },
    }),
  ),
  withStyles(notificationStyles),
)(NotificationClass);

const NotificationListClass = ({ messages }) => (
  <div>
    {messages.map((message, index) => (
      <Notification message={message} key={index} />
    ))}
  </div>
);

export const NotificationList = connect((state) => ({
  messages: selectors.messages(state),
}))(NotificationListClass);
