import React from 'react';
import ChartJs from 'chart.js';
import { useTheme } from '@material-ui/core';
import moment from 'moment';
import { path } from 'ramda';
import { useTranslate } from 'shared/components/translate';

const CountProblem = ({ vision = [], strategic = {} }) => {
  const ref = React.useRef();
  const theme = useTheme();
  const [chart, setChart] = React.useState();
  const translate = useTranslate();

  React.useEffect(() => {
    const chart = new ChartJs(ref.current, {
      type: 'line',
      options: {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              unit: 'week',
              tooltipFormat: 'YYYY-WW',
              displayFormats: {
                'millisecond': 'YYYY-WW',
                'second': 'YYYY-WW',
                'minute': 'YYYY-WW',
                'hour': 'YYYY-WW',
                'day': 'YYYY-WW',
                'week': 'YYYY-WW',
                'month': 'YYYY-WW',
                'quarter': 'YYYY-WW',
                'year': 'YYYY-WW',
              }
            },
          }]
        }
      }
    });

    setChart(chart);
  }, [setChart]);

  React.useEffect(() => {
    if (!!chart) {
      chart.data.datasets = [
        {
          label: translate("dashboards.companyCharts.vision.visionIndicators"),
          backgroundColor: theme.palette.primary.main,
          fill: theme.palette.primary.main,
          data: vision.map(d => ({
            x: moment(d.date, 'YYYY-WW'),
            y: Math.round(d.percentage)
          }))
        },
        ...Object.keys(strategic).map(key => {
          const indicators = strategic[key];

          return {
            label: path([key, 0, 'name'], strategic),
            backgroundColor: theme.palette.secondary.main,
            fill: theme.palette.secondary.main,
            data: indicators.map(d => ({
              x: moment(d.date, 'YYYY-WW'),
              y: Math.round(d.percentage)
            }))
          }
        })

      ]

      chart.update();
    }
  }, [vision, strategic]);

  return <canvas ref={ref} />
}

export default CountProblem;