import React from 'react';
import { pathEq } from 'ramda';

import { Select } from 'shared/components/select';
import { createField } from 'shared/components/selectField';

const options = [
  { value: 'lte', label: 'Mažiau lygu' },
  { value: 'gte', label: 'Daugiau lygu' },
]

export const SelectCase = (props) => (
  <Select
    {...props}
    options={options}
    onChange={val => props.onChange(val.value)}
    value={options.find(pathEq(['value'], props.value))}
    menuPlacement='bottom'
    menuPosition='fixed' />
);

export default createField(SelectCase)