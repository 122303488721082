import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { withTranslate } from 'shared/components/translate';
import { isOpenMissionDialog, getCompanyMission } from 'selectors/strategy.selector';
import Mission, { missionValidationSchema } from '../forms/mission';
import { closeDialog, saveFetchedMission, saveCompanyMission, } from 'reducers/ducks/mission';

const CreateStrategicGoalDialog = ({ translate, ...props }) => {
  const dispatch = useDispatch();
  const open = useSelector(isOpenMissionDialog);
  const mission = useSelector(getCompanyMission);
  const handleCloseRecord = () => dispatch(closeDialog)

  return (
    <Dialog fullWidth open={open} onClose={handleCloseRecord}>
      <DialogTitle>Redaguoti įmonės misiją</DialogTitle>
      <Formik
        enableReinitialize
        initialValues={{ mission }}
        validationSchema={missionValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          dispatch(saveCompanyMission(values))
            .then(() => dispatch(saveFetchedMission))
            .then(() => handleCloseRecord())
            .finally(() => setSubmitting(false));
        }}
        render={formProps => (
          <Form>
            <DialogContent>
              <Mission />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseRecord}>{translate("common.cancel")}</Button>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                disabled={!formProps.isValid || formProps.isSubmitting}
              >
                {translate("common.edit")}
              </Button>
            </DialogActions>
          </Form>
        )}
      />
    </Dialog>
  )
}

export default withTranslate(CreateStrategicGoalDialog);