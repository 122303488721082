import { actions as notificationActions } from 'shared/components/notifications';

const SET_ACTIVE_DEPARTMENT = 'MYKPI/DELETE_DEPARTMENT/SET_ACTIVE_DEPARTMENT';
const SET_OPEN = 'MYKPI/DELETE_DEPARTMENT/SET_OPEN';

const defaultState = {
  department: null,
  open: false,
}

export default (state = defaultState, { type, payload } = {}) => {
  switch (type) {
    case SET_OPEN:
      return { ...state, open: payload };
    case SET_ACTIVE_DEPARTMENT:
      return { ...state, department: payload };
    default:
      return state;
  }
}

const setActiveDepartment = department => ({
  type: SET_ACTIVE_DEPARTMENT,
  payload: department
});

const setOpen = payload => ({
  type: SET_OPEN,
  payload
});

export const openDialog = setOpen(true);
export const closeDialog = setOpen(false);

export const closeDialogAndClearActiveDepartment = dispatch => {
  dispatch(closeDialog);
  dispatch(setActiveDepartment(null));
}

// Operators
export const openDeleteDialogSetActive = department => (dispatch) => {
  dispatch(openDialog);
  dispatch(setActiveDepartment(department));
}

export const deleteDepartment = id => (dispatch, getState, { api }) => {
  return api.delete(`/private/department/id/${id}`)
    .then(response => {
      dispatch(notificationActions.displayMessage('success', 'Sėkmingai panaikinta grupė'))
      return response;
    })
    .catch(err => {
      dispatch(notificationActions.displayMessage('error', 'Nepavyko panaikinti skyriaus'))

      throw err;
    })
}

export const deleteDepartmentCloseDialog = id => (dispatch) => {
  return dispatch(deleteDepartment(id))
    .then(() => dispatch(closeDialog))
}