import React from "react";
import { Route, Switch } from "react-router-dom";

import company from '../components/company';

export default (props) => {
  return (
    <Switch>
      <Route
        path={props.match.url}
        component={company} />
    </Switch>
  )
}


