import { getAllSearchFields } from "selectors/indicator.selector"
import qs from 'qs';

const formatFields = (fields = {}) => {
  const query = {};
  if (fields.search) {
    query.search = fields.search
  }
  if (fields.assignedTo) {
    query.assignTo = fields.assignedTo.map(user => user.id)
  }
  if (fields.department) {
    query.department = fields.department.map(user => user.id)
  }
  if (fields.createdBy) {
    query.createdBy = fields.createdBy.map(user => user.id)
  }
  if (fields.strategic) {
    query.strategic = fields.strategic.map(strategy => strategy.id)
  }
  if (fields.departmentGoal) {
    query.departmentGoal = fields.departmentGoal.map(strategy => strategy.id)
  }
  if (fields.createdAt) {
    query.createdAt = fields.createdAt
  }

  return qs.stringify(query);
}

const createListIndicator = (type) => () => (_, getState, { api }) => {
  const fields = getAllSearchFields(getState())

  return api.get(`/private/indicator/${type}/list?${formatFields(fields)}`)
}

export const listEmployeeIndicators = createListIndicator('employee')
export const lisGroupIndicators = createListIndicator('group')
export const listStrategicIndicators = createListIndicator('strategic')
export const listVisionIndicators = () => (_, __, { api }) => {
  return api.get('/private/indicator/vision/list')
}
export const getMission = (_, __, { api }) => {
  return api.get(`/private/mission/get`)
}