import React from "react";
import {
  Card,
  CardMedia,
  CardActionArea,
  makeStyles,
  CardActions,
  Button,
  CardContent,
  Typography,
  Paper,
  Grid,
} from "@material-ui/core";
import { useTranslate } from "shared/components/translate";
import { Link, useLocation, useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    margin: "0 16px 8px 0",
    height: "100%",
  },
  media: {
    height: 250,
    borderRadius: 4,
  },
});

const BlogCard = ({ photo, photoTitle, title, link, description}) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Grid container>
      <Grid item xs={12} md={5} style={{ maxWidth: 390 }}>
        <img src={photo} title={photoTitle} className={classes.media} />
      </Grid>
      <Grid item xs={12} md={7}>
        <Grid container alignContent="space-between" direction="column" wrap="nowrap" className="h-100">
          <Grid item xs={11} style={{ flexGrow: 10}}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  style={{ fontWeight: "bold" }}
                >
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" color="textPrimary">
                  {translate(description)}...
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Button variant="text" color="primary" component={Link} to={link}>
              {translate("landingPage.blogPost.more")}...
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BlogCard;
