import React from "react";
import { Grid } from "@material-ui/core";

import Landing from "./landing";
import Advantages from "./advantages";
import ContactUs from "./contactUs";
import Footer from "./footer";
import BlogPosts from "./blog";
import CvCards from "./cv";
import Price from "./price";
import Guide from "./guide";

const LandingPage = () => {
  return (
    <Grid container style={{ backgroundColor: "#F4F7F8" }}>
      <Grid item xs={12}>
        <Landing />
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 92 }}>
        <Advantages />
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 92 }}>
        <Guide />
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 92 }}>
        <CvCards />
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 92 }}>
        <Price />
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 92 }}>
        <BlogPosts />
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 92 }}>
        <ContactUs />
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 92 }}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default LandingPage;
