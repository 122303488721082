import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { withTranslate } from 'shared/components/translate';
import { closeDialogAndClearData } from 'reducers/ducks/template/deleteDialog';
import { deleteTemplateDialog } from 'selectors/template.selectors';
import { deleteTemplate, fetchTemplatesAndStore } from 'reducers/ducks/template';

const DeleteDepartmentGoalDialog = ({ translate }) => {
  const dispatch = useDispatch();
  const open = useSelector(deleteTemplateDialog.isOpen);
  const data = useSelector(deleteTemplateDialog.getData) || {};

  const handleClose = () => dispatch(closeDialogAndClearData())

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>{translate("template.delete.title")}</DialogTitle>
      <DialogContent>
        {translate("template.delete.name")}: {data.name}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{translate("common.cancel")}</Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            dispatch(deleteTemplate(data.id))
              .then(() => dispatch(fetchTemplatesAndStore))
              .then(() => handleClose())
          }}
        >
          {translate("common.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withTranslate(DeleteDepartmentGoalDialog);