import { history } from 'routes';

export const goTo = url => {
  if (history.location !== url) {
    history.push(url)
  }
}

export const replacePage = url => {
  history.replace(url)
}

export const reloadPage = () => {
  const path = history.location.pathname;
  history.replace(`/reload`);
  setTimeout(() => {
    history.replace(path);
  });
}