import { combineReducers } from "redux";
import refetcher, { createSetNewId } from '../common/refetcher';
import createNamedWrapperReducer from '../common/utils';

import filterReducer from './filterFields';
import recordDateRangeReducer from './recordDateRange';

export default combineReducers({
  recordDateRange: recordDateRangeReducer,
  filter: combineReducers({
    fields: filterReducer,
    fetchId: createNamedWrapperReducer(refetcher, 'indicatorFilter'),
  }),
  fetchId: createNamedWrapperReducer(refetcher, 'indicatorId'),
});

export const fetchIndicatorFilter = createSetNewId('indicatorFilter');
export const setFetchIndicatorId = createSetNewId('indicatorId');

export const findIndicators = query => (_, __, { api }) => {
  return api.get(`/private/indicator/find`);
}