import React from "react";
import { useTranslate } from "shared/components/translate";
import { List, ListItem } from "@material-ui/core";

import InstructorCard from "./instructorCard";
import RytisJpg from "assets/img/Rytis.jpg";

const Rytis = () => {
  const translate = useTranslate();

  return (
    <InstructorCard
      name="Rytis Juozapavičius"
      imageUrl={RytisJpg}
      aboutMe={<>{translate("landingPage.cv.rytis.aboutMe")}</>}
      email="rytis@juozapavicius.com"
      linkedIn="http://linkedin.com/in/rytis-juozapavicius-4a986910"
    />
  );
};

export default Rytis;
