import React from "react";
import { Grid } from "@material-ui/core";

const ContactGrid = ({ icon, text }) => {
  return (
    <Grid container spacing={3} alignContent="center">
      <Grid item>{icon}</Grid>
      <Grid item>{text};</Grid>
    </Grid>
  );
};

export default ContactGrid;
