import React from 'react';
import { Field } from 'formik';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';
import { equals } from 'ramda';

import InputField from 'shared/components/input';
import { withTranslate } from 'shared/components/translate';
import SelectCase from 'components/indicators/form/select-case';
import selectUnit from 'components/indicators/form/select-unit';

function delocalize(currentValue, originalValue) {
  return parseFloat(originalValue.replace(',', '.'))
}

export const strategicGoalValidationSchema = Yup.object().shape({
  description: Yup.string().required(),
  plannedValue: Yup
    .number()
    .required()
    .when('unit', {
      is: equals('proc.'),
      then: Yup.number().min(0).max(100).transform(delocalize),
      otherwise: Yup.number().min(0).transform(delocalize),
    }),
  unit: Yup.string().required(),
  case: Yup.string().required(),
});

const StrategicGoal = ({ translate }) => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Field
        required
        fullWidth
        name='description'
        multiline
        rows={2}
        label={"Tikslo aprašymas"}
        component={InputField}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        required
        fullWidth
        name='plannedValue'
        label={'Planuojama pasiekti reikšmė'}
        component={InputField}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        required
        fullWidth
        name='unit'
        label={'Mato vienetas'}
        component={selectUnit}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        required
        fullWidth
        name='case'
        label={'Lyginimo sąlyga'}
        component={SelectCase}
      />
    </Grid>
  </Grid>
)

export default withTranslate(StrategicGoal)