import React from "react";
import { Grid, Typography, Link } from "@material-ui/core";

import { useTranslate } from "shared/components/translate";
import { LinkedIn, Phone, Mail } from "@material-ui/icons";
import ContactGrid from "../contactGrid";

const InstructorCard = ({
  name,
  aboutMe,
  earasOfExperiance,
  leadsPrograms,
  linkedIn,
  email,
  phone,
  imageUrl,
}) => {
  const translate = useTranslate();

  return (
    <Grid container spacing={1} wrap="nowrap">
      <Grid item>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <div
              style={{
                height: 500,
                width: "100%",
                maxWidth: "430px",
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {translate("landingPage.cv.contacts")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="body1" align="justify">
                      <ContactGrid
                        icon={<LinkedIn />}
                        text={
                          <Link
                            href={linkedIn}
                            target="_blank"
                            className="cv-link"
                          >
                            Linked in
                          </Link>
                        }
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" align="justify">
                      <ContactGrid
                        icon={<Mail />}
                        text={
                          <Link
                            href={`mailto:${email}`}
                            target="_blank"
                            className="cv-link"
                          >
                            {email}
                          </Link>
                        }
                      />
                    </Typography>
                  </Grid>
                  {phone && (
                    <Grid item xs={12}>
                      <Typography variant="body1" align="justify">
                        <ContactGrid
                          icon={<Phone />}
                          text={
                            <Link
                              href={`tel:${phone}`}
                              target="_blank "
                              className="cv-link"
                            >
                              {phone}
                            </Link>
                          }
                        />
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant="h4">{name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {translate("landingPage.cv.aboutMe")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" align="justify">
                  {aboutMe}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {earasOfExperiance && (
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {translate("landingPage.cv.earasOfExperiance")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" align="justify">
                    {earasOfExperiance}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {leadsPrograms && (
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {translate("landingPage.cv.leadsPrograms")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" align="justify">
                    {leadsPrograms}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InstructorCard;
