import React from 'react';
import { Grid } from '@material-ui/core';

import CompanyCharts from '../../charts';

const EmployeeDashboard = () => {

  return (
    <Grid container wrap='nowrap' style={{ height: '100%', marginBottom: 92 }}>
      <Grid item xs={12}>
        <div style={{ padding: 16 }}>
          <CompanyCharts />
        </div>
      </Grid>
    </Grid>
  )
}

export default EmployeeDashboard;