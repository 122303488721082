import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid, Button, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';

import SelectUser from 'shared/components/select-user';
import { useTranslate } from 'shared/components/translate';
import selectDepartment from 'shared/components/select-department';
import { getCurrentUser } from 'selectors/user.selector';
import { getAcknowledgmentsFilter } from 'selectors/acknowledgment.selector';

const FilterContainer = () => {
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const translate = useTranslate();
  const initialValues = useSelector(getAcknowledgmentsFilter);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        dispatch({
          type: 'acknowledgment/set_filter',
          payload: values
        });
      }}
    >
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <Field
                  fullWidth
                  isMulti
                  name='assignedTo'
                  query={{

                  }}
                  component={SelectUser}
                  label={translate("acknowledgment.filter.assignTo")}
                />
              </Grid>
              {
                !!user?.departmentManager?.length && (
                  <Grid item xs={12} md={3}>
                    <Field
                      fullWidth
                      isMulti
                      name='group'
                      component={selectDepartment}
                      label={translate("acknowledgment.filter.group")}
                    />
                  </Grid>
                )
              }
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item>
                <Button
                  variant='contained'
                  color='secondary'
                  type='submit'
                  size='small'
                >
                  <Search />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
}

export default FilterContainer;