import React, { useCallback, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import SideBar from "./sideBar";
import { fetchCurrentUser } from "services/currentUser.service";
import { logout } from "services/auth.service";
import LanguageSelector from "shared/components/languageSelector";
import { setLocale } from "reducers/ducks/user";
import { getActiveLanguage } from "react-localize-redux";
import { useSetLocaleIfNotSet } from "./hooks";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#F4F7F8",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    height: "100vh",
    width: "100vw",
    overflow: "auto",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerRoot: {
    zIndex: 0
  }
}));

export default function PersistentDrawerLeft(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  useSetLocaleIfNotSet();
  React.useEffect(() => {
    dispatch(fetchCurrentUser);
  }, []);

  function handleDrawerOpen() {
    setOpen(true);
  }

  const handleButtonDrawerClose = useCallback(() => {
    if (matches) {
      setOpen(false);
    }
  }, [setOpen, matches]);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open && !matches,
        })}
      >
        <Toolbar>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item>
              <Grid
                className="h-100"
                container
                wrap="nowrap"
                alignContent="center"
                alignItems="center"
              >
                <Grid item>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, open && classes.hide)}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography variant="h6" noWrap>
                    myKPI.eu
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs="auto">
              <Grid container alignItems="center" wrap="nowrap">
                <Grid item>
                  <LanguageSelector />
                </Grid>
                {!matches && (
                  <Grid item>
                    <IconButton
                      color="inherit"
                      onClick={() => {
                        dispatch(logout);
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant={matches ? "temporary" : "persistent"}
        anchor="left"
        open={open}
        classes={{
          root: classes.drawerRoot,
          paper: classes.drawerPaper,
        }}
        BackdropProps={{
          onClick: handleButtonDrawerClose,
        }}
      >
        <SideBar
          handleDrawerClose={handleDrawerClose}
          handleButtonDrawerClose={handleButtonDrawerClose}
        />
      </Drawer>
      <main
        style={{ width: "100%" }}
        className={clsx({
          [classes.content]: !matches,
          [classes.contentShift]: open && !matches,
        })}
      >
        <div className={classes.drawerHeader} />
        {props.children}
      </main>
    </div>
  );
}
