import React from 'react';
import { Grid, Typography, ListItem, List } from '@material-ui/core';
import { useTranslate } from 'shared/components/translate';
import Meetings from 'assets/img/meetings.jpg';
import ImageCard from './image';

const Post3 = () => {
  const translate = useTranslate();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">
          {translate("landingPage.blogPost.post3.title")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ImageCard image={Meetings} />
      </Grid>
      <Grid item xs={12}>
        <List>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l1")}</Typography></ListItem>
          <ListItem>
            <List>
              <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l1.1")}</Typography></ListItem>
              <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l1.2")}</Typography></ListItem>
              <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l1.3")}</Typography></ListItem>
            </List>
          </ListItem>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l2")}</Typography></ListItem>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l3")}</Typography></ListItem>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l4")}</Typography></ListItem>
          <ListItem>
            <List>
              <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l4.1")}</Typography></ListItem>
              <ListItem>
                <List>
                  <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l4.1.1")}</Typography></ListItem>
                  <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l4.1.2")}</Typography></ListItem>
                </List>
              </ListItem>
              <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l4.2")}</Typography></ListItem>
              <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l4.4")}</Typography></ListItem>
            </List>
          </ListItem>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l5")}</Typography></ListItem>
          <ListItem>
            <List>
              <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l5.1")}</Typography></ListItem>
              <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l5.2")}</Typography></ListItem>
              <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l5.3")}</Typography></ListItem>
              <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l5.4")}</Typography></ListItem>
            </List>
          </ListItem>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l6")}</Typography></ListItem>
          <ListItem>
            <List>
              <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l6.1")}</Typography></ListItem>
              <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l6.2")}</Typography></ListItem>
              <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l6.3")}</Typography></ListItem>
              <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l6.4")}</Typography></ListItem>
              <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l6.5")}</Typography></ListItem>
              <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l6.6")}</Typography></ListItem>
            </List>
          </ListItem>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l7")}</Typography></ListItem>
          <ListItem><Typography variant="body1">{translate("landingPage.blogPost.post3.l8")}</Typography></ListItem>
        </List>
      </Grid>
    </Grid>
  )
}

export default Post3;