import { createRequestMessageHandler } from "shared/utils/httpRequestMessageHandler";

const getProblemMessageHandler = createRequestMessageHandler();

export const getProblem = id => (dispatch, _, { api }) => {
  return api
    .get(`/private/problems/problem/${id}`)
    .catch(error => {
      dispatch(getProblemMessageHandler(error));

      throw error;
    })
}

const deleteProblemMessageHandler = createRequestMessageHandler([
  {
    title: { key: 'problems.delete.fail' },
    predicate() {
      return true;
    }
  }
]);

export const deleteProblem = id => (dispatch, _, { api }) => {
  return api
    .delete(`/private/problems/problem/${id}`)
    .catch(error => {
      dispatch(deleteProblemMessageHandler(error));

      throw error;
    });
}


const createProblemMessageHandler = createRequestMessageHandler([
  {
    title: { key: 'problems.create.fail' },
    predicate() {
      return true;
    }
  }
]);

export const createProblem = (problem) => (dispatch, _, { api }) => {
  return api
    .post(`/private/problems/create`, problem)
    .catch(error => {
      dispatch(createProblemMessageHandler(error));

      throw error;
    });
}

const editProblemMessageHandler = createRequestMessageHandler([
  {
    title: { key: 'problems.edit.fail' },
    predicate() {
      return true;
    }
  }
]);

export const editProblem = (id, problem) => (dispatch, _, { api }) => {
  return api
    .put(`/private/problems/problem/${id}`, problem)
    .catch(error => {
      dispatch(editProblemMessageHandler(error));

      throw error;
    });
}