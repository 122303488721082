import React from 'react';
import { Grid } from '@material-ui/core';
import { string } from 'yup';

import TextField from '../../shared/components/input';
import { withTranslate } from '../../shared/components/translate';
import { Field } from 'formik';

const UserInfo = ({ translate }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <Field
          fullWidth
          required
          label={translate("company.create.form.firstName")}
          name='firstName'
          component={TextField} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          fullWidth
          label={translate("company.create.form.lastName")}
          name='lastName'
          component={TextField} />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          required
          autoComplete='new-email'
          label={translate("company.create.form.email")}
          name='email'
          type='email'
          component={TextField} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          fullWidth
          required
          autoComplete='new-password'
          label={translate("company.create.form.password")}
          name='password'
          type='password'
          component={TextField} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          fullWidth
          required
          autoComplete='new-password'
          label={translate("company.create.form.repeatedPassword")}
          name='repeatedPassword'
          type='password'
          component={TextField} />
      </Grid>
    </Grid>
  )
}

export default withTranslate(UserInfo);