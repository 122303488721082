import React from 'react';
import { TextField } from '@material-ui/core';
import { path, split } from 'ramda';

import { withTranslate } from './translate';

const getPath = split('.');

const InputField = ({
  field,
  form: { touched, errors },
  translate,
  ...rest
}) => {
  const pt = getPath(field.name);
  const fieldTouched = path(pt, touched);
  const fieldErrors = path(pt, errors);

  return (
    <TextField
      InputLabelProps={{ shrink: true }}
      placeholder={translate("common.enter")}
      {...field}
      value={field.value || ''}
      error={fieldTouched && fieldErrors}
      {...rest}
      helperText={rest.helperText || (fieldTouched && fieldErrors ? translate(fieldErrors) : undefined)}
    />
  )
}

export default withTranslate(InputField);