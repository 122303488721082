import React from 'react';
import moment from 'moment'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  noWrap: {
    whiteSpace: 'nowrap'
  }
}))
export const Date = ({ date, format }) => {
  const classes = useStyles();

  if (!date) {
    return '-'
  }

  return (<span className={classes.noWrap}>{moment(date).format(format)}</span>);
}