import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Checkbox, Paper, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { getManagedDepartments } from 'reducers/ducks/department';

const Employee = ({ selected, setSelected, search }) => {
  const [data, setData] = React.useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getManagedDepartments)
      .then(response => setData(response.data))
  }, []);

  const handleToggle = (department) => {
    if (selected[department.id]) {
      return setSelected({
        ...selected,
        [department.id]: undefined,
      })
    }

    setSelected({
      ...selected,
      [department.id]: department,
    })
  };


  return (
    <Paper elevation={0} style={{ marginTop: 8 }}>
      <List dense>
        {
          data
            .filter(department => department && department.name && department.name.indexOf(search) >= 0)
            .map(department => {
              return (
                <ListItem key={department.id} dense button onClick={() => handleToggle(department)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={!!selected[department.id]}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': department.id }}
                    />
                  </ListItemIcon>
                  <ListItemText id={department.id} primary={department.name} />
                </ListItem>

              )
            })
        }
      </List>
    </Paper>

  )
}

export default Employee;