import React from "react";
import { useTranslate } from "shared/components/translate";
import { List, ListItem } from "@material-ui/core";

import InstructorCard from "./instructorCard";
import rimtautas from "assets/img/rimtautasBig.jpg";

const RimtautasCv = () => {
  const translate = useTranslate();

  return (
    <InstructorCard
      name="Rimtautas Dališanskis"
      imageUrl={rimtautas}
      aboutMe={translate("landingPage.cv.rimtautas.aboutMe")}
      earasOfExperiance={
        <List>
          <ListItem>
            {translate("landingPage.cv.rimtautas.earasOfExperianceL1")}
          </ListItem>
          <ListItem>
            {translate("landingPage.cv.rimtautas.earasOfExperianceL2")}
          </ListItem>
          <ListItem>
            {translate("landingPage.cv.rimtautas.earasOfExperianceL3")}
          </ListItem>
        </List>
      }
      leadsPrograms={
        <List>
          <ListItem>
            {translate("landingPage.cv.rimtautas.leadsProgramsL1")}
          </ListItem>
          <ListItem>
            {translate("landingPage.cv.rimtautas.leadsProgramsL2")}
          </ListItem>
          <ListItem>
            {translate("landingPage.cv.rimtautas.leadsProgramsL3")}
          </ListItem>
          <ListItem>
            {translate("landingPage.cv.rimtautas.leadsProgramsL4")}
          </ListItem>
          <ListItem>
            {translate("landingPage.cv.rimtautas.leadsProgramsL5")}
          </ListItem>
          <ListItem>
            {translate("landingPage.cv.rimtautas.leadsProgramsL6")}
          </ListItem>
          <ListItem>
            {translate("landingPage.cv.rimtautas.leadsProgramsL7")}
          </ListItem>
        </List>
      }
      email="rimtautas@letslean.lt"
      phone="+370 607 94278"
      linkedIn="https://www.linkedin.com/in/rimtautasdalisanskis/"
    />
  );
};

export default RimtautasCv;
