import React from "react";
import { useMatchMobile } from "shared/hooks/responsive";

import Mobile from "./mobile";
import Desktop from "./desktop";

const ProblemTable = (props) => {
  const isMobile = useMatchMobile();

  if (isMobile) {
    return <Mobile {...props} />;
  }

  return <Desktop {...props} />;
};

export default ProblemTable;
