import React from 'react';
import ChartJs from 'chart.js';
import { useTheme } from '@material-ui/core';
import { useTranslate } from 'shared/components/translate';

const CountProblem = ({ data }) => {
  const ref = React.useRef();
  const theme = useTheme();
  const [chart, setChart] = React.useState();
  const translate = useTranslate();
  React.useEffect(() => {
    const chart = new ChartJs(ref.current, {
      type: 'doughnut',
      options: {
        responsive: false,
        maintainAspectRatio: false,
        legend: false
      }
    });

    setChart(chart);
  }, []);

  React.useEffect(() => {
    if (chart) {
      chart.data = {
        labels: [translate('dashboards.employeeCharts.acknowledgment.received'), translate('dashboards.employeeCharts.acknowledgment.given')],
        datasets: data ? [{
          backgroundColor: [theme.palette.primary.main, theme.palette.secondary.main],
          data: [data.received || 0, data.given || 0]
        }] : []
      }

      chart.update()
    }
  }, [data])

  return <canvas ref={ref} style={{ width: '100%', height: '100%' }} />
}

export default CountProblem;