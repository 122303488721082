import React from 'react';
import { Grid, Button, Collapse, IconButton } from '@material-ui/core';
import StrategyList from './list';
import SearchContainer from './list/searchContainer';
import StrategyChart from './charts';
import { ArrowBack } from '@material-ui/icons';
import { useTranslate } from 'shared/components/translate';

const StrategyDashboard = () => {
  const [selected, setSelected] = React.useState({});
  const [search, setSearch] = React.useState("");
  const [showChart, setShowChart] = React.useState(false);
  const translate = useTranslate();

  return (
    <Grid container style={{ height: '100%' }}>
      <Grid item xs={12}>
        <Collapse in={!showChart}>
          <div style={{ padding: 16, height: '100%' }}>
            <SearchContainer handleSearch={setSearch} />
            <StrategyList search={search} selected={selected} setSelected={setSelected} />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={Object.values(selected)?.length === 0}
              onClick={() => setShowChart(true)}
            >
              {translate('common.show')}
            </Button>
          </div>
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={showChart}>
          <div style={{ padding: 16 }}>
            <IconButton color="primary" onClick={() => setShowChart(false)}>
              <ArrowBack />
            </IconButton>
            <StrategyChart selected={selected} />
          </div>
        </Collapse>
      </Grid>
    </Grid>
  )
}

export default StrategyDashboard;