import React from 'react';
import { DialogActions, DialogTitle, Dialog, Button, DialogContent, List, ListSubheader, ListItem } from '@material-ui/core';
import { path, map, compose, pathOr } from 'ramda';


const DependentsList = ({ dependents = [], title }) => {
  if (!dependents.length) {
    return null;
  }

  return (
    <List>
      <ListSubheader>{title}</ListSubheader>
      {
        dependents.map(indicator => <ListItem key={indicator.id}>{indicator.name || indicator.description}</ListItem>)
      }
    </List>
  )
}

export const DeleteAlert = ({ open, handleClose, handleDelete, dependents = {} }) => {
  return (
    <Dialog
      onClose={handleClose}
      open={open}
    >
      <DialogTitle>Ar tikrai norite panaikinti?</DialogTitle>
      <DialogContent>
        <DependentsList title="Kartu bus pašalinti šie grupių tikslai:" dependents={dependents.groups} />
        <DependentsList title="Kartu bus pašalinti šie darbuotojų tikslai:" dependents={dependents.employeeIndicators} />
        <DependentsList title="Kartu bus pašalinti šios darbuotojų problemos:" dependents={dependents.problems} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
        >
          Atšaukti
          </Button>
        <Button
          variant='contained'
          color="primary"
          onClick={() => {
            handleDelete()
              .then(() => {
                handleClose();
              });
          }}
        >
          Panaikinti
        </Button>
      </DialogActions>
    </Dialog>
  )
}