import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { Formik, Field, Form } from "formik";

import { useTranslate } from "shared/components/translate";
import TextField from "shared/components/input";
import { useDispatch } from "react-redux";
import { contactUs } from "reducers/ducks/landingPage";

const ContactUs = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  return (
    <Grid container justify="center" id="contactUs">
      <Grid item xs={11} lg={9}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4" color="textPrimary">
              {translate("landingPage.contactUs.title")}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={8} style={{ padding: "92px 0" }}>
            <Formik
              onSubmit={(vals, { resetForm }) => {
                dispatch(contactUs(vals)).then(() => resetForm({}));
              }}
            >
              <Form>
                <Grid container spacing={2} >
                  <Grid item xs={12} lg={6}>
                    <Field
                      name="name"
                      label={translate("landingPage.contactUs.name")}
                      component={TextField}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Field
                      name="email"
                      label={translate("landingPage.contactUs.email")}
                      component={TextField}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="message"
                      label={translate("landingPage.contactUs.message")}
                      component={TextField}
                      variant="outlined"
                      fullWidth
                      required
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justify="flex-star">
                      <Grid item>
                        <Button
                          variant="contained"
                          size="large"
                          color="primary"
                          type="submit"
                        >
                          {translate("common.send")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactUs;
