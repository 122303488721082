import React from "react";

import Desktop from "./desktop";
import Mobile from "./mobile";
import { useMatchMobile } from "shared/hooks/responsive";

const DashboardSwitch = () => {
  const isMobile = useMatchMobile();

  if (isMobile) {
    return <Mobile />;
  }

  return <Desktop />;
};

export default DashboardSwitch;
