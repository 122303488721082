import React from 'react';
import { path } from 'ramda';
import { useDispatch } from 'react-redux';

import { Select } from 'shared/components/select';
import { createField } from './selectField';
import { fetchRoleGroups } from 'reducers/ducks/roleGroup';
import { useTranslate } from './translate';

export const SelectRoleGroup = (props) => {
  const dispatch = useDispatch();
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const translate = useTranslate();

  React.useEffect(() => {
    setLoading(true);
    dispatch(fetchRoleGroups)
      .then(response => setOptions(response.data))
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, setOptions]);

  return (
    <Select
      {...props}
      isLoading={loading}
      options={options}
      getOptionValue={path(['id'])}
      getOptionLabel={opt => {
        if (!opt.company_id) {
          return translate(`enums.roleGroups.${opt.name}`)
        }

        return opt.description
      }}
      noOptionsMessage={() => translate("common.noRecords")}
      menuPlacement='bottom'
      menuPosition='fixed' />
  );
}

export default createField(SelectRoleGroup)