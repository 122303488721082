import React from "react";
import { Grid, ListItem, ListItemText } from "@material-ui/core";
import { useDispatch } from "react-redux";

import {
  lisGroupIndicators,
  listEmployeeIndicators,
  listStrategicIndicators,
} from "reducers/ducks/indicator/listIndicators";
import { goTo } from "shared/utils/goTo";
import { IndicatorListContainer } from "./indicatorListContainer";
import SearchContainer from "../filter/descriptionFilter";
import { fetchIndicatorFilter } from "reducers/ducks/indicator";
import { WithRbac } from "common/rbac";
import { ROLES } from "common/roles";
import Vision from "./vision";
import Mission from "./mission";
import { useTranslate } from "shared/components/translate";

const fetchCompanyStrat = (dispatch) =>
  dispatch(listStrategicIndicators()).then((response) => response.data);
const fetchDepartment = (dispatch) =>
  dispatch(lisGroupIndicators()).then((response) => response.data);
const fetchEmployeeIndicators = (dispatch) =>
  dispatch(listEmployeeIndicators()).then((response) => response.data);

export default ({ open }) => {
  const dispatch = useDispatch();
  const translate = useTranslate();

  return (
    <React.Fragment>
      <div
        style={{
          padding: 16,
          height: "100%",
          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Grid container direction="row">
          {!open && (
            <Grid item xs={12}>
              <SearchContainer
                handleSearch={() => dispatch(fetchIndicatorFilter())}
              />
            </Grid>
          )}
          <WithRbac
            action={[
              ROLES["INDICATOR-READ-VISION"],
              ROLES["INDICATOR-CREATE-VISION"],
            ]}
          >
            <Grid item xs={12}>
              <Vision />
            </Grid>
          </WithRbac>
          <WithRbac action={[ROLES["MISSION-READ"], ROLES["MISSION-CREATE"]]}>
            <Grid item xs={12}>
              <Mission />
            </Grid>
          </WithRbac>
          <WithRbac action={ROLES["INDICATOR-READ-STRATEGY"]}>
            <Grid item xs={12}>
              <IndicatorListContainer
                title={translate("indicators.lists.strategy")}
                fetchData={fetchCompanyStrat}
              >
                {({ data, selectedId }) => (
                  <ListItem
                    button
                    selected={data.id === selectedId}
                    onClick={() => goTo(`/strategy/strategy/${data.id}`)}
                  >
                    <ListItemText primary={data.name} />
                  </ListItem>
                )}
              </IndicatorListContainer>
            </Grid>
          </WithRbac>
          <WithRbac action={ROLES["INDICATOR-READ-DEPARTMENT"]}>
            <Grid item xs={12}>
              <IndicatorListContainer
                title={translate("indicators.lists.departments")}
                fetchData={fetchDepartment}
              >
                {({ data, selectedId }) => (
                  <ListItem
                    button
                    selected={data.id === selectedId}
                    onClick={() => goTo(`/strategy/groupIndicator/${data.id}`)}
                  >
                    <ListItemText primary={data.name} />
                  </ListItem>
                )}
              </IndicatorListContainer>
            </Grid>
          </WithRbac>
          <WithRbac action={ROLES["INDICATOR-READ-EMPLOYEE"]}>
            <Grid item xs={12}>
              <IndicatorListContainer
                title={translate("indicators.lists.employee")}
                fetchData={fetchEmployeeIndicators}
              >
                {({ data, selectedId }) => (
                  <ListItem
                    button
                    selected={data.id === selectedId}
                    onClick={() =>
                      goTo(`/strategy/employeeIndicator/${data.id}`)
                    }
                  >
                    <ListItemText primary={data.name} />
                  </ListItem>
                )}
              </IndicatorListContainer>
            </Grid>
          </WithRbac>
        </Grid>
      </div>
    </React.Fragment>
  );
};
