import { createSetDialogData, createSetOpenDialog } from 'reducers/ducks/common/dialog';

export const DELETE_GOAL_DIALOG = 'deleteGoalDialog';

const setOpenDialog = createSetOpenDialog(DELETE_GOAL_DIALOG);
export const setData = createSetDialogData(DELETE_GOAL_DIALOG);

export const openDialog = setOpenDialog(true);
export const closeDialog = setOpenDialog(false);

export const openDialogAndSetData = data => (dispatch) => {
  dispatch(setData(data));
  dispatch(openDialog);
}

export const closeDialogAndClearData = () => (dispatch) => {
  dispatch(setData(null));
  dispatch(closeDialog);
}
