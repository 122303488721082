import React from 'react';
import { Grid, Table, TableCell, TableHead, TableRow, TableBody, Button, Link, Card, CardContent } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import PageHeader from 'shared/components/pageHeader';
import { WithRbac } from 'common/rbac';
import { fetchTemplatesAndStore } from 'reducers/ducks/template';
import MoreButton from 'shared/components/moreButton';
import { ROLES } from 'common/roles';
import { goTo } from 'reducers/ducks/common/goTo';
import DeleteDialog from './delete';
import { openDialogAndSetData } from 'reducers/ducks/template/deleteDialog';
import { templateTableSelectors } from 'selectors/template.selectors';
import { useTranslate } from 'shared/components/translate';

const RoleGroupContainer = () => {
  const dispatch = useDispatch();
  const templates = useSelector(templateTableSelectors.getData);
  const translate = useTranslate();

  React.useEffect(() => {
    dispatch(fetchTemplatesAndStore)
  }, [dispatch]);

  const handleClick = (template) => (eventType) => {
    switch (eventType) {
      case 'delete':
        dispatch(openDialogAndSetData(template))
        break;
      default:
        return goTo(`/template/edit/${template.id}`);
    }
  }

  return (
    <Grid container>
      <DeleteDialog />
      <Grid item xs={12}>
        <PageHeader title={translate("template.title")}>
          <WithRbac action={ROLES["INDICATOR-TEMPLATE-CREATE"]}>
            <Link to='/template/create' component={RouterLink}>
              <Button
                variant='contained'
                color='primary'
              >
                {translate("template.create.action")}
              </Button>
            </Link>
          </WithRbac>
        </PageHeader>
      </Grid>
      <Grid item xs={12}>
        <div style={{ padding: 16 }}>
          <Card>
            <CardContent style={{ overflow: 'auto' }}>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>{translate("template.table.templateName")}</TableCell>
                    <TableCell>{translate("template.table.indicatorName")}</TableCell>
                    <WithRbac action={ROLES["INDICATOR-TEMPLATE-UPDATE"]}>
                      <TableCell>{translate("template.table.actions")}</TableCell>
                    </WithRbac>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    templates.map(template => (
                      <TableRow>
                        <TableCell>{template.templateName}</TableCell>
                        <TableCell>{template.name}</TableCell>
                        <WithRbac action={ROLES["INDICATOR-TEMPLATE-UPDATE"]}>
                          <TableCell>
                            <MoreButton
                              main={{
                                event: 'edit',
                                title: translate("common.edit")
                              }}
                              options={[
                                {
                                  event: 'delete',
                                  title: translate("common.delete"),
                                  action: ROLES["INDICATOR-TEMPLATE-DELETE"]
                                }
                              ]}
                              handleClick={handleClick(template)}
                            />
                          </TableCell>
                        </WithRbac>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </div>
      </Grid>
    </Grid>
  )
}

export default RoleGroupContainer;