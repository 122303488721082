import React from 'react';
import { Field } from 'formik';
import { Grid, Typography } from '@material-ui/core';

import TextField from 'shared/components/input';
import SelectDepartmentGoal from 'shared/components/select-departmentGoal';
import { withTranslate } from 'shared/components/translate';
import SelectUnit from 'shared/components/select-unit';
import SelectPeriod from 'shared/components/select-indicatorPeriod';
import SelectCase from 'shared/components/select-IndicatorCase';
import { ROLES } from 'common/roles';
import { WithRbac } from 'common/rbac';

const IndicatorForm = ({ translate }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant='subtitle2'>
          {translate("template.form.title")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          name='name'
          label={translate("indicators.form.name")}
          component={TextField}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          name='unit'
          label={translate("indicators.form.unit")}
          component={SelectUnit}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          name='plannedValue'
          label={translate("indicators.form.plannedValue")}
          component={TextField}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          name='period'
          label={translate("indicators.form.period")}
          component={SelectPeriod}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          name='case'
          label={translate("indicators.form.case")}
          component={SelectCase}
        />
      </Grid>
    </Grid>
  )
}

export default withTranslate(IndicatorForm);