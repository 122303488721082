import { assoc } from 'ramda';

import { getTranslate } from 'shared/components/translate';
import { actions as notificationActions } from 'shared/components/notifications';
import {
  CREATE_DIALOG,
  EDIT_DIALOG,
  EDIT_GOALid,
  FETCHING,
  OPENED_RECORD,
  RECORD_DIALOG,
  SET_STRATEGY
} from './actionTypes';

const defaultState = {
  strategies: {},
  isFetching: false,
  openCreateDialog: false,
  openEditDialog: false,
  editGoalId: null,
  openRecordDialog: false,
  openedRecordValue: null,
};

export default (state = defaultState, { type, payload } = {}) => {
  switch (type) {
    case SET_STRATEGY:
      return assoc('strategies', payload, state);
    case FETCHING:
      return assoc('isFetching', payload, state);
    case CREATE_DIALOG:
      return assoc('openCreateDialog', payload, state);
    case EDIT_DIALOG:
      return assoc('openEditDialog', payload, state);
    case EDIT_GOALid:
      return assoc('editGoalId', payload, state);
    case RECORD_DIALOG:
      return assoc('openRecordDialog', payload, state);
    case OPENED_RECORD:
      return assoc('openedRecordValue', payload, state);
    default:
      return state;
  }
}

// actions
export const setCompanyStrategy = (strategies = []) => ({
  type: SET_STRATEGY,
  payload: strategies.reduce((acc, elem) => {
    return {
      ...acc,
      [elem.id]: elem,
    }
  }, {})
});

export const setFetching = payload => ({
  type: FETCHING,
  payload
});

export const setOpenCreateDialog = payload => ({
  type: CREATE_DIALOG,
  payload
});

export const setOpenEditDialog = payload => ({
  type: EDIT_DIALOG,
  payload
});

export const setOpenRecordDialog = payload => ({
  type: RECORD_DIALOG,
  payload
});

export const setEditGoalId = payload => ({
  type: EDIT_GOALid,
  payload
});

export const setOpenedRecordValue = payload => ({
  type: OPENED_RECORD,
  payload
});

export const openCreateDialog = setOpenCreateDialog(true);
export const closeCreateDialog = setOpenCreateDialog(false);
export const openEditDialog = setOpenEditDialog(true);
export const closeEditDialog = setOpenEditDialog(false);
export const openRecordDialog = setOpenRecordDialog(true);
export const closeRecordDialog = setOpenRecordDialog(false);

// operators
export const fetchCompanyStrategy = (dispatch, getState, { api }) => {
  dispatch(setFetching(true));
  const translate = getTranslate(getState());

  return api.get('/private/strategicGoal/list')
    .catch(error => {
      dispatch(notificationActions.displayMessage(
        'error',
        translate('notifications.errors.get')
      ));

      throw error;
    })
    .finally(() => dispatch(setFetching(false)))
}

export const setFetchedCompanyStrategy = dispatch => {
  return dispatch(fetchCompanyStrategy)
    .then(response => {
      dispatch(setCompanyStrategy(response.data));

      return response;
    });
}

export const createGetCachedOrFetchCompanyStrategy = selector => (dispatch, getState) => {
  const companyStrategy = selector(getState());

  if (!!companyStrategy) {
    return Promise.resolve(companyStrategy);
  }

  return dispatch(setFetchedCompanyStrategy)
    .then(() => selector(getState()));
}

export const createCompanyStrategy = body => (dispatch, getState, { api }) => {
  const translate = getTranslate(getState());

  return api.post('/private/strategicGoal/create', body)
    .catch(err => {
      dispatch(notificationActions.displayMessage(
        'error',
        translate('notifications.errors.get')
      ));

      throw err;
    })
}

export const createAndFetchStrategy = body => dispatch => {
  return dispatch(createCompanyStrategy(body))
    .then(() => dispatch(setFetchedCompanyStrategy))
}

export const fetchStrategicGoal = id => (dispatch, getState, { api }) => {
  const translate = getTranslate(getState());

  return api.get(`/private/strategicGoal/goal/${id}`)
    .catch(error => {
      dispatch(notificationActions.displayMessage(
        'error',
        translate('notifications.errors.get')
      ));

      throw error;
    })
}

export const editStrategicGoal = (id, values) => (dispatch, getState, { api }) => {
  const translate = getTranslate(getState());

  return api.put(`/private/strategicGoal/goal/${id}`, values)
    .catch(error => {
      dispatch(notificationActions.displayMessage(
        'error',
        translate('notifications.errors.get')
      ));

      throw error;
    })
}

export const setStrategicGoalRecord = ({ id, ...rest } = {}) => (dispatch, getState, { api }) => {
  const translate = getTranslate(getState());

  return api.put(`/private/strategicGoal/${id}/record`, rest)
    .catch(error => {
      dispatch(notificationActions.displayMessage(
        'error',
        translate('notifications.errors.')
      ));

      throw error;
    });
}