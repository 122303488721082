import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid, Button } from '@material-ui/core';

import InputField from 'shared/components/input';
import selectDepartment from 'shared/components/select-department';
import { Search } from '@material-ui/icons';
import { useTranslate } from 'shared/components/translate';

const UserFilter = ({ onSubmit }) => {
    const translate = useTranslate();

    return (
        <Formik
            initialValues={{}}
            onSubmit={val => {
                const formattedval = { ...val };
                if (formattedval.department) {
                    formattedval.department = formattedval.department.map(dep => dep.id)
                }

                onSubmit(formattedval)
            }}
        >
            <Form>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={3}>
                        <Field
                            fullWidth
                            label={translate("users.table.firstName")}
                            name='firstName'
                            component={InputField}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Field
                            fullWidth
                            label={translate("users.table.lastName")}
                            name='lastName'
                            component={InputField}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Field
                            fullWidth
                            label={translate("users.table.email")}
                            name='email'
                            component={InputField}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Field
                            isMulti
                            isClearable
                            label={translate("users.table.department")}
                            name='department'
                            component={selectDepartment}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            size='small'
                            color='secondary'
                            type='submit'
                        >
                            <Search />
                        </Button>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    )
}

export default UserFilter;