import React from "react";
import {
  Router,
  Route,
  Switch,
} from "react-router-dom";
import { createBrowserHistory } from 'history';

import AuthRoutes from './auth.routes';
import landingPage from './landingPage';
import AuthGuard from './auth.guard';
import ProtectedRoute from './protected.routes';

export const history = createBrowserHistory();

export const AppRouter = () => (
  <Router history={history}>
    <Route path='/'>
      <Switch>
        <Route
          path='/auth'
          component={AuthRoutes}
        />
        <Route
          path='/home'
          component={landingPage}
        />
        <AuthGuard
          redirect='/home'
          path='/'
          component={ProtectedRoute} />
      </Switch>
    </Route>
  </Router>
);