import { path, pathOr } from 'ramda';
import { createSelector } from 'reselect'

import { selectors as dialogSelector } from 'reducers/ducks/common/dialog';
import { selectors as createStrategyTableSelectors } from 'reducers/ducks/strategy/table/strategy';
import { selectors as createDepartmentTableSelectors } from 'reducers/ducks/strategy/table/department';

export const getCompanyVision = path(['strategy', 'vision', 'text']);
export const getCompanyMission = path(['strategy', 'mission', 'text']);
export const isOpenVisionDialog = path(['strategy', 'vision', 'openDialog']);
export const isOpenMissionDialog = path(['strategy', 'mission', 'openDialog']);
export const getCompanyStrategies = pathOr({}, ['strategy', 'strategy', 'strategies']);
export const isCreateStrategicGoalDialogOpen = path(['strategy', 'strategy', 'openCreateDialog']);
export const isCreateRecordDialogOpen = path(['strategy', 'strategy', 'openRecordDialog']);
export const getOpenedRecordValue = path(['strategy', 'strategy', 'openedRecordValue']);
export const isEditStrategicGoalDialogOpen = path(['strategy', 'strategy', 'openEditDialog']);
export const getEditStrategicGoalId = path(['strategy', 'strategy', 'editGoalId']);

export const listCompanyStrategicGoals = createSelector(getCompanyStrategies, Object.values)

export const deleteStrategyGoalDialogSelectors = dialogSelector(['strategy', 'deleteGoal']);
export const strategyTableSelectors = createStrategyTableSelectors(['strategy', 'table', 'strategy']);
export const departmentTableSelectors = createDepartmentTableSelectors(['strategy', 'table', 'department']);