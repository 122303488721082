import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const ContactsInfo = ({ icon, label }) => {
  return (
    <Grid container spacing={1} wrap="nowrap">
      <Grid item>{icon}</Grid>
      <Grid item><Typography variant="body1">{label}</Typography></Grid>
    </Grid>
  )
}

export default ContactsInfo;