import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoaderContainer from 'shared/components/loaderContainer';
import NoContentContainer from 'shared/components/noContentContainer';
import { ErrorBoundary } from 'shared/components/errorBoundary';
import { fetchIndicatorId } from 'selectors/indicator.selector';

const ListLoader = ({ fetchData, children }) => {
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const fetchId = useSelector(fetchIndicatorId);
  React.useEffect(() => {
    setFetching(true);
    dispatch(fetchData)
      .then(result => {
        setData(result);
      })
      .finally(() => {
        setFetching(false);
      })
  }, [setData, setFetching, fetchId]);

  return (
    <NoContentContainer noContent={!fetching && (!data || !data.length)}>
      <LoaderContainer fetching={fetching}>
        <ErrorBoundary>
          {children({ data })}
        </ErrorBoundary>
      </LoaderContainer>
    </NoContentContainer>
  )
}


export default ListLoader;