import React from "react";
import { useSelector } from "react-redux";
import { getActiveLanguage } from "shared/components/translate";

const YoutubeVideo = () => {
  const lang = useSelector(getActiveLanguage);
  console.log(lang);

  if (lang?.code === "lt") {
    return (
      <iframe
        height="480"
        style={{ width: "100%" }}
        src="https://www.youtube.com/embed/f1KYr24lYH4?rel=0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    );
  }

  return (
    <iframe
      height="480"
      style={{ width: "100%" }}
      src="https://www.youtube.com/embed/JmL_De6AmAQ?rel=0"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  );
};

export default YoutubeVideo;
