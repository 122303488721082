import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    background: {
      default: '#f4f7f8',
    },
    text: {
      primary: "#444444"
    },
  },
  typography: {
    "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
  overrides: {
    MuiFormControlLabel: {
      label: {
        whiteSpace: 'nowrap',
        fontSize: '0.875rem',
      }
    },
    MuiTypography: {
      subtitle1: {
        fontWeight: 'bold'
      }
    },
    MuiPopover: {
      root: {
        zIndex: 1
      }
    }
  }
});