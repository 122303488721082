import React from 'react';
import { Formik, Form } from 'formik';
import { Dialog, DialogActions, DialogTitle, Button, DialogContent } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import IndicatorForm, { validationSchema } from 'components/indicators/form/mission';
import { editMission } from 'reducers/ducks/indicator/createIndicator';
import { setFetchIndicatorId } from 'reducers/ducks/indicator';
import { useTranslate } from 'shared/components/translate';

const CreateStrategy = ({ open, handleClose, initialValues, ...props }) => {
    const dispatch = useDispatch();
    const translate = useTranslate();

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <Formik
                validationSchema={validationSchema}
                onSubmit={val => {
                    dispatch(editMission(val))
                        .then(() => dispatch(setFetchIndicatorId()))
                        .then(() => handleClose());
                }}
                initialValues={initialValues}>
                {({ isValid }) => (
                    <Form>
                        <DialogTitle>
                            {translate("indicators.views.mission.edit")}
                        </DialogTitle>
                        <DialogContent>
                            <IndicatorForm />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>
                                {translate("common.cancel")}
                            </Button>
                            <Button
                                disabled={!isValid}
                                variant='contained'
                                color='primary'
                                type='submit'
                            >
                                {translate("common.edit")}
                            </Button>
                        </DialogActions>
                    </Form>
                )}

            </Formik>
        </Dialog>
    )
}

export default CreateStrategy