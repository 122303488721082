import { createRequestMessageHandler } from "shared/utils/httpRequestMessageHandler";

const getProblemMessageHandler = createRequestMessageHandler();

export const findStatus = (q) => (dispatch, _, { api }) => {
  return api
    .get(`/private/problems/status/find`)
    .catch(error => {
      dispatch(getProblemMessageHandler(error));

      throw error;
    })
}