import React from 'react';
import { Grid } from '@material-ui/core';

import UserChart from './userChart';

const Charts = ({ selected }) => {
  return (
    <Grid container spacing={1}>
      {
        Object.values(selected)
          .filter(val => !!val)
          .map(indicator => {
            return (
              <Grid item xs={12} key={indicator.id}>
                <UserChart indicator={indicator} />
              </Grid>
            )
          })
      }

    </Grid>
  )
}

export default Charts;