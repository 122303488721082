import React from 'react';
import { ResponsiveBubble } from '@nivo/circle-packing'
import { useTranslate } from 'shared/components/translate';
import { Typography } from '@material-ui/core';

export const colors = {
    green: {
        0: '#1b5e20',
        1: '#388e3c',
        2: '#4caf50',
        3: '#81c784',
    },
    yellow: {
        0: '#ff6f00',
        1: '#ffa000',
        2: '#ffc107',
        3: '#ffd54f',
    },
    red: {
        0: '#b71c1c',
        1: '#d32f2f',
        2: '#f44336',
        3: '#e57373',
    },
    blue: {
        0: '#03a9f4',
        1: '#29b6f6',
        2: '#4fc3f7',
        3: '#81d4fa',
    }
}

const getColor = (depth, colors) => {
    const color = colors[depth];

    if (color) {
        return color
    }

    return color[0];
}

const Network = ({ data }) => {
    const translate = useTranslate();
    if (!data) {
        return null;
    }
    const root = findChild(data)

    return (
        <ResponsiveBubble
            root={root}
            identity="id"
            label={() => ''}
            value={() => 50}
            tooltip={(node) => {
                return (
                    <>
                        <Typography variant="subtitle1" style={{ color: node.color }}>{node.data.name}</Typography>
                        <Typography variant="subtitle1">{translate("dashboards.companyCharts.network.fulfillment")}: {Number.parseInt(node.data.avarage)}%</Typography>
                    </>
                )
            }
            }
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            colors={node => {
                // PVZ jeigu nuo pasiekimaumas iki 30 proc. – raudonas, jei 30- 50 proc.  – geltona, 50 – 70 proc. – žalia 70 - 100 proc. melyna:
                if (node.avarage >= 70) {
                    return getColor(node.depth, colors.blue)
                }

                if (node.avarage >= 50) {
                    return getColor(node.depth, colors.green)
                }

                if (node.avarage >= 30) {
                    return getColor(node.depth, colors.yellow)
                }

                return getColor(node.depth, colors.red)
            }}
            borderColor="black"
            padding={32}
            labelTextColor={{ from: 'color', modifiers: [['darker', 0.8]] }}
            borderWidth={2}
            animate={true}
            motionStiffness={90}
            motionDamping={12}
        />
    )
}

const countColor = (node) => {
    if (node.avarage == null) {
        return 0
    }

    if (node.case === 'lte') {
        if (node.plannedValue >= node.value) {
            return 100
        }

        if (!(node.maxValue - node.plannedValue)) {
            return 100
        }

        if (!(node.maxValue - node.avarage)) {
            return 0
        }

        return (node.maxValue - node.avarage) / (node.maxValue - node.plannedValue) * 100
    }

    return node.avarage * 100 / node.plannedValue
}

const findChild = (data) => {
    const vision = data.find(dt => dt.typename === 'vision');

    return {
        id: vision.id,
        name: vision.name,
        avarage: countColor(vision),
        children: data
            .filter(dt => dt.typename === 'strategic')
            .map(dt => ({
                id: dt.id,
                name: dt.name,
                avarage: countColor(dt),
                children: data
                    .filter(dep => dep.parentindicator === dt.id)
                    .map(dep => ({
                        id: dep.id,
                        name: `${dep.departmentName}: ${dep.name}`,
                        avarage: countColor(dep),
                        children: data
                            .filter(emp => emp.parentindicator === dep.id)
                            .map(emp => ({
                                id: emp.id,
                                name: `${emp.first_name} ${emp.last_name}: ${emp.name}`,
                                avarage: countColor(emp),
                            }))
                    }))
            }))
    }
}


export default Network