import { Assessment, ReportProblem, ThumbUp, Person, People, Equalizer, SettingsApplications, Archive, AccessibilityNew } from '@material-ui/icons';
import { ROLES } from '../../../common/roles';

export default [
  {
    title: 'navigation.strategy',
    icon: Equalizer,
    pagePath: '/strategy',
    allowedRoles: [
      ROLES["INDICATOR-READ-VISION"],
      ROLES["INDICATOR-READ-STRATEGY"],
      ROLES["INDICATOR-READ-DEPARTMENT"],
      ROLES["INDICATOR-READ-EMPLOYEE"],
    ],
  },
  {
    title: 'navigation.indicator',
    icon: Assessment,
    pagePath: '/',
    exact: true,
    allowedRoles: [
      ROLES["INDICATOR-READ-VISION"],
      ROLES["INDICATOR-READ-STRATEGY"],
      ROLES["INDICATOR-READ-DEPARTMENT"],
      ROLES["INDICATOR-READ-EMPLOYEE"],
    ],
  },
  {
    title: 'navigation.problems',
    icon: ReportProblem,
    pagePath: '/problems',
    allowedRoles: [ROLES["PROBLEMS-READ"]],
  },
  {
    title: 'navigation.acknowledgment',
    icon: ThumbUp,
    pagePath: '/acknowledgment',
    allowedRoles: [ROLES["ACKNOWLEDGMENT-READ"]],
  },
  {
    title: 'navigation.administration',
    icon: SettingsApplications,
    allowedRoles: [ROLES["DEPARTMENT-READ"], ROLES["USER-READ"], ROLES["ROLE-GROUPS-READ"], ROLES["INDICATOR-TEMPLATE-READ"]],
    childs: [
      {
        title: 'navigation.department',
        icon: People,
        pagePath: '/department',
        allowedRoles: [ROLES["DEPARTMENT-READ"]]
      },
      {
        title: 'navigation.users',
        icon: Person,
        pagePath: '/users',
        allowedRoles: [ROLES["USER-READ"]]
      },
      {
        title: 'navigation.roleGroup',
        icon: AccessibilityNew,
        pagePath: '/roleGroup',
        allowedRoles: [ROLES["ROLE-GROUPS-READ"]]
      },
      {
        title: 'navigation.template',
        icon: Archive,
        pagePath: '/template',
        allowedRoles: [ROLES["INDICATOR-TEMPLATE-READ"]]
      },
    ]
  }
]