import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getStrategyDashboard } from 'reducers/ducks/dashboard';

import StrategyDepertmentIndicator from './strategyDepartmentIndicator';
import { getDasboardFilter } from 'selectors/dashboard';

const Charts = ({ indicator }) => {
  const [data, setData] = React.useState({});
  const dispatch = useDispatch();
  const filters = useSelector(getDasboardFilter)

  React.useEffect(() => {
    dispatch(getStrategyDashboard(indicator.id))
      .then(response => {
        setData(response.data)
      });
  }, [dispatch, indicator, filters]);


  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <div style={{ height: 48 }}>
          <Grid container alignItems="center" className="h-100">
            <Grid item>
              <Typography variant="subtitle1" color="primary">{indicator.name}</Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <StrategyDepertmentIndicator data={data.strategyToGroup} />
          </Grid>
        </Grid>
      </Grid>
    </Grid >
  )
}

export default Charts;