import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { withTranslate } from 'shared/components/translate';
import { setStrategicGoalRecord, closeRecordDialog, setFetchedCompanyStrategy } from 'reducers/ducks/strategy';
import { isCreateRecordDialogOpen, getOpenedRecordValue } from 'selectors/strategy.selector';
import Record, { recordValidationSchema } from '../forms/record';

const CreateStrategicGoalDialog = ({ translate }) => {
  const dispatch = useDispatch();
  const open = useSelector(isCreateRecordDialogOpen);
  const values = useSelector(getOpenedRecordValue);
  const handleCloseRecord = () => dispatch(closeRecordDialog)

  return (
    <Dialog open={open} onClose={handleCloseRecord}>
      <DialogTitle>Pridėti strateginio tiklso reikšmę</DialogTitle>
      <Formik
        enableReinitialize
        initialValues={values}
        validationSchema={recordValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          dispatch(setStrategicGoalRecord(values))
            .then(() => dispatch(setFetchedCompanyStrategy))
            .then(() => handleCloseRecord())
            .finally(() => setSubmitting(false));
        }}
        render={formProps => (
          <Form>
            <DialogContent>
              <Record />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseRecord}>{translate("common.cancel")}</Button>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                disabled={!formProps.isValid || formProps.isSubmitting}
              >
                {translate("common.add")}
              </Button>
            </DialogActions>
          </Form>
        )}
      />
    </Dialog>
  )
}

export default withTranslate(CreateStrategicGoalDialog);