import React from 'react'
import { withRouter } from 'react-router-dom';
import { Card, CardHeader, CardContent } from '@material-ui/core';

import { withTranslate } from '../../shared/components/translate';

const ForgotPasswordSuccess = withRouter(({ translate }) => {
  return (
    <Card>
      <CardHeader title={translate('auth.forgotPasswordSuccess.title')} />
      <CardContent>
        {translate('auth.forgotPasswordSuccess.description')}
      </CardContent>
    </Card>
  );
})

export default withTranslate(ForgotPasswordSuccess);