import { assoc } from 'ramda';

import { getTranslate } from 'shared/components/translate';
import { actions as notificationActions } from 'shared/components/notifications';

const SET_MISSION = 'MYKPI/COMPANY_VISION/MISSION';
const FETCHING = 'MYKPI/COMPANY_VISION/FETCHING';
const OPEN_EDIT_DIALOG = 'MYKPI/COMPANY_VISION/OPEN_EDIT_DIALOG';

const defaultState = {
  text: '',
  isFetching: false,
  openDialog: false,
};

export default (state = defaultState, { type, payload } = {}) => {
  switch (type) {
    case SET_MISSION:
      return assoc('text', payload, state);
    case FETCHING:
      return assoc('isFetching', payload, state);
    case OPEN_EDIT_DIALOG:
      return assoc('openDialog', payload, state);
    default:
      return state;
  }
}

export const setCompanyMission = payload => ({
  type: SET_MISSION,
  payload
});

export const setFetching = payload => ({
  type: FETCHING,
  payload
});

const setOpenDialog = payload => ({
  type: OPEN_EDIT_DIALOG,
  payload
});

export const openDialog = setOpenDialog(true);
export const closeDialog = setOpenDialog(false);


export const fetchCompanyMission = (dispatch, getState, { api }) => {
  dispatch(setFetching(true));
  const translate = getTranslate(getState());

  return api.get('/private/company/mission')
    .catch(error => {
      dispatch(notificationActions.displayMessage(
        'error',
        translate('notifications.errors.get')
      ));

      throw error;
    })
    .finally(() => dispatch(setFetching(false)))
}

export const saveFetchedMission = (dispatch) => {
  return dispatch(fetchCompanyMission)
    .then((response) => {
      dispatch(setCompanyMission(response.data));

      return response;
    });
}

export const createGetCachedCompanyVision = companyVision => (dispatch) => {
  if (!!companyVision) {
    return Promise.resolve(companyVision);
  }

  return dispatch(saveFetchedMission)
}


export const saveCompanyMission = values => (dispatch, getState, { api }) => {
  const translate = getTranslate(getState());

  return api.put('/private/company/mission', values)
    .catch(error => {
      dispatch(notificationActions.displayMessage(
        'error',
        translate('notifications.errors.edit')
      ));

      throw error;
    })
}
