import { path, assoc } from 'ramda';
import moment from 'moment';

import { getSelectedIndicator, getYearRange, getFilterFrom, getFilterTill } from '../selectors/indicator.selector';
import { actions as notificationActions } from '../shared/components/notifications';
import { getTranslate } from '../shared/components/translate';
import { fetchGroupedRegisterWeekProblemTotal } from './problems.service';

export const openCreateDialog = ({
  type: 'indicator/createDialog',
  payload: true
});

export const closeCreateDialog = ({
  type: 'indicator/createDialog',
  payload: false
});

export const openEditDialog = ({
  type: 'indicator/editDialog',
  payload: true
});

export const closeEditDialog = ({
  type: 'indicator/editDialog',
  payload: false
});

export const openAddRecordDialog = ({
  type: 'indicator/createRecordDialog',
  payload: true
});

export const closeAddRecordDialog = ({
  type: 'indicator/createRecordDialog',
  payload: false
});

export const openEditRecordDialog = ({
  type: 'indicator/editRecordDialog',
  payload: true
});

export const closeEditRecordDialog = ({
  type: 'indicator/editRecordDialog',
  payload: false
});

export const setYearRange = date => ({
  type: 'indicator/yearRange',
  payload: date,
});
export const setDateFilter = date => ({
  type: 'indicator/filter',
  // payload: date
  payload: {
    from: moment(date.from).utc().startOf('month'),
    to: moment(date.to).utc().endOf('month').startOf('day'),
  },
});

export const selectIndicator = id => (dispatch, getState) => {
  const selected = getSelectedIndicator(getState());
  const el = path([id], selected);

  dispatch({
    type: 'indicator/select',
    payload: assoc(id, !el, selected),
  })
};

export const setActiveIndicator = (indicator, indicatorDate, record) => ({
  type: 'indicator/active',
  payload: indicator ? {
    record,
    indicatorDate,
    indicator
  } : null,
});
export const setActiveRecord = (record, recordDate) => ({
  type: 'record/activeRecord',
  payload: record ? {
    recordDate,
    record
  } : null,
});

export const createIndicatorRecord = (record) => (dispatch, _, { api }) => {
  return api.post('/private/record/create', record)
}

export const createIndicator = indicator => (dispatch, _, { api }) => {
  dispatch({ type: 'indicator/create_request' });

  return api.post('/private/indicator/create', indicator)
    .then(result => result.data)
}

export const createIndicatorWithMultiAssignee = indicator => (dispatch, _, { api }) => {
  dispatch({ type: 'indicator/create_request' });
  const newIndicators = indicator.assignTo.map(assignTo => {
    return {
      ...indicator,
      assignTo
    }
  })

  return Promise.all(
    newIndicators.map(indicator => dispatch(createIndicator(indicator)))
  )
}

export const listIndicators = () => (dispatch, getState, { api }) => {
  dispatch({ type: 'indicator/list_request' });
  const date = getYearRange(getState());
  const from = getFilterFrom(getState());
  const to = getFilterTill(getState());

  return api.get('/private/indicator/list')
}

export const editIndicator = (indicator = {}) => (dispatch, _, { api }) => {
  dispatch({ type: 'indicator/edit_request' });

  return api.put(`/private/indicator/${indicator['id']}`, indicator)
}

export const fetchIndicator = id => (dispatch, _, { api }) => {
  dispatch({ type: 'indicator/edit_request' });

  return api.get(`/private/indicator/id/${id}`);
}

export const deleteIndicator = id => (dispatch, getState, { api }) => {
  const translate = getTranslate(getState());
  return api.delete(`/private/indicator/id/${id}`)
    .then(() => {
      dispatch(notificationActions.displayMessage('success', translate('notifications.success.delete')));
    })
    .catch(() => {
      dispatch(notificationActions.displayMessage('error', translate('notifications.errors.delete')))
    });
}

export const editRecord = (id, unit) => (dispatch, _, { api }) => {
  return api.put(`/private/record/id/${id}/unit/${unit}`);
}