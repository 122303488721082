import React from 'react';
import { Grid } from '@material-ui/core';

import UserChart from './userChart';

const Charts = ({ }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <UserChart />
      </Grid>
    </Grid>
  )
}

export default Charts;