import React from 'react';
import { Field } from 'formik';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';

import InputField from 'shared/components/input';
import { withTranslate } from 'shared/components/translate';

export const recordValidationSchema = Yup.object().shape({
  value: Yup.number().required().min(0).transform(delocalize),
});

function delocalize(currentValue, originalValue) {
  return parseFloat(originalValue.replace(',', '.'))
}


const Record = ({ translate }) => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Field
        required
        fullWidth
        name='value'
        label='Reikšmė'
        component={InputField}
      />
    </Grid>
  </Grid>
)

export default withTranslate(Record)