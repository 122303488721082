import React from "react";
import { Field } from "formik";
import { Grid } from "@material-ui/core";
import * as Yup from "yup";

import InputField from "shared/components/input";
import { withTranslate } from "shared/components/translate";
import SelectDepartment from "shared/components/select-department";
import SelectCase from "shared/components/select-IndicatorCase";
import SelectUnit from "shared/components/select-unit";
import selectIndicator from "shared/components/select-indicator";
import selectIndicatorPeriod from "shared/components/select-indicatorPeriod";
import NumberField from "shared/components/numberInput";

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  plannedValue: Yup.number().required(),
  period: Yup.object().required(),
  unit: Yup.object().strict().required(),
  case: Yup.object().required(),
  department: Yup.object().required(),
  parentIndicator: Yup.object().required(),
});

const DepartmentGoal = ({ translate }) => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Field
        required
        fullWidth
        name="name"
        multiline
        rows={2}
        label={translate("indicators.form.departmentDescription")}
        component={InputField}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        fullWidth
        name="period"
        label={translate("indicators.form.period")}
        component={selectIndicatorPeriod}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        required
        fullWidth
        name="plannedValue"
        label={translate("indicators.form.plannedValue")}
        component={NumberField}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        required
        fullWidth
        name="unit"
        label={translate("indicators.form.unit")}
        component={SelectUnit}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        required
        fullWidth
        name="case"
        label={translate("indicators.form.case")}
        component={SelectCase}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        required
        name="department"
        label={translate("indicators.options.department")}
        component={SelectDepartment}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        required
        fullWidth
        type="strategic"
        name="parentIndicator"
        label={translate("indicators.options.strategy")}
        component={selectIndicator}
      />
    </Grid>
  </Grid>
);

export default withTranslate(DepartmentGoal);
