import { path } from 'ramda';

import { selectors as fetchSelectors } from 'reducers/ducks/common/fetch';
import { selectors as paginationSelectors } from 'reducers/ducks/problems/list/pagination';
import { createSelector } from 'reselect';

const emptyArray = [];

export const listSelectors = fetchSelectors(['problem', 'list', 'problems'], emptyArray);

export const isOpenFilter = path(['problem', 'filter', 'state', 'open']);
export const getSearchField = path(['problem', 'filter', 'fields', 'search']);
export const getFilterSelectors = path(['problem', 'filter', 'fields']);
export const pagination = paginationSelectors;

export const getFormattedFilterFields = createSelector(
  getFilterSelectors,
  (fields) => {
    if (!fields) {
      return {};
    }

    const formattedFields = { ...fields };

    if (fields.status && Array.isArray(fields.status)) {
      formattedFields.status = fields.status.map(stat => stat.id)
    }

    if (fields.assignedTo && Array.isArray(fields.assignedTo)) {
      formattedFields.assignedTo = fields.assignedTo.map(user => user.id)
    }

    if (fields.createdBy && Array.isArray(fields.createdBy)) {
      formattedFields.createdBy = fields.createdBy.map(user => user.id)
    }
    if (fields.group && Array.isArray(fields.group)) {
      formattedFields.group = fields.group.map(group => group.id)
    }

    return formattedFields
  }
)