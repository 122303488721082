import React from 'react';
import { Grid, List, ListItem, ListItemText, ListItemAvatar } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import Loader from 'shared/components/loader';
import { listSelectors, isOpenFilter } from 'selectors/problem.selector';
import { fetchProblemsToStore } from 'reducers/ducks/problems/list';
import SearchContainer from './search';
import Status from '../status';
import { useTranslate } from 'shared/components/translate';

const ProblemListContainer = ({ history, match }) => {
  const dispatch = useDispatch();
  const problems = useSelector(listSelectors.getData)
  const fetching = useSelector(listSelectors.isFetching)
  const openFilter = useSelector(isOpenFilter);
  const translate = useTranslate();

  React.useEffect(() => {
    dispatch(fetchProblemsToStore(''));
  }, [dispatch]);


  if (fetching) {
    return (
      <Loader />
    )
  }

  return (
    <Grid container>
      {
        !openFilter && (
          <Grid item xs={12}>
            <SearchContainer
              handleSearch={search => {
                dispatch(fetchProblemsToStore(''));
              }} />
          </Grid>
        )
      }
      <Grid item xs={12}>
        <List dense>
          {
            problems.map(problem => {
              return (
                <ListItem
                  dense
                  button
                  key={problem.id}
                  selected={problem.id === match.params.id}
                  onClick={() => {
                    history.push(`/problems/problem/${problem.id}`)
                  }}
                >
                  <ListItemAvatar>
                    <Status status={problem.status} />
                  </ListItemAvatar>
                  <ListItemText primary={problem.description} />
                </ListItem>
              )
            })
          }
        </List>
      </Grid>
      {/* <Grid item xs={12}>
        <Grid container>
          <Grid item>
            <Pagination />
          </Grid>
        </Grid>
      </Grid> */}
    </Grid>
  )
}

export default withRouter(ProblemListContainer);