import React from "react";
import {
  withLocalize,
  Translate,
  getTranslate as rlrGetTranslate,
  setActiveLanguage as rldSetActiveLanguage,
  getLanguages as rldGetLanguages,
  getActiveLanguage as rldGetActiveLanguage,
} from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import { setLocale } from "yup";
import moment from "moment";
import "moment/locale/lt";
import "moment/locale/en-gb";
import { useSelector } from "react-redux";

import lt from "./translations/lt.json";
import en from "./translations/en.json";

const getInitialLocate = () => {
  const locale = localStorage.getItem("locale");

  return locale || "lt";
};

export const TranslateComponent = (props) => {
  React.useEffect(() => {
    const locale = getInitialLocate();
    moment.locale(locale);

    props.initialize({
      languages: [
        { name: "Lietuvių", code: "lt" },
        { name: "English", code: "en" },
      ],
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true,
        defaultLanguage: locale,
      },
    });

    props.addTranslationForLanguage(lt, "lt");
    props.addTranslationForLanguage(en, "en");
  }, []);

  return null;
};

export const setActiveLanguage = (lang) => (dispatch, getState) => {
  dispatch(rldSetActiveLanguage(lang));
  moment.locale(lang);
  localStorage.setItem("locale", lang);
};

export const getLanguages = (state) => rldGetLanguages(state.localize);
export const getActiveLanguage = (state) =>
  rldGetActiveLanguage(state.localize);
export const getTranslate = (state) => rlrGetTranslate(state.localize);

export default withLocalize(TranslateComponent);

export const useTranslate = () => {
  const translate = useSelector(getTranslate);

  return translate;
};

export const withTranslate = (Component) => (props) => (
  <Translate>
    {({ translate }) => <Component {...props} translate={translate} />}
  </Translate>
);

setLocale({
  string: {
    email: "validation.string.email",
    max: "validation.string.max",
    min: "validation.string.min"
  },
  number: {
    max: "validation.number.max",
    min: "validation.number.min"
  },
  mixed: {
    required: "validation.mixed.required",
    notType: (params) => {
      switch (params.type) {
        case "number":
          return "validation.mixed.notType.number";
        default:
          return "validation.mixed.notType.default";
      }
    },
  },
  date: {
    max: "validation.date.max",
    min: "validation.date.min"
  },
});


