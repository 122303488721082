import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { path } from "ramda";

const isAllowed = ({ loggedIn }) => {
  return loggedIn;
};

const ProtectedRoute = ({ component: Component, redirect, ...rest }) => {
  const loggedIn = useSelector(path(["user", "token"]));

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAllowed({ loggedIn })) {
          return <Component {...props} />;
        }

        return <Redirect to={redirect} />;
      }}
    />
  );
};

export default ProtectedRoute;
