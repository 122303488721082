const SET_SEARCH = 'MYKPI/PROBLEMS_FILTER/SET_SEARCH';
const SET_OTHERS = 'MYKPI/PROBLEMS_FILTER/SET_OTHERS';

const defaultState = {
  search: '',
}

export default function problemFilterReducer(state = defaultState, { type, payload } = {}) {
  switch (type) {
    case SET_SEARCH:
      return { ...state, search: payload }
    case SET_OTHERS:
      return { ...state, ...payload }
    default:
      return state;
  }
}

export const setSearch = payload => ({
  type: SET_SEARCH,
  payload,
})

export const setOtherFields = payload => ({
  type: SET_OTHERS,
  payload,
})