import React from 'react';
import { Grid } from '@material-ui/core';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { IndicatorLoader } from 'components/indicators/common/indicatorLoader';
import { IndicatorRecordsContainer } from 'components/indicators/recordContainer';
import { ActionContainer } from './actions';
import { IndicatorView } from './indicatorView';
import { RecordsContainer } from '../recordsContainer';
import { DeleteAlert } from '../deleteAlert';
import EditGroupIndicator from './edit';
import { fetchIndicator } from 'reducers/ducks/indicator/fetchIndicator';
import { editIndicator } from 'reducers/ducks/indicator/editIndicator';
import { getDependentsIndicators } from 'reducers/ducks/indicator/getDependents';
import { deleteIndicator } from 'services/indicator.service';
import { setFetchIndicatorId, fetchIndicatorFilter } from 'reducers/ducks/indicator';
import { fetchIndicatorIds } from 'selectors/indicator.selector';

export const GroupIndicator = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const fetchId = useSelector(fetchIndicatorIds)
  const [open, setOpen] = React.useState(false);
  const [dependents, setDependents] = React.useState({});
  const [openEdit, setOpenEdit] = React.useState(false);

  const goTo = () => history.push('/strategy');

  return (
    <React.Fragment>
      <DeleteAlert
        handleClose={() => setOpen(false)}
        handleDelete={() =>
          dispatch(deleteIndicator(match.params.id))
            .then(goTo)
            .then(() => dispatch(fetchIndicatorFilter()))
        }
        dependents={dependents}
        open={open}
      />
      <EditGroupIndicator
        id={match.params.id}
        handleClose={() => setOpenEdit(false)}
        handleSubmit={values => dispatch(editIndicator('department', values))
          .then(() => dispatch(setFetchIndicatorId()))
          .then(() => setOpenEdit(false))
        }
        open={openEdit}
      />
      <IndicatorLoader
        fetchData={() => dispatch(fetchIndicator(match.params.id))}
        id={`${fetchId}${match.params.id}`}
      >
        {
          ({ data }) => (
            <Grid container>
              <Grid item xs={12}>
                <IndicatorView data={data}>
                  <ActionContainer
                    onDelete={() => {
                      setOpen(true)
                      dispatch(getDependentsIndicators(data.id, data.type.name))
                        .then(response => setDependents(response.data))
                    }}
                    onEdit={() => setOpenEdit(true)}
                  />
                </IndicatorView>
              </Grid>
              <Grid item xs={12}>
                <RecordsContainer>
                  <IndicatorRecordsContainer period={data.period} cases={data.case} plannedValue={data.plannedValue} />
                </RecordsContainer>
              </Grid>
            </Grid>
          )
        }
      </IndicatorLoader>
    </React.Fragment>
  )
}