import React from 'react';
import { pathEq } from 'ramda';

import { Select } from 'shared/components/select';
import { createField } from 'shared/components/selectField';

const options = [
  { value: 'vnt.', label: 'vnt.' },
  { value: 'proc.', label: 'proc.' },
  { value: 'eur', label: 'eur' },
  { value: 'kv_m', label: 'kv. m' },
  { value: 'm', label: 'm' },
  { value: 'kub_m', label: 'kub. m' },
  { value: 'ha', label: 'ha' },
  { value: 'kv', label: 'kV' },
  { value: 'kv_mm', label: 'kv. mm' },
  { value: 'val', label: 'val.' },
  { value: 'pakuot', label: 'pakuot.' },
  { value: 'ketv', label: 'ketv.' },
  { value: 'min', label: 'min.' },
  { value: 'SMS', label: 'SMS' },
  { value: 'para', label: 'para.' },
  { value: 'lasas', label: 'Lašas' },
  { value: 'pasal', label: 'pasal.' },
  { value: 'akt', label: 'akt.' },
  { value: 'dal_a_', label: 'dal. a. a.' },
  { value: 'rysul', label: 'ryšul.' },
  { value: 'kv_m', label: 'kv. km' },
  { value: 'rul', label: 'rul.' },
  { value: 'rink', label: 'rink.' },
  { value: 'kg', label: 'kg' },
  { value: 'ml', label: 'ml' },
  { value: 'lapas', label: 'lapas' },
  { value: 'tuks_vnt', label: 'tūkst vnt.' },
  { value: 'cm', label: 'cm' },
  { value: 'eur_ct', label: 'euro ct' },
  { value: 'dez', label: 'dėž.' },
  { value: 'flakon', label: 'flakon.' },
  { value: 'blokel', label: 'blokel.' },
  { value: 'rit', label: 'rit.' },
  { value: 'men', label: 'mėn.' },
  { value: 'm', label: 'm.' },
  { value: 'kompl', label: 'kompl.' },
  { value: 'pora', label: 'pora' },
  { value: 't', label: 't' },
  { value: 'kw', label: 'kW' },
  { value: 'kv_cm', label: 'kv cm' },
  { value: 'l_15', label: 'l +15 C' },
  { value: 'l_20', label: 'l +20 C' },
  { value: 's', label: 's' },
  { value: 'vnt', label: 'vnt' },
  { value: 'ct', label: 'ct' },
  { value: 'd', label: 'd.' },
  { value: 'mwh', label: 'MWh' },
]

export const SelectUnit = (props) => (
  <Select
    {...props}
    onChange={val => props.onChange(val.value)}
    value={options.find(pathEq(['value'], props.value))}
    options={options}
    menuPlacement='bottom'
    menuPosition='fixed' />
);

export default createField(SelectUnit)