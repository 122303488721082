import React from 'react';
import { Grid, Typography, Menu, MenuItem } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

import styles from './index.module.css';
import { WithRbac } from 'common/rbac';

const MoreButton = ({
  options = [],
  main,
  handleClick,
}) => {
  const [anchorEl, setAnchor] = React.useState(null);
  const handleClose = () => setAnchor(null);

  return (
    <React.Fragment>
      <div className={styles.buttonContainer}>
        <Grid
          container
          aria-controls="simple-menu"
          aria-haspopup="true"
          justify='space-between'
          spacing={1}
          alignItems='center'
          className={styles.button}
          wrap='nowrap'
        >
          <Grid
            item
            className={styles.buttonMainText}
            onClick={e => handleClick(main.event, e)}
          >
            <Typography align='center' variant='button'>{main.title}</Typography>
          </Grid>
          <Grid
            item
            className={styles.more}
            onClick={e => setAnchor(e.currentTarget)}
          >
            {
              Boolean(anchorEl) ? <ArrowDropUp /> : <ArrowDropDown />
            }
          </Grid>
        </Grid>
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={1}
        getContentAnchorEl={null}
        className={styles.dropDownContainer}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {
          options.map(option => (
            // <WithRbac key={option.title} action={option.action || ''}>
            <MenuItem onClick={e => handleClick(option.event, e)}>
              {option.title}
            </MenuItem>
            // </WithRbac>
          ))
        }
      </Menu>
    </React.Fragment>
  )
}

export default MoreButton;