import { assoc } from 'ramda';
import { getTranslate } from 'shared/components/translate';
import { actions as notificationActions } from 'shared/components/notifications';

const SET_DEPARTMENTS = 'MYKPI/DEPARTMENTS/SET_DEPARTMENTS';

const defaultState = {
  departments: {}
}

export default (state = defaultState, { type, payload } = {}) => {
  switch (type) {
    case SET_DEPARTMENTS:
      return assoc('departments', payload, state);
    default:
      return state;
  }
}

export const setDepartments = (payload = []) => ({
  type: SET_DEPARTMENTS,
  payload: payload.reduce((acc, department) => {
    return {
      ...acc,
      [department.id]: department,
    }
  }, {})
});

export const fetchDepartments = (dispatch, getState, { api }) => {
  const translate = getTranslate(getState());

  return api.get('/private/department/list')
    .catch(error => {
      dispatch(notificationActions.displayMessage(
        'error',
        translate('notifications.errors.get')
      ));

      throw error
    })
}

export const setFetchedDepartments = (dispatch, getState, { api }) => {
  return dispatch(fetchDepartments)
    .then(response => {
      dispatch(setDepartments(response.data));

      return response;
    })
}