import { path } from 'ramda';
import { v1 as uuid } from 'uuid'

const SET_NEW_UUID = 'MYKPI/DIALOG/SET_NEW_UUID';

const defaultSate = {
  id: uuid(),
}

export const selectors = pt => {
  return {
    id: path([...pt, 'id']),
  }
}

export default function refetcher(state = defaultSate, { type, payload } = {}) {
  switch (type) {
    case SET_NEW_UUID:
      return { ...state, id: payload }
    default:
      return state
  }
}

export const createSetNewId = name => () => ({
  name,
  type: SET_NEW_UUID,
  payload: uuid()
});
