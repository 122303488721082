import React from "react";
import { Grid, Typography, List, ListItem } from "@material-ui/core";
import { useTranslate } from "shared/components/translate";

const PrivateElegations = () => {
  const translate = useTranslate();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">
          {translate("landingPage.privacyPolicy.title")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.privacyPolicy.p1")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {translate("landingPage.privacyPolicy.s2")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.privacyPolicy.p2")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {translate("landingPage.privacyPolicy.s3")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.privacyPolicy.p3")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {translate("landingPage.privacyPolicy.s4")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.privacyPolicy.p4")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {translate("landingPage.privacyPolicy.s5")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.privacyPolicy.p5")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.privacyPolicy.p6")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {translate("landingPage.privacyPolicy.s7")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.privacyPolicy.p7")}
        </Typography>
        <List dense>
          <ListItem>
            <Typography variant="body1" align="justify">
              1. {translate("landingPage.privacyPolicy.l1")}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" align="justify">
              2. {translate("landingPage.privacyPolicy.l2")}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" align="justify">
              3. {translate("landingPage.privacyPolicy.l3")}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" align="justify">
              4. {translate("landingPage.privacyPolicy.l4")}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" align="justify">
              5. {translate("landingPage.privacyPolicy.l5")}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" align="justify">
              6. {translate("landingPage.privacyPolicy.l6")}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" align="justify">
              7. {translate("landingPage.privacyPolicy.l7")}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" align="justify">
              8. {translate("landingPage.privacyPolicy.l8")}
            </Typography>
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {translate("landingPage.privacyPolicy.s8")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.privacyPolicy.p8")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {translate("landingPage.privacyPolicy.s9")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          {translate("landingPage.privacyPolicy.p9")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PrivateElegations;
